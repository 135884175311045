import { FieldDictionary } from '@shopify/react-form';
import React from 'react';

export interface NewUnit {
    unitNumber: string;
    unitPrice: string;
    rooms: string;
    baths: string;
}

interface Props {
    unit: FieldDictionary<NewUnit>;
}

export function EditUnits({ unit }: Props) {
    return (
        <>
            <div>
                <label htmlFor="unit_number" className="sr-only">
                    Unit Number
                </label>
                <input
                    type="number"
                    name="unit_number"
                    id="unit_number"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Unit #"
                    value={unit.unitNumber.value}
                    onChange={unit.unitNumber.onChange}
                />
            </div>
            <div>
                <label htmlFor="rent-price" className="sr-only">
                    Unit Number
                </label>
                <input
                    type="number"
                    name="rent-price"
                    id="rent-price"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Price"
                    value={unit.unitPrice.value}
                    onChange={unit.unitPrice.onChange}
                />
            </div>
            <div>
                <label htmlFor="num_rooms" className="sr-only">
                    Unit Number
                </label>
                <input
                    type="number"
                    name="num_rooms"
                    id="num_rooms"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Rooms"
                    value={unit.rooms.value}
                    onChange={unit.rooms.onChange}
                />
            </div>
            <div>
                <label htmlFor="num_baths" className="sr-only">
                    Unit Number
                </label>
                <input
                    type="number"
                    name="num_baths"
                    id="num_baths"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Baths"
                    value={unit.baths.value}
                    onChange={unit.baths.onChange}
                />
            </div>
        </>
    );
}
