import React from 'react';
import { Field } from '@shopify/react-form';
import { CheckIcon, PencilIcon, XIcon } from '@heroicons/react/solid';
import { TextField } from '../../../../../../../../components';
import { useI18n } from '@shopify/react-i18n';
import { useChargeRentMutationMutation } from '../../../../../../../../generated/graphql';

interface Props {
    firstName: Field<string>;
    lastName: Field<string>;
    email: Field<string>;
    editMode: boolean;
    setEditMode: (value: boolean) => void;
    submitting: boolean;
    onSave: () => void;
    unitNumber?: string | null;
    unitPrice?: string | null;
    id?: string;
}

export function TenantDetailCard({
    firstName,
    lastName,
    email,
    editMode,
    setEditMode,
    submitting,
    onSave,
    unitNumber,
    unitPrice,
    id,
}: Props) {
    const [i18n] = useI18n();
    const [chargeRentMutation] = useChargeRentMutationMutation();

    return (
        <section aria-labelledby="property-information-title">
            <div className="bg-white shadow sm:rounded-lg">
                <div className="flex flex-row justify-between">
                    <div className="px-4 py-5 sm:px-6 order-first">
                        <h2
                            id="property-information-title"
                            className="text-lg leading-6 font-medium text-gray-900"
                        >
                            Tenant Information
                        </h2>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            Additional tenant details
                        </p>
                    </div>
                    <div className="px-4 py-5 sm:px-6 order-last space-x-2 space-y-2">
                        <button
                            type="button"
                            className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white ${
                                editMode ? 'bg-red-600' : 'bg-blue-600'
                            } ${
                                editMode
                                    ? 'hover:bg-red-700'
                                    : 'hover:bg-blue-700'
                            } focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                                editMode
                                    ? 'focus:ring-red-500'
                                    : 'focus:ring-red-500'
                            }`}
                            onClick={() => setEditMode(!editMode)}
                            disabled={submitting}
                        >
                            {editMode ? (
                                <XIcon
                                    className="-ml-1 mr-2 h-5 w-5"
                                    aria-hidden="true"
                                />
                            ) : (
                                <PencilIcon
                                    className="-ml-1 mr-2 h-5 w-5"
                                    aria-hidden="true"
                                />
                            )}
                            {editMode ? 'Cancel' : 'Edit'}
                        </button>
                        {editMode ? (
                            <button
                                type="submit"
                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                onClick={onSave}
                                disabled={submitting}
                            >
                                <CheckIcon
                                    className="-ml-1 mr-2 h-5 w-5"
                                    aria-hidden="true"
                                />
                                Save
                            </button>
                        ) : null}
                    </div>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    {editMode ? (
                        <form className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div>
                                <TextField
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    htmlFor="firstName"
                                    inputMode="text"
                                    autoComplete="first-name"
                                    label="First name"
                                    field={firstName}
                                    labelColor="text-gray-500"
                                />
                            </div>
                            <div>
                                <TextField
                                    type="text"
                                    name="address"
                                    id="address"
                                    htmlFor="address"
                                    inputMode="text"
                                    autoComplete="address"
                                    label="Address"
                                    field={lastName}
                                    labelColor="text-gray-500"
                                />
                            </div>
                            <div>
                                <TextField
                                    type="email"
                                    name="email"
                                    id="email"
                                    htmlFor="email"
                                    inputMode="email"
                                    autoComplete="email"
                                    label="Email"
                                    field={email}
                                    labelColor="text-gray-500"
                                />
                            </div>
                        </form>
                    ) : (
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                    First name
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    {firstName.value}
                                </dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                    Last name
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    {lastName.value}
                                </dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                    Email
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    {email.value}
                                </dd>
                            </div>
                            {unitNumber && (
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Unit number
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {unitNumber}
                                    </dd>
                                </div>
                            )}
                            {unitPrice && id && (
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Rent
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {`${i18n.defaultCurrency}${unitPrice}`}
                                    </dd>
                                    <button
                                        type="button"
                                        className="rounded bg-blue-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                        onClick={async () =>
                                            await chargeRentMutation({
                                                variables: {
                                                    occupantId: id,
                                                },
                                            })
                                        }
                                    >
                                        Charge rent
                                    </button>
                                </div>
                            )}
                        </dl>
                    )}
                </div>
            </div>
        </section>
    );
}
