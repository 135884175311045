import React from 'react';

interface Action {
    title: string;
    onClick(): void;
}

interface Props {
    title?: string;
    action?: Action;
    children: React.ReactNode;
    style?: object;
}
export function Page({ title, action, style, children }: Props) {
    return (
        <>
            <div
                className="md:flex md:items-center md:justify-between"
                style={{ marginBottom: '20px' }}
            >
                <div className="flex-1 min-w-0">
                    <h2
                        className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
                        style={style}
                    >
                        {title}
                    </h2>
                </div>
                {action && (
                    <div className="mt-4 flex md:mt-0 md:ml-4">
                        <button
                            type="button"
                            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={action.onClick}
                        >
                            {action.title}
                        </button>
                    </div>
                )}
            </div>
            {children}
        </>
    );
}
