import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase/app';
import { isDevelopmentEnvironment } from './environment';
const firebaseConfig = isDevelopmentEnvironment
    ? {
          projectId: 'zichi-dev',
          apiKey: 'zichi-dev',
          storageBucket: 'zichi-dev.appspot.com',
      }
    : {
          apiKey: 'AIzaSyCDH6XjoMI8-bhn3xAAIp-UUcCLRF5R_mE',
          authDomain: 'property-manager-a677a.firebaseapp.com',
          databaseURL: 'https://property-manager-a677a.firebaseio.com',
          projectId: 'property-manager-a677a',
          storageBucket: 'property-manager-a677a.appspot.com',
          messagingSenderId: '145887864086',
          appId: '1:145887864086:web:0e7716eddc875b15489c37',
          measurementId: 'G-NWKCGW7FF5',
      };

firebase.initializeApp(firebaseConfig);
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
