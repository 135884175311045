import React, { useEffect } from 'react';
import * as ls from 'local-storage';
import { usePropertyManagerData } from './DataProvider';
import { LoadingSpinner } from '../components';
import { useGetUserQuery } from '../generated/graphql';
import { Navigation } from './Navigation';
import { useAuth } from '.';
import { Navigate } from 'react-router-dom';
import { UserTypes } from '../sections/auth/Authenticate';

export const BaseApp = () => {
    const propertyManagerData = usePropertyManagerData();
    const authState = useAuth();

    const { data, loading } = useGetUserQuery({
        variables: {
            uid: ls.get<string>('uid'),
        },
    });

    useEffect(() => {
        propertyManagerData?.setUserInfo(data?.GetUser || null);
    }, [data, propertyManagerData]);

    if (!authState?.getAuthorizationToken()) {
        return <Navigate to="/auth" />;
    }

    if (data?.GetUser?.type === UserTypes.TENANT && data?.GetUser?.onboarding) {
        return <Navigate to="/tenant_onboarding" />;
    }

    if (
        (data?.GetUser?.type === UserTypes.LANDLORD ||
            data?.GetUser?.type === UserTypes.REALTOR) &&
        data?.GetUser?.onboarding
    ) {
        return <Navigate to="/landlord_onboarding" />;
    }

    if (loading) {
        return <LoadingSpinner />;
    }

    return <Navigation />;
};
