import React, { createContext, useContext } from 'react';
import {
    AuthenticatedUser,
    useAuthProvider,
} from '../../@utilities/useAuthProvider';
import { UserTypes } from '../../sections/auth/Authenticate';
require('firebase/auth');

interface AuthProviderReturn {
    user: AuthenticatedUser | null;
    signIn: (
        email: string,
        password: string,
        loadingCallback: (isLoading: boolean) => void
    ) => Promise<{ error?: string; user?: AuthenticatedUser }>;
    signUp: (
        email: string,
        password: string,
        firstName: string,
        lastName: string,
        preferredCurrency: string,
        userType: UserTypes,
        loadingCallback: (isLoading: boolean) => void
    ) => Promise<{ error?: string; user?: AuthenticatedUser }>;
    resetPassword: (email: string) => any;
    sendTenantCreateAccountEmail: (
        email: string,
        landlordId: string,
        firstName: string,
        lastName: string
    ) => any;
    signOut: () => void;
    getAuthorizationToken: () => string | undefined;
    fetchAuthorizationToken: () => Promise<string | undefined>;
    setUser: (user: AuthenticatedUser | null) => void;
}
const AuthContext = createContext<AuthProviderReturn | null>(null);
export const AuthProvider = ({ children }: any) => {
    const auth = useAuthProvider();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
