import React, { useEffect, useState } from 'react';
// import { TextField } from '../../components';
import { useAuth, usePropertyManagerData } from '../../foundation';
import {
    LandlordSubscription,
    useAddLandlordOnboardedMutation,
    useCompleteLandlordOnboardingMutation,
    useGetUserQuery,
} from '../../generated/graphql';
import * as ls from 'local-storage';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useField, useForm } from '@shopify/react-form';

export function LandlordOnboarding() {
    const propertyManagerData = usePropertyManagerData();
    const authState = useAuth();
    const [portalUrl, setPortalUrl] = useState('');
    const navigate = useNavigate();

    const { data, refetch: refetchUserData } = useGetUserQuery({
        variables: {
            uid: ls.get<string>('uid'),
        },
    });

    let [searchParams] = useSearchParams();

    const sessionId = searchParams.get('session_id');
    const paymentSuccessfull = searchParams.get('success');

    const [addLandlordOnboarded] = useAddLandlordOnboardedMutation();
    const [completeLandlordOnboarding] =
        useCompleteLandlordOnboardingMutation();

    useEffect(() => {
        propertyManagerData?.setUserInfo(data?.GetUser || null);
    }, [data, propertyManagerData]);

    useEffect(() => {
        async function completeOnboarding() {
            if (
                propertyManagerData?.userInfo?.onboarding === true &&
                sessionId &&
                paymentSuccessfull
            ) {
                const { data } = await completeLandlordOnboarding({
                    variables: {
                        sessionId,
                    },
                });
                await refetchUserData();
                setPortalUrl(
                    data?.CompleteLandlordOnboarding?.portalSessionUrl || ''
                );
            }
        }

        void completeOnboarding();
    }, [
        sessionId,
        propertyManagerData?.userInfo?.onboarding,
        paymentSuccessfull,
        completeLandlordOnboarding,
        refetchUserData,
    ]);

    const {
        fields: { subscriptionToUse },
        submit,
        submitting,
    } = useForm({
        fields: {
            companyName: useField(''),
            subscriptionToUse: useField(LandlordSubscription.Basic),
        },
        onSubmit: async ({ companyName, subscriptionToUse }) => {
            const { data } = await addLandlordOnboarded({
                variables: {
                    subscriptionType: subscriptionToUse,
                },
            });

            if (data?.OnboardLandlord.checkoutSessionUrl) {
                // window.open(
                //     data?.OnboardLandlord.checkoutSessionUrl,
                //     '_blank' // <- This is what makes it open in a new window.
                // );

                window.location.href = data?.OnboardLandlord.checkoutSessionUrl;
            }

            return { status: 'success' };
        },
    });

    if (!authState?.getAuthorizationToken()) {
        return <Navigate to="/auth" />;
    }

    const subscriptions = [
        {
            id: LandlordSubscription.Free,
            title: 'Free',
            description: 'Free (Maximum of 3 properties)',
        },
        {
            id: LandlordSubscription.Basic,
            title: 'Basic',
            description: 'Basic Features (Maximum of 15 properties)',
        },
        {
            id: LandlordSubscription.Premium,
            title: 'Premium',
            description: 'Unlimited features (Unlimited number of properties)',
        },
    ];

    return (
        <div className="grid grid-rows p-8 place-content-center">
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="min-w-full sm:mx-auto">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <div className="flex flex-col items-center mb-10">
                            <img
                                className="mx-auto h-14 w-auto"
                                src="https://firebasestorage.googleapis.com/v0/b/property-manager-a677a.appspot.com/o/ZICHI%20LOGO%20WITH%20TEXT.svg?alt=media&token=4fb2def4-641a-4ec2-b6d4-4d5d74275a85"
                                alt="Zichi Inc Logo"
                            />
                            <h2 className="mt-6 text-center text-xl font-extrabold text-gray-900">
                                We need more information to complete your
                                profile
                            </h2>
                        </div>
                        <form className="space-y-6">
                            {/* <div>
                                <TextField
                                    field={companyName}
                                    name="companyName"
                                    htmlFor="companyName"
                                    type="text"
                                    id="companyName"
                                    inputMode="text"
                                    label="Company name (optional)"
                                />
                            </div> */}
                            <div>
                                <p className="text-lg font-medium text-gray-900">
                                    Subscription
                                </p>
                                <fieldset className="border-b border-gray-200">
                                    <legend className="sr-only">
                                        Subscription
                                    </legend>
                                    <div className="divide-y divide-gray-200">
                                        {subscriptions.map((subscription) => {
                                            return (
                                                <div className="relative flex items-start py-4">
                                                    <div className="min-w-0 flex-1 text-sm">
                                                        <label
                                                            htmlFor={
                                                                subscription.id
                                                            }
                                                            className="font-medium text-gray-700"
                                                        >
                                                            {subscription.title}
                                                        </label>
                                                        <p
                                                            id="comments-description"
                                                            className="text-gray-500"
                                                        >
                                                            {
                                                                subscription.description
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="ml-3 flex h-5 items-center">
                                                        <input
                                                            id={subscription.id}
                                                            aria-describedby="comments-description"
                                                            name={
                                                                subscription.id
                                                            }
                                                            type="radio"
                                                            checked={
                                                                subscriptionToUse.value ===
                                                                subscription.id
                                                            }
                                                            onChange={() =>
                                                                subscriptionToUse.onChange(
                                                                    subscription.id
                                                                )
                                                            }
                                                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </fieldset>
                            </div>

                            {paymentSuccessfull && portalUrl ? (
                                <>
                                    <a
                                        href={portalUrl}
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-75"
                                    >
                                        Manage Subscription
                                    </a>
                                    <button
                                        onClick={() => navigate('/properties')}
                                        className="mt-5 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-75"
                                    >
                                        Go to properties
                                    </button>
                                </>
                            ) : (
                                <button
                                    type="submit"
                                    onClick={submit}
                                    disabled={submitting}
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-75"
                                >
                                    Confirm
                                </button>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
