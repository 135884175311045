import React, { useState } from 'react';
import { CashIcon, HomeIcon } from '@heroicons/react/outline';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { Unit } from '../../../../../generated/graphql';
import { useI18n } from '@shopify/react-i18n';
import { EditUnitsModal } from './PropertyDetailCard/components';

interface Props {
    propertyUnits: Unit[];
}

export function UnitsCard({ propertyUnits }: Props) {
    const [i18n] = useI18n();
    const [editUnit, setEditUnit] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState<Unit | null>(null);

    return (
        <section aria-labelledby="property-information-title">
            <div className="bg-white shadow sm:rounded-lg">
                <div className="flex flex-row justify-between">
                    <div className="px-4 py-5 sm:px-6 order-first">
                        <h2
                            id="property-information-title"
                            className="text-lg leading-6 font-medium text-gray-900"
                        >
                            Property Units
                        </h2>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            Units for the property
                        </p>
                    </div>
                </div>
                <div className="bg-white shadow overflow-hidden sm:rounded-md">
                    {propertyUnits.length > 0 ? (
                        <>
                            <ul className="divide-y divide-gray-200">
                                {propertyUnits.map((unit) => (
                                    <li key={unit.id}>
                                        <button
                                            onClick={() => {
                                                setSelectedUnit(unit);
                                                setEditUnit(true);
                                            }}
                                            className="w-full hover:bg-gray-50"
                                        >
                                            <div className="flex items-center px-4 py-4 sm:px-6">
                                                <div className="min-w-0 flex-1 flex items-center">
                                                    <div className="flex-shrink-0">
                                                        <p className="font-medium text-2xl text-slate-700">
                                                            {unit.unitNumber}
                                                        </p>
                                                    </div>
                                                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                        <div>
                                                            <div>
                                                                <p className="flex items-center text-lg text-gray-500">
                                                                    <CashIcon
                                                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                                        aria-hidden="true"
                                                                    />
                                                                    <span className="truncate">
                                                                        {`${i18n.defaultCurrency}${unit.unitPrice}`}
                                                                    </span>
                                                                </p>
                                                                <p className="flex items-center text-lg text-gray-500">
                                                                    <HomeIcon
                                                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                                        aria-hidden="true"
                                                                    />
                                                                    <span className="truncate">
                                                                        {`${unit.rooms}BD ${unit.baths}BATH`}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <ChevronRightIcon
                                                        className="h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            </div>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            <EditUnitsModal
                                unit={{
                                    rooms: selectedUnit?.rooms || '',
                                    baths: selectedUnit?.baths || '',
                                    unitNumber: selectedUnit?.unitNumber || '',
                                    unitPrice: selectedUnit?.unitPrice || '',
                                }}
                                propertyId={selectedUnit?.propertyId || ''}
                                unitId={selectedUnit?.id || ''}
                                open={editUnit}
                                onClose={() => {
                                    setSelectedUnit(null);
                                    setEditUnit(false);
                                }}
                            />
                        </>
                    ) : (
                        <div className="text-center mb-5">
                            <h3 className="mt-2 text-sm font-medium text-gray-900">
                                No units
                            </h3>
                            <p className="mt-1 text-sm text-gray-500">
                                If applicable to the property type get started
                                by adding a unit
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}
