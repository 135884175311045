import React, { useState, Fragment } from 'react';

import { useField, useForm, useDynamicList } from '@shopify/react-form';
import { v4 as uuidv4 } from 'uuid';
import { PlusSmIcon as PlusSmIconSolid } from '@heroicons/react/solid';
import { usePropertyManagerData } from '../../../../foundation';
import { useAddPropertyMutationMutation } from '../../../../generated/graphql';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { AddressEntry, EditUnits, TextField } from '../../../../components';
interface Props {
    isVisible: boolean;
    setClose(): void;
}

const propertyTypes = ['House', 'Apartment', 'Condo', 'Studio'];
export function PropertyNew({ isVisible, setClose }: Props) {
    const propertyManagerData = usePropertyManagerData();
    const [addProperty] = useAddPropertyMutationMutation();

    const factory = () => {
        return {
            unitNumber: '',
            unitPrice: '',
            rooms: '',
            baths: '',
        };
    };

    const { addItem, fields } = useDynamicList([], factory);

    const {
        fields: { name, address, propertyType, rentPrice, allUnits, about },
        submit,
    } = useForm({
        fields: {
            name: useField(''),
            propertyType: useField(propertyTypes[0]),
            rent: useField(true),
            rentPrice: useField(0),
            about: useField(''),
            allUnits: fields,
            address: useField({
                company: '',
                firstName: '',
                lastName: '',
                address1: '',
                address2: '',
                city: '',
                province: '',
                zip: '',
                country: '',
                phone: '',
            }),
        },
        onSubmit: async (fieldValues) => {
            const propertyId = uuidv4();
            const {
                address,
                rentPrice,
                name,
                allUnits,
                propertyType,
                rent,
                about,
            } = fieldValues;
            const addressToUse = `${address.address1}, 
            ${address.address2} ${address.city}, ${address.zip},
             ${address.province}, ${address.country}`;
            await addProperty({
                variables: {
                    propertyInput: {
                        name,
                        landlordId: propertyManagerData.userInfo?.id!,
                        address: addressToUse,
                        price: Number(rentPrice),
                        units: getValueFromAllUnits(allUnits, propertyId),
                        type: propertyType,
                        id: propertyId,
                        forRent: rent,
                        about,
                    },
                },
            });
            setClose();
            return { status: 'success' };
        },
    });

    const recipientForUnits = (propertyType: string) => {
        if (propertyType === 'Apartment') {
            return true;
        }
        return false;
    };

    const [shouldShowUnits, setShouldShowUnits] = useState(
        recipientForUnits(propertyType.value)
    );

    const getValueFromAllUnits = (units: any, propertyId: string) => {
        const unitValues: any[] = [];
        units.forEach((unit: any) => {
            const unitObj = {
                baths: unit.baths,
                propertyId: propertyId,
                rooms: unit.rooms,
                type: unit.type,
                unitNumber: unit.unitNumber,
                unitPrice: unit.unitPrice,
            };

            unitValues.push(unitObj);
        });

        return unitValues;
    };

    const handleTypeChange = (value: string) => {
        propertyType.onChange(value);
        setShouldShowUnits(recipientForUnits(value));
    };

    return (
        <Transition.Root show={isVisible} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 overflow-hidden"
                onClose={setClose}
                style={{ zIndex: 9999 }}
            >
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay className="absolute inset-0" />
                    <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="w-screen max-w-md">
                                <form className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                    <div className="flex-1 h-0 overflow-y-auto">
                                        <div className="py-6 px-4 bg-indigo-700 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-lg font-medium text-white">
                                                    New property
                                                </Dialog.Title>
                                                <div className="ml-3 h-7 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                        onClick={() =>
                                                            setClose()
                                                        }
                                                    >
                                                        <span className="sr-only">
                                                            Close panel
                                                        </span>
                                                        <XIcon
                                                            className="h-6 w-6"
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mt-1">
                                                <p className="text-sm text-indigo-300">
                                                    Fill in the information
                                                    below to create a new
                                                    property.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex-1 flex flex-col justify-between">
                                            <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                <div className="space-y-6 pt-6 pb-5">
                                                    <div>
                                                        <TextField
                                                            field={name}
                                                            htmlFor="property-name"
                                                            inputMode="text"
                                                            label="Property name"
                                                            id="property-name"
                                                            name="property-name"
                                                            type="text"
                                                            autoComplete="text"
                                                            required
                                                        />
                                                    </div>
                                                    <div>
                                                        <AddressEntry
                                                            address={address}
                                                        />
                                                    </div>
                                                    <div>
                                                        <label
                                                            htmlFor="property-type"
                                                            className="block text-sm font-medium text-gray-700"
                                                        >
                                                            Type
                                                        </label>
                                                        <select
                                                            id="property-type"
                                                            name="property-type"
                                                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                            value={
                                                                propertyType.value
                                                            }
                                                            onChange={(event) =>
                                                                handleTypeChange(
                                                                    event.target
                                                                        .value
                                                                )
                                                            }
                                                        >
                                                            {propertyTypes.map(
                                                                (type) => {
                                                                    return (
                                                                        <option
                                                                            key={
                                                                                type
                                                                            }
                                                                        >
                                                                            {
                                                                                type
                                                                            }
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <div className="mt-1">
                                                            <TextField
                                                                field={about}
                                                                htmlFor="about"
                                                                inputMode="text"
                                                                label="About"
                                                                id="about"
                                                                name="about"
                                                                type="text"
                                                                autoComplete="text"
                                                                required
                                                                textArea
                                                            />
                                                        </div>
                                                    </div>
                                                    {!shouldShowUnits && (
                                                        <div>
                                                            <TextField
                                                                field={
                                                                    rentPrice
                                                                }
                                                                htmlFor="rent-price"
                                                                inputMode="numeric"
                                                                label="Rent price"
                                                                id="rent-price"
                                                                name="rent-price"
                                                                type="number"
                                                                required
                                                                currencyField
                                                            />
                                                        </div>
                                                    )}
                                                    {shouldShowUnits && (
                                                        <div>
                                                            <p className="block text-sm font-medium text-gray-900">
                                                                Units
                                                            </p>
                                                            <div
                                                                role="list"
                                                                className="grid grid-row gap-4"
                                                            >
                                                                {allUnits.map(
                                                                    (unit) => {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    unit
                                                                                        .unitNumber
                                                                                        .value
                                                                                }
                                                                                className="grid grid-cols-4 gap-4"
                                                                            >
                                                                                <EditUnits
                                                                                    unit={
                                                                                        unit
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                                onClick={() =>
                                                                    addItem()
                                                                }
                                                                style={{
                                                                    marginTop:
                                                                        '20px',
                                                                }}
                                                            >
                                                                <PlusSmIconSolid
                                                                    className="h-5 w-5"
                                                                    aria-hidden="true"
                                                                />
                                                                Add Unit
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                                        <button
                                            type="button"
                                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={() => setClose()}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={submit}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
