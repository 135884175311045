import React from 'react';
import { Address, FieldName } from '@shopify/address-consts';
import { Field } from '@shopify/react-form';
import { TextFieldValue } from '../TextField';

interface Props {
    address: Field<Address>;
}
export function AddressEntry({ address }: Props) {
    const fieldNamesToUse = [
        FieldName.Address1,
        FieldName.Address2,
        FieldName.City,
        FieldName.Zone,
        FieldName.PostalCode,
        FieldName.Country,
    ];

    return (
        <>
            {fieldNamesToUse.map((fieldName) => {
                switch (fieldName) {
                    case FieldName.Address1:
                        return (
                            <div className="mt-1">
                                <TextFieldValue
                                    value={address.value.address1}
                                    onChange={(value) =>
                                        address.onChange({
                                            ...address.value,
                                            address1: value,
                                        })
                                    }
                                    htmlFor="address1"
                                    name="address1"
                                    id="address1"
                                    type="text"
                                    inputMode="text"
                                    label="Address 1"
                                    autoComplete="address1"
                                />
                            </div>
                        );
                    case FieldName.Address2:
                        return (
                            <div className="mt-1">
                                <TextFieldValue
                                    value={address.value.address2}
                                    onChange={(value) =>
                                        address.onChange({
                                            ...address.value,
                                            address2: value,
                                        })
                                    }
                                    htmlFor="address2"
                                    name="address2"
                                    id="address2"
                                    type="text"
                                    inputMode="text"
                                    label="Address 2"
                                    autoComplete="address2"
                                />
                            </div>
                        );
                    case FieldName.City:
                        return (
                            <div className="mt-1">
                                <TextFieldValue
                                    value={address.value.city}
                                    onChange={(value) =>
                                        address.onChange({
                                            ...address.value,
                                            city: value,
                                        })
                                    }
                                    htmlFor="city"
                                    name="city"
                                    id="city"
                                    type="text"
                                    inputMode="text"
                                    label="City"
                                    autoComplete="city"
                                />
                            </div>
                        );
                    case FieldName.Zone:
                        return (
                            <div className="mt-1">
                                <TextFieldValue
                                    value={address.value.province || ''}
                                    onChange={(value) =>
                                        address.onChange({
                                            ...address.value,
                                            province: value,
                                        })
                                    }
                                    htmlFor="province"
                                    name="province"
                                    id="province"
                                    type="text"
                                    inputMode="text"
                                    label="State / Province"
                                    autoComplete="province"
                                />
                            </div>
                        );
                    case FieldName.PostalCode:
                        return (
                            <div className="mt-1">
                                <TextFieldValue
                                    value={address.value.zip}
                                    onChange={(value) =>
                                        address.onChange({
                                            ...address.value,
                                            zip: value,
                                        })
                                    }
                                    htmlFor="zip"
                                    name="zip"
                                    id="zip"
                                    type="text"
                                    inputMode="text"
                                    label="Post Code"
                                    autoComplete="zip"
                                />
                            </div>
                        );
                    case FieldName.Country:
                        return (
                            <div className="mt-1">
                                <TextFieldValue
                                    value={address.value.country}
                                    onChange={(value) =>
                                        address.onChange({
                                            ...address.value,
                                            country: value,
                                        })
                                    }
                                    htmlFor="country"
                                    name="country"
                                    id="country"
                                    type="text"
                                    inputMode="text"
                                    label="Country"
                                    autoComplete="country"
                                />
                            </div>
                        );
                    default:
                        return null;
                }
            })}
        </>
    );
}
