import { useField, useForm } from '@shopify/react-form';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { TextField } from '../../components';
import { useAuth, usePropertyManagerData } from '../../foundation';
import * as ls from 'local-storage';
import {
    useAddTenantOnboardedMutation,
    useGetUserQuery,
} from '../../generated/graphql';

export function TenantOnboarding() {
    const propertyManagerData = usePropertyManagerData();
    const authState = useAuth();

    const [addTenantOnboardedMutation] = useAddTenantOnboardedMutation();

    const { data } = useGetUserQuery({
        variables: {
            uid: ls.get<string>('uid'),
        },
    });

    useEffect(() => {
        propertyManagerData?.setUserInfo(data?.GetUser || null);
    }, [data, propertyManagerData]);

    const {
        fields: { firstName, lastName, email },
        submit,
        submitting,
    } = useForm({
        fields: {
            email: useField(propertyManagerData?.userInfo?.email || ''),
            firstName: useField(propertyManagerData?.userInfo?.firstName || ''),
            lastName: useField(propertyManagerData?.userInfo?.lastName || ''),
        },
        onSubmit: async ({ email, firstName, lastName }) => {
            await addTenantOnboardedMutation({
                variables: {
                    accountInput: {
                        id: data?.GetUser?.id!,
                        email,
                        firstName,
                        lastName,
                        onboarding: false,
                        type: data?.GetUser?.type!,
                    },
                },
            });

            window.location.href = '/properties';

            return { status: 'success' };
        },
    });

    if (!authState?.getAuthorizationToken()) {
        return <Navigate to="/auth" />;
    }

    if (data && data.GetUser?.type !== 'TENANT' && !data?.GetUser?.onboarding) {
        return <Navigate to="/properties" />;
    }

    return (
        <div className="grid grid-rows p-8 place-content-center">
            <p className="font-sans text-xl font-medium">
                Welcome to Zichi, your landlord has added you to a rental
            </p>
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <div className="flex flex-col items-center mb-10">
                            <img
                                className="mx-auto h-14 w-auto"
                                src="https://firebasestorage.googleapis.com/v0/b/property-manager-a677a.appspot.com/o/ZICHI%20LOGO%20WITH%20TEXT.svg?alt=media&token=4fb2def4-641a-4ec2-b6d4-4d5d74275a85"
                                alt="Zichi Inc Logo"
                            />
                            <h2 className="mt-6 text-center text-xl font-extrabold text-gray-900">
                                We need to confirm some information to complete
                                your profile
                            </h2>
                        </div>
                        <form className="space-y-6">
                            <div>
                                <TextField
                                    field={email}
                                    name="email"
                                    htmlFor="email"
                                    type="email"
                                    id="email"
                                    inputMode="email"
                                    label="Email"
                                    disabled
                                />
                            </div>
                            <TextField
                                field={firstName}
                                name="firstName"
                                htmlFor="firstName"
                                type="text"
                                id="firstName"
                                inputMode="text"
                                label="First Name"
                            />
                            <TextField
                                field={lastName}
                                name="lastName"
                                htmlFor="lastName"
                                type="text"
                                id="lastName"
                                inputMode="text"
                                label="Last Name"
                            />
                            <button
                                type="submit"
                                onClick={submit}
                                disabled={submitting}
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-75"
                            >
                                Confirm
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
