import React from 'react';
export function EmptyTenantsState() {
    return (
        <div className="text-center">
            <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
            >
                <path
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">
                No Tenants
            </h3>
            <p className="mt-1 text-sm text-gray-500">
                Get started by adding a new tenant.
            </p>
            <div className="mt-6"></div>
        </div>
    );
}

export function EmptyPropertiesState() {
    return (
        <div className="text-center">
            <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
            >
                <path
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">
                No Properties
            </h3>
            <p className="mt-1 text-sm text-gray-500">
                Get started by adding a new property.
            </p>
            <div className="mt-6"></div>
        </div>
    );
}

export function EmptyContactsState() {
    return (
        <div className="text-center">
            <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
            >
                <path
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">
                No applicants
            </h3>
            <div className="mt-6"></div>
        </div>
    );
}
