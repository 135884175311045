import React from 'react';

interface RentalDetail {
    firstName: string;
    lastName: string;
    email: string;
    properties: { address: string; price: number }[];
}

interface Props {
    rentalDetail: RentalDetail;
}

export function RentalInformation({ rentalDetail }: Props) {
    const fullName = `${rentalDetail.firstName} ${rentalDetail.lastName}`;

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Landlord Info
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Landlord details.
                </p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                            Full name
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {fullName}
                        </dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                            Email address
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {rentalDetail.email}
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    );
}
