import React from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Field, useField, useForm } from '@shopify/react-form';
import { Page } from '../../../../components';
import {
    BrandingData,
    ThemeEditorData,
} from '../../../../foundation/EditorContext/EditorContext';
import { useContactLandlordMutation } from '../../../../generated/graphql';
import { useParams } from 'react-router-dom';

interface Props {
    open: boolean;
    onClose(): void;
    propertyId: string;
    branding: ThemeEditorData | BrandingData | null;
}

export function ContactModal({ open, onClose, propertyId, branding }: Props) {
    const [contactLanlord] = useContactLandlordMutation();
    const { landlord_id } = useParams();
    const {
        fields: { email, firstName, lastName, message },
        submit,
    } = useForm({
        fields: {
            email: useField(''),
            firstName: useField(''),
            lastName: useField(''),
            message: useField(''),
        },
        onSubmit: async ({ email, firstName, lastName, message }) => {
            if (landlord_id) {
                await contactLanlord({
                    variables: {
                        contactInput: {
                            email,
                            firstName,
                            lastName,
                            message,
                            landlordId: landlord_id,
                            propertyId,
                        },
                    },
                    context: {
                        headers: {
                            landlordid: landlord_id,
                            skipAuth: true,
                        },
                    },
                });
                onClose();
            }
            return { status: 'success' };
        },
    });

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => {}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
                                style={{
                                    backgroundColor:
                                        branding?.branding.colors.background
                                            .value,
                                }}
                            >
                                <Page
                                    title="Contact landlord"
                                    style={{
                                        color: branding?.branding.colors
                                            .primaryText.value,
                                    }}
                                >
                                    <div>
                                        <ContactFields
                                            email={email}
                                            firstName={firstName}
                                            lastName={lastName}
                                            message={message}
                                            branding={branding}
                                        />
                                    </div>
                                </Page>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                        type="button"
                                        onClick={submit}
                                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                                        style={{
                                            backgroundColor:
                                                branding?.branding.colors.accent
                                                    .value,
                                            color: branding?.branding.colors
                                                .primaryText.value,
                                        }}
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                        onClick={onClose}
                                        style={{
                                            backgroundColor:
                                                branding?.branding.colors.accent
                                                    .value,
                                            color: branding?.branding.colors
                                                .primaryText.value,
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

interface ContactProps {
    email: Field<string>;
    firstName: Field<string>;
    lastName: Field<string>;
    message: Field<string>;
    branding: ThemeEditorData | BrandingData | null;
}

export function ContactFields({
    email,
    firstName,
    lastName,
    message,
    branding,
}: ContactProps) {
    return (
        <>
            <div className="mb-5">
                <label
                    htmlFor="email"
                    style={{
                        color: branding?.branding.colors.secondaryText.value,
                    }}
                >
                    Email
                </label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Email"
                    value={email.value}
                    onChange={email.onChange}
                />
            </div>
            <div className="mb-5">
                <label
                    htmlFor="first-name"
                    style={{
                        color: branding?.branding.colors.secondaryText.value,
                    }}
                >
                    First name
                </label>
                <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="First name"
                    value={firstName.value}
                    onChange={firstName.onChange}
                />
            </div>
            <div className="mb-5">
                <label
                    htmlFor="last-name"
                    style={{
                        color: branding?.branding.colors.secondaryText.value,
                    }}
                >
                    Last name
                </label>
                <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Last name"
                    value={lastName.value}
                    onChange={lastName.onChange}
                />
            </div>
            <div className="mb-5">
                <label htmlFor="message" className="sr-only">
                    Message
                </label>
                <textarea
                    id="message"
                    name="message"
                    rows={3}
                    className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                    placeholder="Message"
                    value={message.value}
                    onChange={(e) => message.onChange(e.target.value)}
                />
            </div>
        </>
    );
}
