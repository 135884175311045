import React, { useState } from 'react';
import { AccountSettings } from './AccountSettings';
import { PaymentSettings } from './PaymentSettings';
import { ProfileSettings } from './ProfileSettings';

const tabs = [{ name: 'General' }, { name: 'Account' }, { name: 'Payments' }];

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
}

export function Settings() {
    const [currentSetting, setCurrentSetting] = useState('General');

    return (
        <main className="overflow-hidden rounded-lg bg-white shadow">
            <div className="relative mx-auto max-w-4xl md:px-8 xl:px-0">
                <div className="pt-10 pb-16">
                    <div className="px-4 sm:px-6 md:px-0">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                            Settings
                        </h1>
                    </div>
                    <div className="px-4 sm:px-6 md:px-0">
                        <div className="py-6">
                            <div className="lg:hidden">
                                <label
                                    htmlFor="selected-tab"
                                    className="sr-only"
                                >
                                    Select a setting
                                </label>
                                <select
                                    id="selected-tab"
                                    name="selected-tab"
                                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-600 focus:outline-none focus:ring-indigo-600 sm:text-sm"
                                    defaultValue={currentSetting}
                                    onChange={(event) =>
                                        setCurrentSetting(event.target.value)
                                    }
                                >
                                    {tabs.map((tab) => (
                                        <option key={tab.name}>
                                            {tab.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="hidden lg:block">
                                <div className="border-b border-gray-200">
                                    <nav className="-mb-px flex space-x-8">
                                        {tabs.map((tab) => (
                                            <button
                                                key={tab.name}
                                                className={classNames(
                                                    currentSetting === tab.name
                                                        ? 'border-indigo-600 text-indigo-600'
                                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                                )}
                                                onClick={() =>
                                                    setCurrentSetting(tab.name)
                                                }
                                            >
                                                {tab.name}
                                            </button>
                                        ))}
                                    </nav>
                                </div>
                            </div>
                            <>
                                {currentSetting === 'General' && (
                                    <ProfileSettings />
                                )}
                                {currentSetting === 'Account' && (
                                    <AccountSettings />
                                )}
                                {currentSetting === 'Payments' && (
                                    <PaymentSettings />
                                )}
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
