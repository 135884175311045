import React, { useMemo, useState } from 'react';

import { PropertiesTable, PropertyNew } from './components';
import { usePropertyManagerData } from '../../foundation';
import {
    LandlordSubscription,
    useGetPropertiesQuery,
    useRemovePropertyMutation,
} from '../../generated/graphql';
import { LoadingSpinner, NotFound, Page } from '../../components';
import { useGetLandlordSubscription } from '../settings/useGetLandlordSubscription';

export function Properties() {
    const [shouldCreateNew, setShouldCreateNew] = useState<boolean>(false);
    const [removePropertyMutate] = useRemovePropertyMutation();

    const propertyManagerData = usePropertyManagerData();

    const { planName } = useGetLandlordSubscription();
    const removeProperty = async (id: string) => {
        let landlordID: string = propertyManagerData.userInfo?.id as string;
        await removePropertyMutate({
            variables: {
                id: id,
                landlordId: landlordID,
            },
        });
        refetch();
    };

    const {
        data: queryResult,
        refetch,
        loading,
    } = useGetPropertiesQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            landlordId: propertyManagerData.userInfo?.id!,
        },
    });

    const propertiesAction = useMemo(() => {
        const properties = queryResult?.GetProperties?.properties;

        if (!properties) {
            return undefined;
        }

        if (loading) {
            return undefined;
        }

        if (planName === LandlordSubscription.Free && properties.length < 3) {
            return {
                title: 'Create Property',
                onClick: () => setShouldCreateNew(true),
            };
        } else if (
            planName === LandlordSubscription.Basic &&
            properties.length < 15
        ) {
            return {
                title: 'Create Property',
                onClick: () => setShouldCreateNew(true),
            };
        } else if (planName === LandlordSubscription.Premium) {
            return {
                title: 'Create Property',
                onClick: () => setShouldCreateNew(true),
            };
        }
    }, [loading, planName, queryResult?.GetProperties?.properties]);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (!queryResult || !queryResult.GetProperties?.properties) {
        return <NotFound />;
    }

    const properties = queryResult.GetProperties.properties.map((property) => {
        return {
            id: property.id,
            name: property.name,
            address: property.address,
            price: property.price,
            type: property.type,
            units:
                property.units
                    ?.filter((unit) => unit.propertyId)
                    .map((unit) => {
                        return {
                            id: unit.id,
                            unitPrice: unit.unitPrice
                                ? parseInt(unit.unitPrice)
                                : undefined,
                        };
                    }) || [],
        };
    });

    return (
        <div>
            <Page title="Properties" action={propertiesAction}>
                <PropertiesTable
                    properties={properties}
                    removeProperty={removeProperty}
                />
            </Page>
            <PropertyNew
                isVisible={shouldCreateNew}
                setClose={() => {
                    setShouldCreateNew(false);
                    refetch();
                }}
            />
        </div>
    );
}
