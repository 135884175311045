import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Account = {
  __typename?: 'Account';
  branding?: Maybe<LandlordBranding>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  landlords: Array<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  onboarding: Scalars['Boolean'];
  preferredCurrency?: Maybe<Scalars['String']>;
  subscription?: Maybe<LandlordSubscription>;
  type: Scalars['String'];
};

export type AccountInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
  onboarding: Scalars['Boolean'];
  rentChargeDay?: InputMaybe<Scalars['Int']>;
  type: Scalars['String'];
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  checkoutSessionUrl: Scalars['String'];
};

export type Comment = {
  __typename?: 'Comment';
  comment: Scalars['String'];
  dateTime: Scalars['String'];
  id: Scalars['ID'];
  userName: Scalars['String'];
};

export type CommentInput = {
  comment: Scalars['String'];
  dateTime: Scalars['String'];
  userName: Scalars['String'];
};

export type Contact = {
  __typename?: 'Contact';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  message: Scalars['String'];
  propertyId: Scalars['String'];
};

export type ContactInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  landlordId: Scalars['String'];
  lastName: Scalars['String'];
  message: Scalars['String'];
  propertyId: Scalars['String'];
};

export type GenerateRentPaymentResult = {
  __typename?: 'GenerateRentPaymentResult';
  rentPaymentUrl?: Maybe<Scalars['String']>;
};

export type GetPropertiesResult = {
  __typename?: 'GetPropertiesResult';
  properties?: Maybe<Array<Property>>;
  units: Array<Unit>;
};

export type LandlordBranding = {
  __typename?: 'LandlordBranding';
  colors: LandlordBrandingColors;
  logo?: Maybe<Scalars['String']>;
};

export type LandlordBrandingColors = {
  __typename?: 'LandlordBrandingColors';
  accent: Scalars['String'];
  background: Scalars['String'];
  footer: Scalars['String'];
  heading: Scalars['String'];
  primaryText: Scalars['String'];
  secondaryText: Scalars['String'];
};

export type LandlordBrandingColorsInput = {
  accent: Scalars['String'];
  background: Scalars['String'];
  footer: Scalars['String'];
  heading: Scalars['String'];
  primaryText: Scalars['String'];
  secondaryText: Scalars['String'];
};

export type LandlordBrandingInput = {
  colors: LandlordBrandingColorsInput;
  logo?: InputMaybe<Scalars['String']>;
};

export type LandlordPaymentAccountDetails = {
  __typename?: 'LandlordPaymentAccountDetails';
  account: Scalars['String'];
  accountLink: Scalars['String'];
};

export type LandlordStripeAccountInfo = {
  __typename?: 'LandlordStripeAccountInfo';
  chargesEnabled?: Maybe<Scalars['Boolean']>;
};

export enum LandlordSubscription {
  Basic = 'BASIC',
  Free = 'FREE',
  Premium = 'PREMIUM'
}

export type LandlordSubscriptionInfo = {
  __typename?: 'LandlordSubscriptionInfo';
  active: Scalars['Boolean'];
  planName?: Maybe<LandlordSubscription>;
  portalSessionUrl?: Maybe<Scalars['String']>;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  AddComment: Comment;
  AddOccupant: Occupant;
  AddProperty: Property;
  AddPropertyImage: Scalars['Boolean'];
  AddTenant: Account;
  AddUnit: Unit;
  ChargeRent: Occupant;
  CompleteLandlordOnboarding: PortalSession;
  CreateContact: Contact;
  GenerateRentPaymentForTenant?: Maybe<GenerateRentPaymentResult>;
  OnboardLandlord: CheckoutSession;
  RegisterLandlordForPayments: LandlordPaymentAccountDetails;
  RemoveOccupant: Scalars['Boolean'];
  RemoveProperty: Scalars['Boolean'];
  UpdateAccountInformation: UpdatedAccounInformation;
  UpdateLandlordBranding: LandlordBranding;
  UpdateOccupant: Occupant;
  UpdateProperty: Property;
  UpdateUnit: Unit;
};


export type MutationAddCommentArgs = {
  comment: CommentInput;
  landlordId: Scalars['String'];
  propertyId: Scalars['String'];
};


export type MutationAddOccupantArgs = {
  occupantInput: OccupantInput;
};


export type MutationAddPropertyArgs = {
  propertyInput: PropertyInput;
};


export type MutationAddPropertyImageArgs = {
  propertyImageInput?: InputMaybe<PropertyImageInput>;
};


export type MutationAddTenantArgs = {
  accountInput: AccountInput;
};


export type MutationAddUnitArgs = {
  landlordId: Scalars['String'];
  propertyId: Scalars['String'];
  unitInput: UnitInput;
};


export type MutationChargeRentArgs = {
  occupantId: Scalars['String'];
};


export type MutationCompleteLandlordOnboardingArgs = {
  sessionId: Scalars['String'];
};


export type MutationCreateContactArgs = {
  contactInput: ContactInput;
};


export type MutationGenerateRentPaymentForTenantArgs = {
  landlordId: Scalars['String'];
  occupantId: Scalars['String'];
};


export type MutationOnboardLandlordArgs = {
  company?: InputMaybe<Scalars['String']>;
  landlordSubscription: LandlordSubscription;
};


export type MutationRemoveOccupantArgs = {
  id: Scalars['ID'];
  landlordId: Scalars['String'];
};


export type MutationRemovePropertyArgs = {
  id: Scalars['ID'];
  landlordId: Scalars['String'];
};


export type MutationUpdateAccountInformationArgs = {
  updateAccountInformationInput: UpdateAccountInformationInput;
};


export type MutationUpdateLandlordBrandingArgs = {
  landlordBranding: LandlordBrandingInput;
};


export type MutationUpdateOccupantArgs = {
  occupantInput: OccupantInput;
};


export type MutationUpdatePropertyArgs = {
  propertyInput: PropertyInput;
};


export type MutationUpdateUnitArgs = {
  landlordId: Scalars['String'];
  unitInput: UnitInput;
};

export type Occupant = {
  __typename?: 'Occupant';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  landlordId: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['String']>;
  rentChargeDay?: Maybe<Scalars['Int']>;
  rentDue?: Maybe<Scalars['Boolean']>;
  timeline: Array<TimeLine>;
  unitId?: Maybe<Scalars['String']>;
  unitNumber?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['String']>;
};

export type OccupantInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  landlordId?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  propertyId?: InputMaybe<Scalars['String']>;
  rentChargeDay?: InputMaybe<Scalars['Int']>;
  unitId?: InputMaybe<Scalars['String']>;
};

export type PortalSession = {
  __typename?: 'PortalSession';
  portalSessionUrl: Scalars['String'];
};

export type Property = {
  __typename?: 'Property';
  about?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  forRent: Scalars['Boolean'];
  id: Scalars['String'];
  landlordId: Scalars['ID'];
  name: Scalars['String'];
  pictures: Array<Scalars['String']>;
  price: Scalars['Float'];
  timeline: Array<TimeLine>;
  type: Scalars['String'];
  units?: Maybe<Array<Unit>>;
};

export type PropertyImageInput = {
  filePath: Scalars['String'];
  landlordId?: InputMaybe<Scalars['String']>;
  propertyId?: InputMaybe<Scalars['String']>;
};

export type PropertyInput = {
  about?: InputMaybe<Scalars['String']>;
  address: Scalars['String'];
  forRent: Scalars['Boolean'];
  id: Scalars['String'];
  landlordId: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Float'];
  type: Scalars['String'];
  units?: InputMaybe<Array<UnitInputAddition>>;
};

export type Query = {
  __typename?: 'Query';
  GetComments?: Maybe<Array<Comment>>;
  GetContacts: Array<Contact>;
  GetLandlordSubscription: LandlordSubscriptionInfo;
  GetLandlords: Array<Account>;
  GetOccupant?: Maybe<Occupant>;
  GetOccupantsForLandlord?: Maybe<Array<Occupant>>;
  GetProperties?: Maybe<GetPropertiesResult>;
  GetProperty?: Maybe<Property>;
  GetRentalDetail?: Maybe<RentalDetail>;
  GetStripeAccountInfo: LandlordStripeAccountInfo;
  GetUnitForProperty?: Maybe<Unit>;
  GetUnitsForProperty: Array<Unit>;
  GetUser?: Maybe<Account>;
};


export type QueryGetCommentsArgs = {
  landlordId: Scalars['String'];
  propertyId: Scalars['String'];
};


export type QueryGetLandlordsArgs = {
  uids: Array<Scalars['String']>;
};


export type QueryGetOccupantArgs = {
  id: Scalars['ID'];
  landlordId: Scalars['String'];
};


export type QueryGetOccupantsForLandlordArgs = {
  landlordId: Scalars['ID'];
};


export type QueryGetPropertiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  landlordId: Scalars['ID'];
};


export type QueryGetPropertyArgs = {
  id: Scalars['ID'];
  landlordId: Scalars['String'];
};


export type QueryGetRentalDetailArgs = {
  landlordId: Scalars['String'];
  tenantEmail: Scalars['String'];
};


export type QueryGetUnitForPropertyArgs = {
  propertyId?: InputMaybe<Scalars['String']>;
  unitId?: InputMaybe<Scalars['String']>;
};


export type QueryGetUnitsForPropertyArgs = {
  landlordId: Scalars['String'];
  propertyId: Scalars['String'];
};


export type QueryGetUserArgs = {
  uid: Scalars['ID'];
};

export type RentalDetail = {
  __typename?: 'RentalDetail';
  landlordId: Scalars['ID'];
  landlordInfo: Account;
  properties: Array<Property>;
};

export type Tenant = {
  __typename?: 'Tenant';
  id: Scalars['ID'];
  rentChargeDay: Scalars['Int'];
};

export type TimeLine = {
  __typename?: 'TimeLine';
  content: Scalars['String'];
  date: Scalars['String'];
  datetime: Scalars['String'];
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type Unit = {
  __typename?: 'Unit';
  baths: Scalars['String'];
  id: Scalars['ID'];
  occupantIds: Array<Scalars['String']>;
  occupied: Scalars['Boolean'];
  propertyId: Scalars['String'];
  rooms: Scalars['String'];
  unitNumber: Scalars['String'];
  unitPrice?: Maybe<Scalars['String']>;
};

export type UnitInput = {
  baths?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  propertyId: Scalars['String'];
  rooms?: InputMaybe<Scalars['String']>;
  unitNumber?: InputMaybe<Scalars['String']>;
  unitPrice?: InputMaybe<Scalars['String']>;
};

export type UnitInputAddition = {
  baths?: InputMaybe<Scalars['String']>;
  propertyId: Scalars['String'];
  rooms?: InputMaybe<Scalars['String']>;
  unitNumber?: InputMaybe<Scalars['String']>;
  unitPrice?: InputMaybe<Scalars['String']>;
};

export type UpdateAccountInformationInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  preferredCurrency: Scalars['String'];
};

export type UpdatedAccounInformation = {
  __typename?: 'UpdatedAccounInformation';
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
};

export type GetUserQueryVariables = Exact<{
  uid: Scalars['ID'];
}>;


export type GetUserQuery = { __typename?: 'Query', GetUser?: { __typename?: 'Account', email: string, id: string, type: string, firstName?: string | null | undefined, lastName?: string | null | undefined, onboarding: boolean, landlords: Array<string>, preferredCurrency?: string | null | undefined, branding?: { __typename?: 'LandlordBranding', logo?: string | null | undefined, colors: { __typename?: 'LandlordBrandingColors', heading: string, background: string, primaryText: string, secondaryText: string, accent: string, footer: string } } | null | undefined } | null | undefined };

export type AddCommentMutationVariables = Exact<{
  landlordId: Scalars['String'];
  propertyId: Scalars['String'];
  comment: CommentInput;
}>;


export type AddCommentMutation = { __typename?: 'Mutation', AddComment: { __typename?: 'Comment', id: string, userName: string, comment: string, dateTime: string } };

export type GetCommentsQueryVariables = Exact<{
  landlordId: Scalars['String'];
  propertyId: Scalars['String'];
}>;


export type GetCommentsQuery = { __typename?: 'Query', GetComments?: Array<{ __typename?: 'Comment', id: string, userName: string, comment: string, dateTime: string }> | null | undefined };

export type UpdateLandlordBrandingMutationVariables = Exact<{
  branding: LandlordBrandingInput;
}>;


export type UpdateLandlordBrandingMutation = { __typename?: 'Mutation', UpdateLandlordBranding: { __typename?: 'LandlordBranding', logo?: string | null | undefined, colors: { __typename?: 'LandlordBrandingColors', heading: string, background: string, primaryText: string, secondaryText: string, accent: string, footer: string } } };

export type GetContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactsQuery = { __typename?: 'Query', GetContacts: Array<{ __typename?: 'Contact', id: string, email: string, firstName: string, lastName: string, message: string, propertyId: string }> };

export type GetRentalDetailQueryVariables = Exact<{
  landlordId: Scalars['String'];
  tenantEmail: Scalars['String'];
}>;


export type GetRentalDetailQuery = { __typename?: 'Query', GetRentalDetail?: { __typename?: 'RentalDetail', landlordId: string, properties: Array<{ __typename?: 'Property', address: string, price: number, about?: string | null | undefined }>, landlordInfo: { __typename?: 'Account', firstName?: string | null | undefined, lastName?: string | null | undefined, email: string } } | null | undefined };

export type GetLandlordsQueryVariables = Exact<{
  uids: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetLandlordsQuery = { __typename?: 'Query', GetLandlords: Array<{ __typename?: 'Account', id: string, email: string, firstName?: string | null | undefined, lastName?: string | null | undefined }> };

export type ContactLandlordMutationVariables = Exact<{
  contactInput: ContactInput;
}>;


export type ContactLandlordMutation = { __typename?: 'Mutation', CreateContact: { __typename?: 'Contact', id: string, email: string, firstName: string, lastName: string, message: string, propertyId: string } };

export type AddLandlordOnboardedMutationVariables = Exact<{
  subscriptionType: LandlordSubscription;
}>;


export type AddLandlordOnboardedMutation = { __typename?: 'Mutation', OnboardLandlord: { __typename?: 'CheckoutSession', checkoutSessionUrl: string } };

export type AddTenantOnboardedMutationVariables = Exact<{
  accountInput: AccountInput;
}>;


export type AddTenantOnboardedMutation = { __typename?: 'Mutation', AddTenant: { __typename?: 'Account', email: string, id: string, type: string, firstName?: string | null | undefined, lastName?: string | null | undefined, onboarding: boolean } };

export type CompleteLandlordOnboardingMutationVariables = Exact<{
  sessionId: Scalars['String'];
}>;


export type CompleteLandlordOnboardingMutation = { __typename?: 'Mutation', CompleteLandlordOnboarding: { __typename?: 'PortalSession', portalSessionUrl: string } };

export type RemovePropertyMutationVariables = Exact<{
  id: Scalars['ID'];
  landlordId: Scalars['String'];
}>;


export type RemovePropertyMutation = { __typename?: 'Mutation', RemoveProperty: boolean };

export type EditUnitMutationVariables = Exact<{
  landlordId: Scalars['String'];
  unitInput: UnitInput;
}>;


export type EditUnitMutation = { __typename?: 'Mutation', UpdateUnit: { __typename?: 'Unit', id: string, propertyId: string, unitNumber: string, rooms: string, baths: string, unitPrice?: string | null | undefined, occupied: boolean, occupantIds: Array<string> } };

export type GetPropertyQueryQueryVariables = Exact<{
  id: Scalars['ID'];
  landlordId: Scalars['String'];
}>;


export type GetPropertyQueryQuery = { __typename?: 'Query', GetProperty?: { __typename?: 'Property', landlordId: string, id: string, name: string, address: string, type: string, price: number, forRent: boolean, about?: string | null | undefined, pictures: Array<string>, units?: Array<{ __typename?: 'Unit', id: string, unitNumber: string, rooms: string, baths: string, unitPrice?: string | null | undefined, propertyId: string, occupied: boolean, occupantIds: Array<string> }> | null | undefined, timeline: Array<{ __typename?: 'TimeLine', id: string, type: string, content: string, date: string, datetime: string }> } | null | undefined };

export type AddPropertyImageMutationVariables = Exact<{
  propertyImageInput?: InputMaybe<PropertyImageInput>;
}>;


export type AddPropertyImageMutation = { __typename?: 'Mutation', AddPropertyImage: boolean };

export type UpdatePropertyMutationMutationVariables = Exact<{
  propertyInput: PropertyInput;
}>;


export type UpdatePropertyMutationMutation = { __typename?: 'Mutation', UpdateProperty: { __typename?: 'Property', name: string, landlordId: string, id: string, address: string, type: string, about?: string | null | undefined, units?: Array<{ __typename?: 'Unit', unitNumber: string }> | null | undefined } };

export type AddPropertyMutationMutationVariables = Exact<{
  propertyInput: PropertyInput;
}>;


export type AddPropertyMutationMutation = { __typename?: 'Mutation', AddProperty: { __typename?: 'Property', name: string, landlordId: string, id: string, address: string, type: string, units?: Array<{ __typename?: 'Unit', unitNumber: string }> | null | undefined } };

export type GetPropertiesQueryVariables = Exact<{
  landlordId: Scalars['ID'];
}>;


export type GetPropertiesQuery = { __typename?: 'Query', GetProperties?: { __typename?: 'GetPropertiesResult', properties?: Array<{ __typename?: 'Property', name: string, id: string, address: string, type: string, price: number, forRent: boolean, landlordId: string, pictures: Array<string>, about?: string | null | undefined, units?: Array<{ __typename?: 'Unit', id: string, unitPrice?: string | null | undefined, propertyId: string }> | null | undefined }> | null | undefined } | null | undefined };

export type GetLandlordSubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLandlordSubscriptionQuery = { __typename?: 'Query', GetLandlordSubscription: { __typename?: 'LandlordSubscriptionInfo', active: boolean, planName?: LandlordSubscription | null | undefined, portalSessionUrl?: string | null | undefined } };

export type GetStripeAccountInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStripeAccountInfoQuery = { __typename?: 'Query', GetStripeAccountInfo: { __typename?: 'LandlordStripeAccountInfo', chargesEnabled?: boolean | null | undefined } };

export type RegisterLandlordForPaymentsMutationVariables = Exact<{ [key: string]: never; }>;


export type RegisterLandlordForPaymentsMutation = { __typename?: 'Mutation', RegisterLandlordForPayments: { __typename?: 'LandlordPaymentAccountDetails', account: string, accountLink: string } };

export type UpdateAccountInformationMutationVariables = Exact<{
  updateAccountInformationInput: UpdateAccountInformationInput;
}>;


export type UpdateAccountInformationMutation = { __typename?: 'Mutation', UpdateAccountInformation: { __typename?: 'UpdatedAccounInformation', id: string, firstName: string, lastName: string } };

export type ChargeRentMutationMutationVariables = Exact<{
  occupantId: Scalars['String'];
}>;


export type ChargeRentMutationMutation = { __typename?: 'Mutation', ChargeRent: { __typename?: 'Occupant', id: string, rentDue?: boolean | null | undefined } };

export type GetOccupantQueryVariables = Exact<{
  id: Scalars['ID'];
  landlordId: Scalars['String'];
}>;


export type GetOccupantQuery = { __typename?: 'Query', GetOccupant?: { __typename?: 'Occupant', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, unitId?: string | null | undefined, propertyId?: string | null | undefined, email?: string | null | undefined, unitPrice?: string | null | undefined, unitNumber?: string | null | undefined, timeline: Array<{ __typename?: 'TimeLine', id: string, type: string, content: string, date: string, datetime: string }> } | null | undefined };

export type GetUnitForPropertyQueryVariables = Exact<{
  propertyId?: InputMaybe<Scalars['String']>;
  unitId?: InputMaybe<Scalars['String']>;
}>;


export type GetUnitForPropertyQuery = { __typename?: 'Query', GetUnitForProperty?: { __typename?: 'Unit', unitNumber: string, unitPrice?: string | null | undefined } | null | undefined };

export type UpdateOccupantMutationVariables = Exact<{
  occupantInput: OccupantInput;
}>;


export type UpdateOccupantMutation = { __typename?: 'Mutation', UpdateOccupant: { __typename?: 'Occupant', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, unitId?: string | null | undefined, propertyId?: string | null | undefined, email?: string | null | undefined } };

export type AddTenantMutationVariables = Exact<{
  occupantInput: OccupantInput;
}>;


export type AddTenantMutation = { __typename?: 'Mutation', AddOccupant: { __typename?: 'Occupant', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, unitId?: string | null | undefined, landlordId: string, propertyId?: string | null | undefined, email?: string | null | undefined } };

export type GetUnitsForPropertyQueryVariables = Exact<{
  landlordId: Scalars['String'];
  propertyId: Scalars['String'];
}>;


export type GetUnitsForPropertyQuery = { __typename?: 'Query', GetUnitsForProperty: Array<{ __typename?: 'Unit', id: string, propertyId: string, unitNumber: string, rooms: string, baths: string, unitPrice?: string | null | undefined, occupied: boolean, occupantIds: Array<string> }> };

export type GetTenantsQueryVariables = Exact<{
  landlordId: Scalars['ID'];
}>;


export type GetTenantsQuery = { __typename?: 'Query', GetOccupantsForLandlord?: Array<{ __typename?: 'Occupant', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, unitId?: string | null | undefined, propertyId?: string | null | undefined, email?: string | null | undefined, landlordId: string, timeline: Array<{ __typename?: 'TimeLine', id: string, type: string, content: string, date: string, datetime: string }> }> | null | undefined };

export type RemoveTenantMutationVariables = Exact<{
  id: Scalars['ID'];
  landlordId: Scalars['String'];
}>;


export type RemoveTenantMutation = { __typename?: 'Mutation', RemoveOccupant: boolean };

export type PropertiesQueryVariables = Exact<{
  landlordId: Scalars['ID'];
}>;


export type PropertiesQuery = { __typename?: 'Query', GetProperties?: { __typename?: 'GetPropertiesResult', properties?: Array<{ __typename?: 'Property', name: string, id: string, address: string, type: string, price: number, forRent: boolean, landlordId: string, units?: Array<{ __typename?: 'Unit', id: string, unitPrice?: string | null | undefined, propertyId: string }> | null | undefined }> | null | undefined } | null | undefined };


export const GetUserDocument = gql`
    query getUser($uid: ID!) {
  GetUser(uid: $uid) {
    email
    id
    type
    firstName
    lastName
    onboarding
    landlords
    preferredCurrency
    branding {
      logo
      colors {
        heading
        background
        primaryText
        secondaryText
        accent
        footer
      }
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const AddCommentDocument = gql`
    mutation AddComment($landlordId: String!, $propertyId: String!, $comment: CommentInput!) {
  AddComment(landlordId: $landlordId, propertyId: $propertyId, comment: $comment) {
    id
    userName
    comment
    dateTime
  }
}
    `;
export type AddCommentMutationFn = Apollo.MutationFunction<AddCommentMutation, AddCommentMutationVariables>;

/**
 * __useAddCommentMutation__
 *
 * To run a mutation, you first call `useAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentMutation, { data, loading, error }] = useAddCommentMutation({
 *   variables: {
 *      landlordId: // value for 'landlordId'
 *      propertyId: // value for 'propertyId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useAddCommentMutation(baseOptions?: Apollo.MutationHookOptions<AddCommentMutation, AddCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommentMutation, AddCommentMutationVariables>(AddCommentDocument, options);
      }
export type AddCommentMutationHookResult = ReturnType<typeof useAddCommentMutation>;
export type AddCommentMutationResult = Apollo.MutationResult<AddCommentMutation>;
export type AddCommentMutationOptions = Apollo.BaseMutationOptions<AddCommentMutation, AddCommentMutationVariables>;
export const GetCommentsDocument = gql`
    query GetComments($landlordId: String!, $propertyId: String!) {
  GetComments(landlordId: $landlordId, propertyId: $propertyId) {
    id
    userName
    comment
    dateTime
  }
}
    `;

/**
 * __useGetCommentsQuery__
 *
 * To run a query within a React component, call `useGetCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsQuery({
 *   variables: {
 *      landlordId: // value for 'landlordId'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetCommentsQuery(baseOptions: Apollo.QueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options);
      }
export function useGetCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options);
        }
export type GetCommentsQueryHookResult = ReturnType<typeof useGetCommentsQuery>;
export type GetCommentsLazyQueryHookResult = ReturnType<typeof useGetCommentsLazyQuery>;
export type GetCommentsQueryResult = Apollo.QueryResult<GetCommentsQuery, GetCommentsQueryVariables>;
export const UpdateLandlordBrandingDocument = gql`
    mutation UpdateLandlordBranding($branding: LandlordBrandingInput!) {
  UpdateLandlordBranding(landlordBranding: $branding) {
    logo
    colors {
      heading
      background
      primaryText
      secondaryText
      accent
      footer
    }
  }
}
    `;
export type UpdateLandlordBrandingMutationFn = Apollo.MutationFunction<UpdateLandlordBrandingMutation, UpdateLandlordBrandingMutationVariables>;

/**
 * __useUpdateLandlordBrandingMutation__
 *
 * To run a mutation, you first call `useUpdateLandlordBrandingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLandlordBrandingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLandlordBrandingMutation, { data, loading, error }] = useUpdateLandlordBrandingMutation({
 *   variables: {
 *      branding: // value for 'branding'
 *   },
 * });
 */
export function useUpdateLandlordBrandingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLandlordBrandingMutation, UpdateLandlordBrandingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLandlordBrandingMutation, UpdateLandlordBrandingMutationVariables>(UpdateLandlordBrandingDocument, options);
      }
export type UpdateLandlordBrandingMutationHookResult = ReturnType<typeof useUpdateLandlordBrandingMutation>;
export type UpdateLandlordBrandingMutationResult = Apollo.MutationResult<UpdateLandlordBrandingMutation>;
export type UpdateLandlordBrandingMutationOptions = Apollo.BaseMutationOptions<UpdateLandlordBrandingMutation, UpdateLandlordBrandingMutationVariables>;
export const GetContactsDocument = gql`
    query GetContacts {
  GetContacts {
    id
    email
    firstName
    lastName
    message
    propertyId
  }
}
    `;

/**
 * __useGetContactsQuery__
 *
 * To run a query within a React component, call `useGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactsQuery(baseOptions?: Apollo.QueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, options);
      }
export function useGetContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, options);
        }
export type GetContactsQueryHookResult = ReturnType<typeof useGetContactsQuery>;
export type GetContactsLazyQueryHookResult = ReturnType<typeof useGetContactsLazyQuery>;
export type GetContactsQueryResult = Apollo.QueryResult<GetContactsQuery, GetContactsQueryVariables>;
export const GetRentalDetailDocument = gql`
    query GetRentalDetail($landlordId: String!, $tenantEmail: String!) {
  GetRentalDetail(landlordId: $landlordId, tenantEmail: $tenantEmail) {
    landlordId
    properties {
      address
      price
      about
    }
    landlordInfo {
      firstName
      lastName
      email
    }
  }
}
    `;

/**
 * __useGetRentalDetailQuery__
 *
 * To run a query within a React component, call `useGetRentalDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRentalDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRentalDetailQuery({
 *   variables: {
 *      landlordId: // value for 'landlordId'
 *      tenantEmail: // value for 'tenantEmail'
 *   },
 * });
 */
export function useGetRentalDetailQuery(baseOptions: Apollo.QueryHookOptions<GetRentalDetailQuery, GetRentalDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRentalDetailQuery, GetRentalDetailQueryVariables>(GetRentalDetailDocument, options);
      }
export function useGetRentalDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRentalDetailQuery, GetRentalDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRentalDetailQuery, GetRentalDetailQueryVariables>(GetRentalDetailDocument, options);
        }
export type GetRentalDetailQueryHookResult = ReturnType<typeof useGetRentalDetailQuery>;
export type GetRentalDetailLazyQueryHookResult = ReturnType<typeof useGetRentalDetailLazyQuery>;
export type GetRentalDetailQueryResult = Apollo.QueryResult<GetRentalDetailQuery, GetRentalDetailQueryVariables>;
export const GetLandlordsDocument = gql`
    query GetLandlords($uids: [String!]!) {
  GetLandlords(uids: $uids) {
    id
    email
    firstName
    lastName
  }
}
    `;

/**
 * __useGetLandlordsQuery__
 *
 * To run a query within a React component, call `useGetLandlordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLandlordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLandlordsQuery({
 *   variables: {
 *      uids: // value for 'uids'
 *   },
 * });
 */
export function useGetLandlordsQuery(baseOptions: Apollo.QueryHookOptions<GetLandlordsQuery, GetLandlordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLandlordsQuery, GetLandlordsQueryVariables>(GetLandlordsDocument, options);
      }
export function useGetLandlordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLandlordsQuery, GetLandlordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLandlordsQuery, GetLandlordsQueryVariables>(GetLandlordsDocument, options);
        }
export type GetLandlordsQueryHookResult = ReturnType<typeof useGetLandlordsQuery>;
export type GetLandlordsLazyQueryHookResult = ReturnType<typeof useGetLandlordsLazyQuery>;
export type GetLandlordsQueryResult = Apollo.QueryResult<GetLandlordsQuery, GetLandlordsQueryVariables>;
export const ContactLandlordDocument = gql`
    mutation ContactLandlord($contactInput: ContactInput!) {
  CreateContact(contactInput: $contactInput) {
    id
    email
    firstName
    lastName
    message
    propertyId
  }
}
    `;
export type ContactLandlordMutationFn = Apollo.MutationFunction<ContactLandlordMutation, ContactLandlordMutationVariables>;

/**
 * __useContactLandlordMutation__
 *
 * To run a mutation, you first call `useContactLandlordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactLandlordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactLandlordMutation, { data, loading, error }] = useContactLandlordMutation({
 *   variables: {
 *      contactInput: // value for 'contactInput'
 *   },
 * });
 */
export function useContactLandlordMutation(baseOptions?: Apollo.MutationHookOptions<ContactLandlordMutation, ContactLandlordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactLandlordMutation, ContactLandlordMutationVariables>(ContactLandlordDocument, options);
      }
export type ContactLandlordMutationHookResult = ReturnType<typeof useContactLandlordMutation>;
export type ContactLandlordMutationResult = Apollo.MutationResult<ContactLandlordMutation>;
export type ContactLandlordMutationOptions = Apollo.BaseMutationOptions<ContactLandlordMutation, ContactLandlordMutationVariables>;
export const AddLandlordOnboardedDocument = gql`
    mutation AddLandlordOnboarded($subscriptionType: LandlordSubscription!) {
  OnboardLandlord(landlordSubscription: $subscriptionType) {
    checkoutSessionUrl
  }
}
    `;
export type AddLandlordOnboardedMutationFn = Apollo.MutationFunction<AddLandlordOnboardedMutation, AddLandlordOnboardedMutationVariables>;

/**
 * __useAddLandlordOnboardedMutation__
 *
 * To run a mutation, you first call `useAddLandlordOnboardedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLandlordOnboardedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLandlordOnboardedMutation, { data, loading, error }] = useAddLandlordOnboardedMutation({
 *   variables: {
 *      subscriptionType: // value for 'subscriptionType'
 *   },
 * });
 */
export function useAddLandlordOnboardedMutation(baseOptions?: Apollo.MutationHookOptions<AddLandlordOnboardedMutation, AddLandlordOnboardedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLandlordOnboardedMutation, AddLandlordOnboardedMutationVariables>(AddLandlordOnboardedDocument, options);
      }
export type AddLandlordOnboardedMutationHookResult = ReturnType<typeof useAddLandlordOnboardedMutation>;
export type AddLandlordOnboardedMutationResult = Apollo.MutationResult<AddLandlordOnboardedMutation>;
export type AddLandlordOnboardedMutationOptions = Apollo.BaseMutationOptions<AddLandlordOnboardedMutation, AddLandlordOnboardedMutationVariables>;
export const AddTenantOnboardedDocument = gql`
    mutation AddTenantOnboarded($accountInput: AccountInput!) {
  AddTenant(accountInput: $accountInput) {
    email
    id
    type
    firstName
    lastName
    onboarding
  }
}
    `;
export type AddTenantOnboardedMutationFn = Apollo.MutationFunction<AddTenantOnboardedMutation, AddTenantOnboardedMutationVariables>;

/**
 * __useAddTenantOnboardedMutation__
 *
 * To run a mutation, you first call `useAddTenantOnboardedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTenantOnboardedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTenantOnboardedMutation, { data, loading, error }] = useAddTenantOnboardedMutation({
 *   variables: {
 *      accountInput: // value for 'accountInput'
 *   },
 * });
 */
export function useAddTenantOnboardedMutation(baseOptions?: Apollo.MutationHookOptions<AddTenantOnboardedMutation, AddTenantOnboardedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTenantOnboardedMutation, AddTenantOnboardedMutationVariables>(AddTenantOnboardedDocument, options);
      }
export type AddTenantOnboardedMutationHookResult = ReturnType<typeof useAddTenantOnboardedMutation>;
export type AddTenantOnboardedMutationResult = Apollo.MutationResult<AddTenantOnboardedMutation>;
export type AddTenantOnboardedMutationOptions = Apollo.BaseMutationOptions<AddTenantOnboardedMutation, AddTenantOnboardedMutationVariables>;
export const CompleteLandlordOnboardingDocument = gql`
    mutation CompleteLandlordOnboarding($sessionId: String!) {
  CompleteLandlordOnboarding(sessionId: $sessionId) {
    portalSessionUrl
  }
}
    `;
export type CompleteLandlordOnboardingMutationFn = Apollo.MutationFunction<CompleteLandlordOnboardingMutation, CompleteLandlordOnboardingMutationVariables>;

/**
 * __useCompleteLandlordOnboardingMutation__
 *
 * To run a mutation, you first call `useCompleteLandlordOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteLandlordOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeLandlordOnboardingMutation, { data, loading, error }] = useCompleteLandlordOnboardingMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useCompleteLandlordOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<CompleteLandlordOnboardingMutation, CompleteLandlordOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteLandlordOnboardingMutation, CompleteLandlordOnboardingMutationVariables>(CompleteLandlordOnboardingDocument, options);
      }
export type CompleteLandlordOnboardingMutationHookResult = ReturnType<typeof useCompleteLandlordOnboardingMutation>;
export type CompleteLandlordOnboardingMutationResult = Apollo.MutationResult<CompleteLandlordOnboardingMutation>;
export type CompleteLandlordOnboardingMutationOptions = Apollo.BaseMutationOptions<CompleteLandlordOnboardingMutation, CompleteLandlordOnboardingMutationVariables>;
export const RemovePropertyDocument = gql`
    mutation RemoveProperty($id: ID!, $landlordId: String!) {
  RemoveProperty(id: $id, landlordId: $landlordId)
}
    `;
export type RemovePropertyMutationFn = Apollo.MutationFunction<RemovePropertyMutation, RemovePropertyMutationVariables>;

/**
 * __useRemovePropertyMutation__
 *
 * To run a mutation, you first call `useRemovePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePropertyMutation, { data, loading, error }] = useRemovePropertyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      landlordId: // value for 'landlordId'
 *   },
 * });
 */
export function useRemovePropertyMutation(baseOptions?: Apollo.MutationHookOptions<RemovePropertyMutation, RemovePropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePropertyMutation, RemovePropertyMutationVariables>(RemovePropertyDocument, options);
      }
export type RemovePropertyMutationHookResult = ReturnType<typeof useRemovePropertyMutation>;
export type RemovePropertyMutationResult = Apollo.MutationResult<RemovePropertyMutation>;
export type RemovePropertyMutationOptions = Apollo.BaseMutationOptions<RemovePropertyMutation, RemovePropertyMutationVariables>;
export const EditUnitDocument = gql`
    mutation EditUnit($landlordId: String!, $unitInput: UnitInput!) {
  UpdateUnit(landlordId: $landlordId, unitInput: $unitInput) {
    id
    propertyId
    unitNumber
    rooms
    baths
    unitPrice
    occupied
    occupantIds
  }
}
    `;
export type EditUnitMutationFn = Apollo.MutationFunction<EditUnitMutation, EditUnitMutationVariables>;

/**
 * __useEditUnitMutation__
 *
 * To run a mutation, you first call `useEditUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUnitMutation, { data, loading, error }] = useEditUnitMutation({
 *   variables: {
 *      landlordId: // value for 'landlordId'
 *      unitInput: // value for 'unitInput'
 *   },
 * });
 */
export function useEditUnitMutation(baseOptions?: Apollo.MutationHookOptions<EditUnitMutation, EditUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUnitMutation, EditUnitMutationVariables>(EditUnitDocument, options);
      }
export type EditUnitMutationHookResult = ReturnType<typeof useEditUnitMutation>;
export type EditUnitMutationResult = Apollo.MutationResult<EditUnitMutation>;
export type EditUnitMutationOptions = Apollo.BaseMutationOptions<EditUnitMutation, EditUnitMutationVariables>;
export const GetPropertyQueryDocument = gql`
    query GetPropertyQuery($id: ID!, $landlordId: String!) {
  GetProperty(id: $id, landlordId: $landlordId) {
    landlordId
    id
    name
    address
    type
    price
    forRent
    units {
      id
      unitNumber
      rooms
      baths
      unitPrice
      propertyId
      occupied
      occupantIds
    }
    about
    pictures
    timeline {
      id
      type
      content
      date
      datetime
    }
  }
}
    `;

/**
 * __useGetPropertyQueryQuery__
 *
 * To run a query within a React component, call `useGetPropertyQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      landlordId: // value for 'landlordId'
 *   },
 * });
 */
export function useGetPropertyQueryQuery(baseOptions: Apollo.QueryHookOptions<GetPropertyQueryQuery, GetPropertyQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropertyQueryQuery, GetPropertyQueryQueryVariables>(GetPropertyQueryDocument, options);
      }
export function useGetPropertyQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyQueryQuery, GetPropertyQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropertyQueryQuery, GetPropertyQueryQueryVariables>(GetPropertyQueryDocument, options);
        }
export type GetPropertyQueryQueryHookResult = ReturnType<typeof useGetPropertyQueryQuery>;
export type GetPropertyQueryLazyQueryHookResult = ReturnType<typeof useGetPropertyQueryLazyQuery>;
export type GetPropertyQueryQueryResult = Apollo.QueryResult<GetPropertyQueryQuery, GetPropertyQueryQueryVariables>;
export const AddPropertyImageDocument = gql`
    mutation AddPropertyImage($propertyImageInput: PropertyImageInput) {
  AddPropertyImage(propertyImageInput: $propertyImageInput)
}
    `;
export type AddPropertyImageMutationFn = Apollo.MutationFunction<AddPropertyImageMutation, AddPropertyImageMutationVariables>;

/**
 * __useAddPropertyImageMutation__
 *
 * To run a mutation, you first call `useAddPropertyImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPropertyImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPropertyImageMutation, { data, loading, error }] = useAddPropertyImageMutation({
 *   variables: {
 *      propertyImageInput: // value for 'propertyImageInput'
 *   },
 * });
 */
export function useAddPropertyImageMutation(baseOptions?: Apollo.MutationHookOptions<AddPropertyImageMutation, AddPropertyImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPropertyImageMutation, AddPropertyImageMutationVariables>(AddPropertyImageDocument, options);
      }
export type AddPropertyImageMutationHookResult = ReturnType<typeof useAddPropertyImageMutation>;
export type AddPropertyImageMutationResult = Apollo.MutationResult<AddPropertyImageMutation>;
export type AddPropertyImageMutationOptions = Apollo.BaseMutationOptions<AddPropertyImageMutation, AddPropertyImageMutationVariables>;
export const UpdatePropertyMutationDocument = gql`
    mutation UpdatePropertyMutation($propertyInput: PropertyInput!) {
  UpdateProperty(propertyInput: $propertyInput) {
    name
    landlordId
    id
    address
    type
    units {
      unitNumber
    }
    about
  }
}
    `;
export type UpdatePropertyMutationMutationFn = Apollo.MutationFunction<UpdatePropertyMutationMutation, UpdatePropertyMutationMutationVariables>;

/**
 * __useUpdatePropertyMutationMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyMutationMutation, { data, loading, error }] = useUpdatePropertyMutationMutation({
 *   variables: {
 *      propertyInput: // value for 'propertyInput'
 *   },
 * });
 */
export function useUpdatePropertyMutationMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePropertyMutationMutation, UpdatePropertyMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePropertyMutationMutation, UpdatePropertyMutationMutationVariables>(UpdatePropertyMutationDocument, options);
      }
export type UpdatePropertyMutationMutationHookResult = ReturnType<typeof useUpdatePropertyMutationMutation>;
export type UpdatePropertyMutationMutationResult = Apollo.MutationResult<UpdatePropertyMutationMutation>;
export type UpdatePropertyMutationMutationOptions = Apollo.BaseMutationOptions<UpdatePropertyMutationMutation, UpdatePropertyMutationMutationVariables>;
export const AddPropertyMutationDocument = gql`
    mutation AddPropertyMutation($propertyInput: PropertyInput!) {
  AddProperty(propertyInput: $propertyInput) {
    name
    landlordId
    id
    address
    type
    units {
      unitNumber
    }
  }
}
    `;
export type AddPropertyMutationMutationFn = Apollo.MutationFunction<AddPropertyMutationMutation, AddPropertyMutationMutationVariables>;

/**
 * __useAddPropertyMutationMutation__
 *
 * To run a mutation, you first call `useAddPropertyMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPropertyMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPropertyMutationMutation, { data, loading, error }] = useAddPropertyMutationMutation({
 *   variables: {
 *      propertyInput: // value for 'propertyInput'
 *   },
 * });
 */
export function useAddPropertyMutationMutation(baseOptions?: Apollo.MutationHookOptions<AddPropertyMutationMutation, AddPropertyMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPropertyMutationMutation, AddPropertyMutationMutationVariables>(AddPropertyMutationDocument, options);
      }
export type AddPropertyMutationMutationHookResult = ReturnType<typeof useAddPropertyMutationMutation>;
export type AddPropertyMutationMutationResult = Apollo.MutationResult<AddPropertyMutationMutation>;
export type AddPropertyMutationMutationOptions = Apollo.BaseMutationOptions<AddPropertyMutationMutation, AddPropertyMutationMutationVariables>;
export const GetPropertiesDocument = gql`
    query getProperties($landlordId: ID!) {
  GetProperties(landlordId: $landlordId) {
    properties {
      name
      id
      address
      type
      price
      forRent
      landlordId
      pictures
      about
      units {
        id
        unitPrice
        propertyId
      }
    }
  }
}
    `;

/**
 * __useGetPropertiesQuery__
 *
 * To run a query within a React component, call `useGetPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertiesQuery({
 *   variables: {
 *      landlordId: // value for 'landlordId'
 *   },
 * });
 */
export function useGetPropertiesQuery(baseOptions: Apollo.QueryHookOptions<GetPropertiesQuery, GetPropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPropertiesQuery, GetPropertiesQueryVariables>(GetPropertiesDocument, options);
      }
export function useGetPropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertiesQuery, GetPropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPropertiesQuery, GetPropertiesQueryVariables>(GetPropertiesDocument, options);
        }
export type GetPropertiesQueryHookResult = ReturnType<typeof useGetPropertiesQuery>;
export type GetPropertiesLazyQueryHookResult = ReturnType<typeof useGetPropertiesLazyQuery>;
export type GetPropertiesQueryResult = Apollo.QueryResult<GetPropertiesQuery, GetPropertiesQueryVariables>;
export const GetLandlordSubscriptionDocument = gql`
    query GetLandlordSubscription {
  GetLandlordSubscription {
    active
    planName
    portalSessionUrl
  }
}
    `;

/**
 * __useGetLandlordSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetLandlordSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLandlordSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLandlordSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLandlordSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<GetLandlordSubscriptionQuery, GetLandlordSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLandlordSubscriptionQuery, GetLandlordSubscriptionQueryVariables>(GetLandlordSubscriptionDocument, options);
      }
export function useGetLandlordSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLandlordSubscriptionQuery, GetLandlordSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLandlordSubscriptionQuery, GetLandlordSubscriptionQueryVariables>(GetLandlordSubscriptionDocument, options);
        }
export type GetLandlordSubscriptionQueryHookResult = ReturnType<typeof useGetLandlordSubscriptionQuery>;
export type GetLandlordSubscriptionLazyQueryHookResult = ReturnType<typeof useGetLandlordSubscriptionLazyQuery>;
export type GetLandlordSubscriptionQueryResult = Apollo.QueryResult<GetLandlordSubscriptionQuery, GetLandlordSubscriptionQueryVariables>;
export const GetStripeAccountInfoDocument = gql`
    query GetStripeAccountInfo {
  GetStripeAccountInfo {
    chargesEnabled
  }
}
    `;

/**
 * __useGetStripeAccountInfoQuery__
 *
 * To run a query within a React component, call `useGetStripeAccountInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeAccountInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeAccountInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripeAccountInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetStripeAccountInfoQuery, GetStripeAccountInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeAccountInfoQuery, GetStripeAccountInfoQueryVariables>(GetStripeAccountInfoDocument, options);
      }
export function useGetStripeAccountInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeAccountInfoQuery, GetStripeAccountInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeAccountInfoQuery, GetStripeAccountInfoQueryVariables>(GetStripeAccountInfoDocument, options);
        }
export type GetStripeAccountInfoQueryHookResult = ReturnType<typeof useGetStripeAccountInfoQuery>;
export type GetStripeAccountInfoLazyQueryHookResult = ReturnType<typeof useGetStripeAccountInfoLazyQuery>;
export type GetStripeAccountInfoQueryResult = Apollo.QueryResult<GetStripeAccountInfoQuery, GetStripeAccountInfoQueryVariables>;
export const RegisterLandlordForPaymentsDocument = gql`
    mutation RegisterLandlordForPayments {
  RegisterLandlordForPayments {
    account
    accountLink
  }
}
    `;
export type RegisterLandlordForPaymentsMutationFn = Apollo.MutationFunction<RegisterLandlordForPaymentsMutation, RegisterLandlordForPaymentsMutationVariables>;

/**
 * __useRegisterLandlordForPaymentsMutation__
 *
 * To run a mutation, you first call `useRegisterLandlordForPaymentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterLandlordForPaymentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerLandlordForPaymentsMutation, { data, loading, error }] = useRegisterLandlordForPaymentsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRegisterLandlordForPaymentsMutation(baseOptions?: Apollo.MutationHookOptions<RegisterLandlordForPaymentsMutation, RegisterLandlordForPaymentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterLandlordForPaymentsMutation, RegisterLandlordForPaymentsMutationVariables>(RegisterLandlordForPaymentsDocument, options);
      }
export type RegisterLandlordForPaymentsMutationHookResult = ReturnType<typeof useRegisterLandlordForPaymentsMutation>;
export type RegisterLandlordForPaymentsMutationResult = Apollo.MutationResult<RegisterLandlordForPaymentsMutation>;
export type RegisterLandlordForPaymentsMutationOptions = Apollo.BaseMutationOptions<RegisterLandlordForPaymentsMutation, RegisterLandlordForPaymentsMutationVariables>;
export const UpdateAccountInformationDocument = gql`
    mutation UpdateAccountInformation($updateAccountInformationInput: UpdateAccountInformationInput!) {
  UpdateAccountInformation(
    updateAccountInformationInput: $updateAccountInformationInput
  ) {
    id
    firstName
    lastName
  }
}
    `;
export type UpdateAccountInformationMutationFn = Apollo.MutationFunction<UpdateAccountInformationMutation, UpdateAccountInformationMutationVariables>;

/**
 * __useUpdateAccountInformationMutation__
 *
 * To run a mutation, you first call `useUpdateAccountInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountInformationMutation, { data, loading, error }] = useUpdateAccountInformationMutation({
 *   variables: {
 *      updateAccountInformationInput: // value for 'updateAccountInformationInput'
 *   },
 * });
 */
export function useUpdateAccountInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountInformationMutation, UpdateAccountInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountInformationMutation, UpdateAccountInformationMutationVariables>(UpdateAccountInformationDocument, options);
      }
export type UpdateAccountInformationMutationHookResult = ReturnType<typeof useUpdateAccountInformationMutation>;
export type UpdateAccountInformationMutationResult = Apollo.MutationResult<UpdateAccountInformationMutation>;
export type UpdateAccountInformationMutationOptions = Apollo.BaseMutationOptions<UpdateAccountInformationMutation, UpdateAccountInformationMutationVariables>;
export const ChargeRentMutationDocument = gql`
    mutation ChargeRentMutation($occupantId: String!) {
  ChargeRent(occupantId: $occupantId) {
    id
    rentDue
  }
}
    `;
export type ChargeRentMutationMutationFn = Apollo.MutationFunction<ChargeRentMutationMutation, ChargeRentMutationMutationVariables>;

/**
 * __useChargeRentMutationMutation__
 *
 * To run a mutation, you first call `useChargeRentMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeRentMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeRentMutationMutation, { data, loading, error }] = useChargeRentMutationMutation({
 *   variables: {
 *      occupantId: // value for 'occupantId'
 *   },
 * });
 */
export function useChargeRentMutationMutation(baseOptions?: Apollo.MutationHookOptions<ChargeRentMutationMutation, ChargeRentMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChargeRentMutationMutation, ChargeRentMutationMutationVariables>(ChargeRentMutationDocument, options);
      }
export type ChargeRentMutationMutationHookResult = ReturnType<typeof useChargeRentMutationMutation>;
export type ChargeRentMutationMutationResult = Apollo.MutationResult<ChargeRentMutationMutation>;
export type ChargeRentMutationMutationOptions = Apollo.BaseMutationOptions<ChargeRentMutationMutation, ChargeRentMutationMutationVariables>;
export const GetOccupantDocument = gql`
    query GetOccupant($id: ID!, $landlordId: String!) {
  GetOccupant(id: $id, landlordId: $landlordId) {
    id
    firstName
    lastName
    unitId
    propertyId
    email
    unitPrice
    unitNumber
    timeline {
      id
      type
      content
      date
      datetime
    }
  }
}
    `;

/**
 * __useGetOccupantQuery__
 *
 * To run a query within a React component, call `useGetOccupantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOccupantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOccupantQuery({
 *   variables: {
 *      id: // value for 'id'
 *      landlordId: // value for 'landlordId'
 *   },
 * });
 */
export function useGetOccupantQuery(baseOptions: Apollo.QueryHookOptions<GetOccupantQuery, GetOccupantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOccupantQuery, GetOccupantQueryVariables>(GetOccupantDocument, options);
      }
export function useGetOccupantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOccupantQuery, GetOccupantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOccupantQuery, GetOccupantQueryVariables>(GetOccupantDocument, options);
        }
export type GetOccupantQueryHookResult = ReturnType<typeof useGetOccupantQuery>;
export type GetOccupantLazyQueryHookResult = ReturnType<typeof useGetOccupantLazyQuery>;
export type GetOccupantQueryResult = Apollo.QueryResult<GetOccupantQuery, GetOccupantQueryVariables>;
export const GetUnitForPropertyDocument = gql`
    query GetUnitForProperty($propertyId: String, $unitId: String) {
  GetUnitForProperty(propertyId: $propertyId, unitId: $unitId) {
    unitNumber
    unitPrice
  }
}
    `;

/**
 * __useGetUnitForPropertyQuery__
 *
 * To run a query within a React component, call `useGetUnitForPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitForPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitForPropertyQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useGetUnitForPropertyQuery(baseOptions?: Apollo.QueryHookOptions<GetUnitForPropertyQuery, GetUnitForPropertyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitForPropertyQuery, GetUnitForPropertyQueryVariables>(GetUnitForPropertyDocument, options);
      }
export function useGetUnitForPropertyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitForPropertyQuery, GetUnitForPropertyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitForPropertyQuery, GetUnitForPropertyQueryVariables>(GetUnitForPropertyDocument, options);
        }
export type GetUnitForPropertyQueryHookResult = ReturnType<typeof useGetUnitForPropertyQuery>;
export type GetUnitForPropertyLazyQueryHookResult = ReturnType<typeof useGetUnitForPropertyLazyQuery>;
export type GetUnitForPropertyQueryResult = Apollo.QueryResult<GetUnitForPropertyQuery, GetUnitForPropertyQueryVariables>;
export const UpdateOccupantDocument = gql`
    mutation UpdateOccupant($occupantInput: OccupantInput!) {
  UpdateOccupant(occupantInput: $occupantInput) {
    id
    firstName
    lastName
    unitId
    propertyId
    email
  }
}
    `;
export type UpdateOccupantMutationFn = Apollo.MutationFunction<UpdateOccupantMutation, UpdateOccupantMutationVariables>;

/**
 * __useUpdateOccupantMutation__
 *
 * To run a mutation, you first call `useUpdateOccupantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOccupantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOccupantMutation, { data, loading, error }] = useUpdateOccupantMutation({
 *   variables: {
 *      occupantInput: // value for 'occupantInput'
 *   },
 * });
 */
export function useUpdateOccupantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOccupantMutation, UpdateOccupantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOccupantMutation, UpdateOccupantMutationVariables>(UpdateOccupantDocument, options);
      }
export type UpdateOccupantMutationHookResult = ReturnType<typeof useUpdateOccupantMutation>;
export type UpdateOccupantMutationResult = Apollo.MutationResult<UpdateOccupantMutation>;
export type UpdateOccupantMutationOptions = Apollo.BaseMutationOptions<UpdateOccupantMutation, UpdateOccupantMutationVariables>;
export const AddTenantDocument = gql`
    mutation AddTenant($occupantInput: OccupantInput!) {
  AddOccupant(occupantInput: $occupantInput) {
    id
    firstName
    lastName
    unitId
    landlordId
    propertyId
    email
  }
}
    `;
export type AddTenantMutationFn = Apollo.MutationFunction<AddTenantMutation, AddTenantMutationVariables>;

/**
 * __useAddTenantMutation__
 *
 * To run a mutation, you first call `useAddTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTenantMutation, { data, loading, error }] = useAddTenantMutation({
 *   variables: {
 *      occupantInput: // value for 'occupantInput'
 *   },
 * });
 */
export function useAddTenantMutation(baseOptions?: Apollo.MutationHookOptions<AddTenantMutation, AddTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTenantMutation, AddTenantMutationVariables>(AddTenantDocument, options);
      }
export type AddTenantMutationHookResult = ReturnType<typeof useAddTenantMutation>;
export type AddTenantMutationResult = Apollo.MutationResult<AddTenantMutation>;
export type AddTenantMutationOptions = Apollo.BaseMutationOptions<AddTenantMutation, AddTenantMutationVariables>;
export const GetUnitsForPropertyDocument = gql`
    query GetUnitsForProperty($landlordId: String!, $propertyId: String!) {
  GetUnitsForProperty(landlordId: $landlordId, propertyId: $propertyId) {
    id
    propertyId
    unitNumber
    rooms
    baths
    unitPrice
    occupied
    occupantIds
  }
}
    `;

/**
 * __useGetUnitsForPropertyQuery__
 *
 * To run a query within a React component, call `useGetUnitsForPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitsForPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitsForPropertyQuery({
 *   variables: {
 *      landlordId: // value for 'landlordId'
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetUnitsForPropertyQuery(baseOptions: Apollo.QueryHookOptions<GetUnitsForPropertyQuery, GetUnitsForPropertyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitsForPropertyQuery, GetUnitsForPropertyQueryVariables>(GetUnitsForPropertyDocument, options);
      }
export function useGetUnitsForPropertyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitsForPropertyQuery, GetUnitsForPropertyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitsForPropertyQuery, GetUnitsForPropertyQueryVariables>(GetUnitsForPropertyDocument, options);
        }
export type GetUnitsForPropertyQueryHookResult = ReturnType<typeof useGetUnitsForPropertyQuery>;
export type GetUnitsForPropertyLazyQueryHookResult = ReturnType<typeof useGetUnitsForPropertyLazyQuery>;
export type GetUnitsForPropertyQueryResult = Apollo.QueryResult<GetUnitsForPropertyQuery, GetUnitsForPropertyQueryVariables>;
export const GetTenantsDocument = gql`
    query GetTenants($landlordId: ID!) {
  GetOccupantsForLandlord(landlordId: $landlordId) {
    id
    firstName
    lastName
    unitId
    propertyId
    email
    landlordId
    timeline {
      id
      type
      content
      date
      datetime
    }
  }
}
    `;

/**
 * __useGetTenantsQuery__
 *
 * To run a query within a React component, call `useGetTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsQuery({
 *   variables: {
 *      landlordId: // value for 'landlordId'
 *   },
 * });
 */
export function useGetTenantsQuery(baseOptions: Apollo.QueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
      }
export function useGetTenantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
        }
export type GetTenantsQueryHookResult = ReturnType<typeof useGetTenantsQuery>;
export type GetTenantsLazyQueryHookResult = ReturnType<typeof useGetTenantsLazyQuery>;
export type GetTenantsQueryResult = Apollo.QueryResult<GetTenantsQuery, GetTenantsQueryVariables>;
export const RemoveTenantDocument = gql`
    mutation RemoveTenant($id: ID!, $landlordId: String!) {
  RemoveOccupant(id: $id, landlordId: $landlordId)
}
    `;
export type RemoveTenantMutationFn = Apollo.MutationFunction<RemoveTenantMutation, RemoveTenantMutationVariables>;

/**
 * __useRemoveTenantMutation__
 *
 * To run a mutation, you first call `useRemoveTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTenantMutation, { data, loading, error }] = useRemoveTenantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      landlordId: // value for 'landlordId'
 *   },
 * });
 */
export function useRemoveTenantMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTenantMutation, RemoveTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTenantMutation, RemoveTenantMutationVariables>(RemoveTenantDocument, options);
      }
export type RemoveTenantMutationHookResult = ReturnType<typeof useRemoveTenantMutation>;
export type RemoveTenantMutationResult = Apollo.MutationResult<RemoveTenantMutation>;
export type RemoveTenantMutationOptions = Apollo.BaseMutationOptions<RemoveTenantMutation, RemoveTenantMutationVariables>;
export const PropertiesDocument = gql`
    query Properties($landlordId: ID!) {
  GetProperties(landlordId: $landlordId) {
    properties {
      name
      id
      address
      type
      price
      forRent
      landlordId
      units {
        id
        unitPrice
        propertyId
      }
    }
  }
}
    `;

/**
 * __usePropertiesQuery__
 *
 * To run a query within a React component, call `usePropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertiesQuery({
 *   variables: {
 *      landlordId: // value for 'landlordId'
 *   },
 * });
 */
export function usePropertiesQuery(baseOptions: Apollo.QueryHookOptions<PropertiesQuery, PropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PropertiesQuery, PropertiesQueryVariables>(PropertiesDocument, options);
      }
export function usePropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PropertiesQuery, PropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PropertiesQuery, PropertiesQueryVariables>(PropertiesDocument, options);
        }
export type PropertiesQueryHookResult = ReturnType<typeof usePropertiesQuery>;
export type PropertiesLazyQueryHookResult = ReturnType<typeof usePropertiesLazyQuery>;
export type PropertiesQueryResult = Apollo.QueryResult<PropertiesQuery, PropertiesQueryVariables>;