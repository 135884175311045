import React from 'react';
import { XCircleIcon } from '@heroicons/react/solid';

interface Props {
    errors: string[];
}
export function ErrorBanner({ errors }: Props) {
    return (
        <div
            className="rounded-md bg-red-50 p-4"
            style={{ marginBottom: '25px' }}
        >
            <div className="flex">
                <div className="flex-shrink-0">
                    <XCircleIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                        {errors.length > 1
                            ? `There were ${errors.length} errors with your submission`
                            : 'There is an error with your submission'}
                    </h3>
                    <div className="mt-2 text-sm text-red-700">
                        <ul className="list-disc pl-5 space-y-1">
                            {errors.map((error) => (
                                <li key={error}>{error}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
