import React from 'react';
import { LoadingSpinner } from '../../components';
import { usePropertyManagerData } from '../../foundation';
import { useGetLandlordSubscription } from './useGetLandlordSubscription';

export function AccountSettings() {
    const propertyManagerData = usePropertyManagerData();

    const { email } = propertyManagerData.userInfo || {};

    // const { fields } = useForm({
    //     fields: {
    //         email: useField(email),
    //         password: useField(''),
    //     },
    // });

    const { planName, subscriptionActive, portalUrl, loading } =
        useGetLandlordSubscription();

    const Subscription = () => {
        return (
            <>
                {planName && subscriptionActive ? (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-300 text-orange-800">
                        {planName}
                    </span>
                ) : (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-300 text-red-800">
                        NOT ACTIVE
                    </span>
                )}
            </>
        );
    };

    return (
        <div className="mt-10 divide-y divide-gray-200">
            <div className="space-y-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Account
                </h3>
                <p className="max-w-2xl text-sm text-gray-500">
                    Some information here is used to login to your account.
                </p>
            </div>
            <div className="mt-6">
                <dl className="divide-y divide-gray-200">
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                        <dt className="text-sm font-medium text-gray-500">
                            Email
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span className="flex-grow">{email}</span>
                            {/* <span className="ml-4 flex-shrink-0">
                                <button
                                    type="button"
                                    className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                >
                                    Update
                                </button>
                            </span> */}
                        </dd>
                    </div>
                    {/* <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                        <dt className="text-sm font-medium text-gray-500">
                            Password
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span className="flex-grow">**********</span>
                            <span className="ml-4 flex-shrink-0">
                                <button
                                    type="button"
                                    className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                >
                                    Update
                                </button>
                            </span>
                        </dd>
                    </div> */}
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                        <dt className="text-sm font-medium text-gray-500">
                            Plan
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span className="flex-grow">
                                {loading ? (
                                    <LoadingSpinner
                                        size="small"
                                        fullSize={false}
                                        pathColor="blue"
                                        circleColor="blue"
                                    />
                                ) : (
                                    <Subscription />
                                )}
                            </span>
                            {loading ? (
                                <LoadingSpinner
                                    size="small"
                                    fullSize={false}
                                    pathColor="blue"
                                    circleColor="blue"
                                />
                            ) : (
                                <span className="ml-4 flex-shrink-0">
                                    <a
                                        href={portalUrl || ''}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                    >
                                        Update
                                    </a>
                                </span>
                            )}
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    );
}
