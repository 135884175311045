import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Tenants } from './components';
import { TenantDetail } from './components/Tenants';
import { usePropertyManagerData } from '../../foundation';
import { useGetPropertiesQuery } from '../../generated/graphql';

export function UserRoutes() {
    const propertyManagerData = usePropertyManagerData();
    const { data } = useGetPropertiesQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            landlordId: propertyManagerData.userInfo?.id || '',
        },
    });

    return (
        <Routes>
            <Route
                path={'/tenants'}
                element={
                    <Tenants
                        properties={data?.GetProperties?.properties || []}
                    />
                }
            />
            <Route path={'/tenants/:id'} element={<TenantDetail />} />
        </Routes>
    );
}
