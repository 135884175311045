import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, useField } from '@shopify/react-form';
import {
    Comments,
    LoadingSpinner,
    NotFound,
    Page,
    TimeLine,
} from '../../../../components';
import { FormValues } from './types';
import { usePropertyManagerData } from '../../../../foundation';
import {
    useGetPropertyQueryQuery,
    useUpdatePropertyMutationMutation,
} from '../../../../generated/graphql';
import { PropertyDetailCard, UnitsCard } from './components';
import { extractPropertyDetailFields } from './extractor';
export function PropertyDetail() {
    const propertyManagerData = usePropertyManagerData();
    const [editMode, setEditMode] = useState(false);
    const [updateProperty] = useUpdatePropertyMutationMutation();
    const { id } = useParams();
    const { data, loading } = useGetPropertyQueryQuery({
        variables: {
            id: id!,
            landlordId: propertyManagerData.userInfo?.id!,
        },
    });

    const {
        name,
        address,
        type: propertyType,
        forRent,
        price,
        about,
        timeline,
        units,
    } = extractPropertyDetailFields(data);

    const propertyDetailFields = {
        nameField: useField(name),
        addressField: useField(address),
        propertyTypeField: useField(propertyType),
        forRentField: useField(forRent),
        priceField: useField(price),
        aboutField: useField(about),
    };

    const {
        fields: {
            nameField,
            addressField,
            propertyTypeField,
            forRentField,
            priceField,
            aboutField,
        },
        submit,
        submitting,
    } = useForm({
        fields: {
            ...propertyDetailFields,
        },
        // @ts-ignore
        onSubmit: async (fieldValues: FormValues) => {
            const propertyId = id!;
            const {
                addressField,
                priceField,
                nameField,
                propertyTypeField,
                forRentField,
            } = fieldValues;
            const result = await updateProperty({
                variables: {
                    propertyInput: {
                        id: propertyId,
                        name: nameField,
                        landlordId: propertyManagerData.userInfo?.id!,
                        address: addressField,
                        price: parseFloat(priceField),
                        type: propertyTypeField,
                        forRent: forRentField,
                    },
                },
                refetchQueries: ['GetPropertyQuery'],
                awaitRefetchQueries: true,
            });

            if (!result.errors || result.errors.length === 0) {
                setEditMode(false);
            }
            return { status: 'success' };
        },
    });

    const extractedUnits =
        units?.map((unit) => {
            return { ...unit };
        }) || [];

    if (loading) {
        return <LoadingSpinner />;
    }

    if (!data) {
        return <NotFound />;
    }

    return (
        <Page title={name}>
            <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                    <PropertyDetailCard
                        name={nameField}
                        address={addressField}
                        type={propertyTypeField}
                        renting={forRentField}
                        price={priceField}
                        about={aboutField}
                        units={extractedUnits}
                        onSave={submit}
                        submitting={submitting}
                        editMode={editMode}
                        id={id}
                        setEditMode={setEditMode}
                        pictures={data.GetProperty?.pictures}
                    />
                    <UnitsCard propertyUnits={extractedUnits} />
                    <Comments
                        landlordId={propertyManagerData.userInfo?.id!}
                        propertyId={id!}
                    />
                </div>
                {timeline.length > 0 && <TimeLine timeline={timeline} />}
            </div>
        </Page>
    );
}
