import React, { createContext, useContext, useMemo } from 'react';
import { Field, useField, useForm } from '@shopify/react-form';
import { FileToUpload, useFileUploader } from '../FileUploader/FileUploader';
import { useUpdateLandlordBrandingMutation } from '../../generated/graphql';
import { usePropertyManagerData } from '../DataProvider';
export interface ThemeEditorData {
    branding: {
        logo: Field<FileToUpload | null>;
        colors: {
            heading: Field<string>;
            background: Field<string>;
            primaryText: Field<string>;
            secondaryText: Field<string>;
            accent: Field<string>;
            footer: Field<string>;
        };
    };
    publishedBrandingData: BrandingData;
    dirty: boolean;
    publishBranding(): void;
}

export interface BrandingData {
    branding: {
        logo: { value: string };
        colors: {
            heading: { value: string };
            background: { value: string };
            primaryText: { value: string };
            secondaryText: { value: string };
            accent: { value: string };
            footer: { value: string };
        };
    };
}
const ThemeEditorContext = createContext<ThemeEditorData | null>(null);
export const useEditorContext = (skip?: boolean) => {
    if (ThemeEditorContext === undefined && !skip) {
        throw new Error(
            'You must wrap your app in a theme editor context for useEditorContext'
        );
    }

    return useContext(ThemeEditorContext);
};

export const ThemeEditorProvider = ({ children }: any) => {
    const fileUploader = useFileUploader();
    const propertyManagerData = usePropertyManagerData();

    const brandingData = useMemo(() => {
        const branding = propertyManagerData.userInfo?.branding;

        return {
            branding: {
                logo: {
                    value: branding?.logo || '',
                },
                colors: {
                    heading: { value: branding?.colors.heading || '#FFFFFF' },
                    background: {
                        value: branding?.colors.background || '#FFFFFF',
                    },
                    primaryText: {
                        value: branding?.colors.primaryText || '#000000',
                    },
                    secondaryText: {
                        value: branding?.colors.secondaryText || '#808080',
                    },
                    accent: { value: branding?.colors.accent || '#4B0082' },
                    footer: { value: branding?.colors.footer || '#FFFFFF' },
                },
            },
        };
    }, [propertyManagerData.userInfo?.branding]);

    const [landlordBrandingMutation] = useUpdateLandlordBrandingMutation();
    const { fields, dirty, submit } = useForm({
        fields: {
            logo: useField<FileToUpload | null>(null),
            colors: {
                heading: useField(brandingData.branding.colors.heading.value),
                background: useField(
                    brandingData.branding.colors.background.value
                ),
                primaryText: useField(
                    brandingData.branding.colors.primaryText.value
                ),
                secondaryText: useField(
                    brandingData.branding.colors.secondaryText.value
                ),
                accent: useField(brandingData.branding.colors.accent.value),
                footer: useField(brandingData.branding.colors.footer.value),
            },
        },
        onSubmit: async ({ logo, colors }) => {
            const logoDownloadUrl =
                logo && typeof logo !== 'string'
                    ? await fileUploader.uploadFile(logo)
                    : brandingData.branding.logo.value;

            await landlordBrandingMutation({
                variables: {
                    branding: {
                        logo: logoDownloadUrl,
                        colors,
                    },
                },
            });

            return { status: 'success' };
        },
    });
    return (
        <ThemeEditorContext.Provider
            value={{
                branding: fields,
                dirty,
                publishBranding: () => submit(),
                publishedBrandingData: brandingData,
            }}
        >
            {children}
        </ThemeEditorContext.Provider>
    );
};
