import React from 'react';
import { Field } from '@shopify/react-form';
import { FileToUpload } from '../../foundation/FileUploader/FileUploader';
import { usePropertyManagerData } from '../../foundation';
import { BrandingData } from '../../foundation/EditorContext/EditorContext';

export function LogoUpload({
    file,
    publishedBrandingData,
}: {
    file: Field<FileToUpload | null>;
    publishedBrandingData: BrandingData;
}) {
    const propertyManagerData = usePropertyManagerData();
    const handleFileUploadChange = (e: any) => {
        const files = e.target.files;
        file.onChange({
            file: files[0],
            pathToUpload: `landlords/${propertyManagerData.userInfo?.id}/branding/logo/${files[0].name}`,
        });
    };

    return (
        <div>
            <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center w-full max-w-lg p-5 mx-auto mt-2 text-center bg-white border-2 border-gray-300 border-dashed cursor-pointer dark:bg-gray-900 dark:border-gray-700 rounded-xl"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-8 h-8 text-gray-500 dark:text-gray-400"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                    />
                </svg>
                <h2 className="mt-1 font-medium tracking-wide text-gray-700 dark:text-gray-200">
                    Click to upload or drag and drop
                </h2>
                <p className="mt-2 text-xs tracking-wide text-gray-500 dark:text-gray-400 mb-3">
                    SVG, PNG, JPG or GIF (MAX. 512x512px)
                </p>
                <input
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    accept="image/*"
                    onChange={(e) => handleFileUploadChange(e)}
                    multiple={false}
                />
                {file.value ? (
                    <img
                        src={URL.createObjectURL(file.value.file)}
                        alt={`${propertyManagerData.userInfo?.firstName} Logo`}
                    />
                ) : null}
                {publishedBrandingData.branding.logo.value && !file.value ? (
                    <img
                        src={publishedBrandingData.branding.logo.value}
                        alt={`${propertyManagerData.userInfo?.firstName} Logo`}
                    />
                ) : null}
            </label>
        </div>
    );
}
