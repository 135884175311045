import React from 'react';
import { Field } from '@shopify/react-form';

export function ColorPicker({ color }: { color: Field<string> }) {
    return (
        <>
            <input
                type="color"
                className="p-1 h-14 w-14 block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700"
                id="hs-color-input"
                value={color.value}
                onChange={(event) => color.onChange(event.target.value)}
                title="Choose your color"
            />
        </>
    );
}
