import {
    connectStorageEmulator,
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from 'firebase/storage';
import { useCallback } from 'react';
import { isDevelopmentEnvironment } from '../../environment';

export interface FileToUpload {
    file: File;
    pathToUpload: string;
}

export function useFileUploader() {
    const storage = getStorage();
    if (isDevelopmentEnvironment) {
        connectStorageEmulator(storage, 'localhost', 7777);
    }

    const uploadFile = useCallback(
        (fileToUpload: FileToUpload): Promise<string> => {
            const imgRef = ref(storage, fileToUpload.pathToUpload);
            const uploadTask = uploadBytesResumable(imgRef, fileToUpload.file);
            return new Promise((resolve, reject) => {
                uploadTask.on(
                    'state_changed',
                    (_) => {},
                    (err) => reject(err),
                    async () => {
                        const downloadUrl = await getDownloadURL(
                            uploadTask.snapshot.ref
                        );
                        resolve(downloadUrl);
                    }
                );
            });
        },
        [storage]
    );

    const uploadFiles = useCallback(
        (files: FileToUpload[]) => {
            return Promise.all(files.map((file) => uploadFile(file)));
        },
        [uploadFile]
    );

    return {
        uploadFiles,
        uploadFile,
    };
}
