import { useField, useForm } from '@shopify/react-form';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    useGetOccupantQuery,
    useUpdateOccupantMutation,
} from '../../../../../../generated/graphql';
import { usePropertyManagerData } from '../../../../../../foundation';
import {
    LoadingSpinner,
    NotFound,
    Page,
    TimeLine,
} from '../../../../../../components';
import { TenantDetailCard } from './components/TenantDetailCard';

export function TenantDetail() {
    const propertyManagerData = usePropertyManagerData();
    const { id } = useParams();
    const [editMode, setEditMode] = useState(false);
    const { data, loading } = useGetOccupantQuery({
        variables: {
            id: id!,
            landlordId: propertyManagerData.userInfo?.id!,
        },
        skip: !Boolean(id) || !Boolean(propertyManagerData.userInfo?.id),
    });

    const [updateOccupantMutation] = useUpdateOccupantMutation();

    const {
        fields: { firstName, lastName, email },
        submit,
        submitting,
    } = useForm({
        fields: {
            firstName: useField(data?.GetOccupant?.firstName || ''),
            lastName: useField(data?.GetOccupant?.lastName || ''),
            email: useField(data?.GetOccupant?.email || ''),
        },
        onSubmit: async (fieldValues) => {
            const result = await updateOccupantMutation({
                variables: {
                    occupantInput: {
                        ...fieldValues,
                        landlordId: propertyManagerData.userInfo!.id!,
                        id,
                    },
                },
                refetchQueries: ['GetOccupant'],
            });

            if (!result.errors || result.errors.length === 0) {
                setEditMode(false);
            }

            return { status: 'success' };
        },
    });

    if (loading) {
        return <LoadingSpinner />;
    }

    if (!data) {
        return <NotFound />;
    }

    return (
        <Page title={`${firstName.value} ${lastName.value}`}>
            <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                    <TenantDetailCard
                        firstName={firstName}
                        lastName={lastName}
                        email={email}
                        editMode={editMode}
                        setEditMode={setEditMode}
                        onSave={submit}
                        submitting={submitting}
                        unitNumber={data.GetOccupant?.unitNumber}
                        unitPrice={data.GetOccupant?.unitPrice}
                        id={data?.GetOccupant?.id}
                    />
                </div>
                <TimeLine timeline={data?.GetOccupant?.timeline || []} />
            </div>
        </Page>
    );
}
