import React from 'react';
import { LoadingSpinner, NotFound, Page } from '../../components';
import { useGetContactsQuery } from '../../generated/graphql';
import { ContactsTable } from './ContactsTable';

export function Contacts() {
    const { data, loading } = useGetContactsQuery();

    if (loading) {
        return <LoadingSpinner />;
    }

    if (!data) {
        return <NotFound />;
    }

    return (
        <Page title="Applicants">
            <ContactsTable contacts={data.GetContacts} />
        </Page>
    );
}
