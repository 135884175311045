import React from 'react';
import { LogoUpload } from './LogoUpload';
import { ColorPicker } from './ColorPicker';
import { useEditorContext } from '../../foundation/EditorContext/EditorContext';

export function EditorPanel() {
    const editorContext = useEditorContext();

    if (!editorContext) {
        return null;
    }

    return (
        <section
            aria-labelledby="timeline-title"
            className="lg:col-start-3 lg:col-span-1"
        >
            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                <h1
                    id="timeline-title"
                    className="text-2xl font-bold text-gray-900"
                >
                    Editor
                </h1>
                <div className="mt-6 flow-root">
                    <ul className="mb-8">
                        <p className="mb-3 text-xl font-medium">Logo</p>
                        <li>
                            <LogoUpload
                                file={editorContext.branding.logo}
                                publishedBrandingData={
                                    editorContext.publishedBrandingData
                                }
                            />
                        </li>
                    </ul>
                    <ul className="mb-8">
                        <p className="mb-3 text-xl font-medium">Colors</p>
                        <li className="mb-3">
                            <div className="flex gap-x-2">
                                <ColorPicker
                                    color={
                                        editorContext.branding.colors.heading
                                    }
                                />
                                <p className="text-base self-center">
                                    Heading color
                                </p>
                            </div>
                        </li>
                        <li className="mb-3">
                            <div className="flex gap-x-2">
                                <ColorPicker
                                    color={
                                        editorContext.branding.colors.background
                                    }
                                />
                                <p className="text-base self-center">
                                    Background color
                                </p>
                            </div>
                        </li>
                        <li className="mb-3">
                            <div className="flex gap-x-2">
                                <ColorPicker
                                    color={
                                        editorContext.branding.colors
                                            .primaryText
                                    }
                                />
                                <p className="text-base self-center">
                                    Primary text color
                                </p>
                            </div>
                        </li>
                        <li className="mb-3">
                            <div className="flex gap-x-2">
                                <ColorPicker
                                    color={
                                        editorContext.branding.colors
                                            .secondaryText
                                    }
                                />
                                <p className="text-base self-center">
                                    Secondary text color
                                </p>
                            </div>
                        </li>
                        <li className="mb-3">
                            <div className="flex gap-x-2">
                                <ColorPicker
                                    color={editorContext.branding.colors.accent}
                                />
                                <p className="text-base self-center">
                                    Accent color
                                </p>
                            </div>
                        </li>
                        <li className="mb-3">
                            <div className="flex gap-x-2">
                                <ColorPicker
                                    color={editorContext.branding.colors.footer}
                                />
                                <p className="text-base self-center">
                                    Footer color
                                </p>
                            </div>
                        </li>
                    </ul>
                    <ul className="mb-8">
                        <li>
                            <button
                                type="button"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-75"
                                onClick={editorContext.publishBranding}
                            >
                                Publish
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
}
