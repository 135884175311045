import { useState } from 'react';
import * as ls from 'local-storage';
import { identityServer } from '../environment';
import { UserTypes } from '../sections/auth/Authenticate';
export interface AuthenticatedUser {
    email?: string;
    uid: string;
}

interface AuthenticationResult {
    token: string;
    // eslint-disable-next-line camelcase
    refresh_token: string;
    // eslint-disable-next-line camelcase
    token_expiration_time: string;
    uid: string;
    email: string;
    status: string;
}

export function useAuthProvider() {
    const [user, setUser] = useState<AuthenticatedUser | null>(null);
    const signIn = async (
        email: string,
        password: string,
        loadingCallback: (value: boolean) => void
    ) => {
        const result = await fetch(
            `${identityServer}/authenticate/login?email=${email}&password=${password}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );

        const authenticationResult = await result.json();

        loadingCallback(false);

        if (authenticationResult.status === 'FAILURE') {
            console.log(authenticationResult);
            return {
                error: authenticationResult.message as string,
                user: undefined,
            };
        } else {
            const user = {
                email: authenticationResult.email,
                uid: authenticationResult.uid,
            } as AuthenticatedUser;

            storeAuthenticationResult(authenticationResult);
            setUser(user);

            return { user, error: undefined };
        }
    };

    const signUp = async (
        email: string,
        password: string,
        firstName: string,
        lastName: string,
        preferredCurrency: string,
        userType: UserTypes,
        loadingCallback: (value: boolean) => void
    ) => {
        const result = await fetch(
            `${identityServer}/authenticate/signup?email=${email}&password=${password}&firstName=${firstName}&lastName=${lastName}&preferredCurrency=${preferredCurrency}&userType=${userType}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );
        const authenticationResult = await result.json();
        loadingCallback(false);

        if (authenticationResult.status === 'FAILURE') {
            return {
                error: authenticationResult.message as string,
                user: undefined,
            };
        } else {
            const user = {
                email: authenticationResult.email,
                uid: authenticationResult.uid,
            } as AuthenticatedUser;

            storeAuthenticationResult(authenticationResult);
            setUser(user);

            return { user, error: undefined };
        }
    };
    const resetPassword = async (email: string) => {
        const result = await fetch(
            `${identityServer}/authenticate/resetPassword?email=${email}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );
        const passwordResetResult = await result.json();
        return passwordResetResult;
    };

    const sendTenantCreateAccountEmail = async (
        email: string,
        landlordId: string,
        firstName: string,
        lastName: string
    ) => {
        const result = await fetch(
            `${identityServer}/email/createTenantAccount?email=${email}&landlordId=${landlordId}&firstName=${firstName}&lastName=${lastName}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );
        const emailJobResult = await result.json();
        return emailJobResult;
    };

    const getAuthorizationToken = () => {
        const refreshToken = ls.get<string>('refresh_token');
        const authorizationToken = ls.get<string>('authorization_token');
        const tokenExpirationTime = ls.get<string>('token_expiration_time');
        const uid = ls.get<string>('uid');

        if (
            !authorizationToken ||
            !refreshToken ||
            !tokenExpirationTime ||
            !uid
        ) {
            return;
        }

        if (Date.now() < Date.parse(tokenExpirationTime)) {
            // Make sure current date of token is less than expiry date

            return authorizationToken;
        }
    };

    const fetchAuthorizationToken = async () => {
        const refreshToken = ls.get<string>('refresh_token');

        const result = await fetch(
            `${identityServer}/authenticate/refresh?refresh_token=${refreshToken}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );

        const authenticationResult = await result.json();

        return storeAuthenticationResult(authenticationResult);
    };

    const storeAuthenticationResult = (
        authenticationResult: AuthenticationResult
    ) => {
        if (authenticationResult.status === 'AUTHENTICATED') {
            ls.set<string>('authorization_token', authenticationResult.token);
            ls.set<string>(
                'token_expiration_time',
                authenticationResult.token_expiration_time
            );
            ls.set<string>('refresh_token', authenticationResult.refresh_token);
            ls.set<string>('uid', authenticationResult.uid);

            setUser({
                email: authenticationResult.email,
                uid: authenticationResult.uid,
            });

            return authenticationResult.token;
        } else {
            ls.clear();
            window.location.reload();
        }
    };

    const signOut = () => {
        ls.clear();
        setUser(null);
    };

    return {
        user,
        signIn,
        signUp,
        resetPassword,
        sendTenantCreateAccountEmail,
        signOut,
        getAuthorizationToken,
        fetchAuthorizationToken,
        setUser: setUser,
    };
}
