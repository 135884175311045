import React, { useMemo, useState } from 'react';
import { TenantNew, TenantsTable } from './components';
import {
    useGetTenantsQuery,
    useRemoveTenantMutation,
} from '../../../../generated/graphql';
import { usePropertyManagerData } from '../../../../foundation';
import { LoadingSpinner, NotFound, Page } from '../../../../components';

export interface TenantsTransformedProperty {
    __typename?: 'Property';
    name: string;
    id: string;
    address: string;
    type: string;
    price: number;
    forRent: boolean;
    landlordId: string;
}
interface Props {
    properties?: TenantsTransformedProperty[];
}

export function Tenants({ properties }: Props) {
    const [createNewTenant, setCreateNewTenant] = useState(false);
    const propertyManagerData = usePropertyManagerData();
    const [removeTenantMutate] = useRemoveTenantMutation();

    const { data, loading, refetch } = useGetTenantsQuery({
        variables: {
            landlordId: propertyManagerData.userInfo?.id || '',
        },
    });

    const tenants = data?.GetOccupantsForLandlord;
    const removeTenant = async (id: string) => {
        let landlordID: string = propertyManagerData.userInfo?.id as string;
        await removeTenantMutate({
            variables: {
                id: id,
                landlordId: landlordID,
            },
        });
        refetch();
    };
    const tenantsRenting = useMemo(() => {
        return tenants?.filter((tenant) =>
            properties?.some((property) => property.id === tenant?.propertyId)
        );
    }, [tenants, properties]);

    const source = useMemo(() => {
        return tenantsRenting?.map((tenant) => {
            return {
                ...tenant,
                name: `${tenant?.firstName} ${tenant?.lastName}`,
            };
        });
    }, [tenantsRenting]);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (!data || !propertyManagerData.userInfo?.id) {
        return <NotFound />;
    }

    return (
        <>
            <Page
                title="Tenants"
                action={{
                    title: 'Add new tenant',
                    onClick: () => setCreateNewTenant(true),
                }}
            >
                <TenantsTable removeTenant={removeTenant} occupants={source} />
            </Page>
            <TenantNew
                onClose={() => {
                    setCreateNewTenant(false);
                    refetch();
                }}
                open={createNewTenant}
                properties={properties}
            />
        </>
    );
}
