import React from 'react';
import { ChevronRightIcon, MailIcon } from '@heroicons/react/solid';
import { LoadingSpinner, NotFound, Page } from '../../components';
import { useGetLandlordsQuery } from '../../generated/graphql';
import { usePropertyManagerData } from '../../foundation';
import { UserNameBadge } from '../../components/UserNameBadge';
import { Link } from 'react-router-dom';

export function Rentals() {
    const propertyManagerData = usePropertyManagerData();

    const { data, loading } = useGetLandlordsQuery({
        variables: {
            uids: propertyManagerData.userInfo?.landlords || [],
        },
    });

    if (loading) {
        return <LoadingSpinner />;
    }

    if (!data) {
        return <NotFound />;
    }

    return (
        <Page title="Rentals">
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
                <ul className="divide-y divide-gray-200">
                    {data.GetLandlords.map((landlord) => (
                        <li key={landlord?.id}>
                            <Link
                                to={`/rentals/${landlord.id}`}
                                className="block hover:bg-gray-50"
                            >
                                <div className="flex items-center px-4 py-4 sm:px-6">
                                    <div className="min-w-0 flex-1 flex items-center">
                                        <div className="flex-shrink-0">
                                            <UserNameBadge
                                                firstName={landlord?.firstName?.charAt(
                                                    0
                                                )}
                                                lastName={landlord?.lastName?.charAt(
                                                    0
                                                )}
                                            />
                                        </div>
                                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                            <div>
                                                <p className="text-sm font-medium text-indigo-600 truncate">
                                                    {landlord?.firstName}{' '}
                                                    {landlord?.lastName}
                                                </p>
                                                <p className="mt-2 flex items-center text-sm text-gray-500">
                                                    <MailIcon
                                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                    <span className="truncate">
                                                        {landlord.email}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ChevronRightIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </Page>
    );
}
