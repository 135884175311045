import { Field } from '@shopify/react-form';
import React from 'react';

export interface NewUnit {
    unitNumber: string;
    unitPrice: string;
    rooms: string;
    baths: string;
}

interface Props {
    unitNumber: Field<string>;
    unitPrice: Field<string>;
    rooms: Field<string>;
    baths: Field<string>;
}

export function EditUnitsFields({
    unitNumber,
    unitPrice,
    rooms,
    baths,
}: Props) {
    return (
        <>
            <div className="mb-5">
                <label htmlFor="unit_number">Unit number</label>
                <input
                    type="number"
                    name="unit_number"
                    id="unit_number"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Unit #"
                    value={unitNumber.value}
                    onChange={unitNumber.onChange}
                />
            </div>
            <div className="mb-5">
                <label htmlFor="rent-price">Rent price</label>
                <input
                    type="number"
                    name="rent-price"
                    id="rent-price"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Price"
                    value={unitPrice.value}
                    onChange={unitPrice.onChange}
                />
            </div>
            <div className="mb-5">
                <label htmlFor="num_rooms">Rooms</label>
                <input
                    type="number"
                    name="num_rooms"
                    id="num_rooms"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Rooms"
                    value={rooms.value}
                    onChange={rooms.onChange}
                />
            </div>
            <div className="mb-5">
                <label htmlFor="num_baths">Baths</label>
                <input
                    type="number"
                    name="num_baths"
                    id="num_baths"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Baths"
                    value={baths.value}
                    onChange={baths.onChange}
                />
            </div>
        </>
    );
}
