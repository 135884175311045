import React from 'react';

interface Props {
    firstName?: string | null;
    lastName?: string | null;
}
export function UserNameBadge({ firstName, lastName }: Props) {
    if (!firstName || !lastName) {
        return null;
    }
    return (
        <div
            className="h-10 w-10 rounded-full bg-red-500 border-2 border-indigo-600 hover:bg-red-700"
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <p className="text-lg text-white">{`${firstName}${lastName}`}</p>
        </div>
    );
}
