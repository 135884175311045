import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Property } from '../CondensedPropertyTable/CondensedPropertyTable';
import { DocumentList, ImageGallery } from '../../../../../../components';

interface Props {
    open: boolean;
    propertyData: Property | null;
    onClose(): void;
}

export function PropertyDetailSlideOver({
    open,
    onClose,
    propertyData,
}: Props) {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                        <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-lg font-medium text-white">
                                                    {propertyData?.address}
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                        onClick={() =>
                                                            onClose()
                                                        }
                                                    >
                                                        <span className="sr-only">
                                                            Close property
                                                            detail
                                                        </span>
                                                        <XIcon
                                                            className="h-6 w-6"
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mt-1">
                                                <p className="text-sm text-indigo-300">
                                                    {propertyData?.about}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="relative flex-1 py-6 px-4 sm:px-6">
                                            <section>
                                                <h1 className="text-xl font-bold">
                                                    Documents
                                                </h1>
                                                <div className="mt-5 pb-5">
                                                    <DocumentList />
                                                </div>
                                            </section>
                                            <section>
                                                <h1 className="text-xl font-bold">
                                                    Images
                                                </h1>
                                                <div className="mt-5 pb-5">
                                                    <ImageGallery />
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
