import React from 'react';

export interface Property {
    address: string;
    price: number;
    about: string;
}

interface Props {
    properties: Property[];
    landlordName: string;
    onViewProperty(property: Property | null): void;
    landlordPrefferedCurrency?: string | null;
}

export function CondensedPropertyTable({
    properties,
    landlordName,
    landlordPrefferedCurrency,
}: Props) {
    return (
        <div className="mt-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">
                        Properties
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        {`A list of all the properties you are renting from ${landlordName}`}
                    </p>
                </div>
            </div>
            <div className="mt-5 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                        >
                                            Address
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Rent price
                                        </th>
                                        {/*<th*/}
                                        {/*    scope="col"*/}
                                        {/*    className="relative py-3.5 pl-3 pr-4 sm:pr-6"*/}
                                        {/*>*/}
                                        {/*    <span className="sr-only">*/}
                                        {/*        View*/}
                                        {/*    </span>*/}
                                        {/*</th>*/}
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {properties.map((property) => (
                                        <tr key={property.address}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {property.address}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">
                                                    {`${property.price.toString()} ${landlordPrefferedCurrency}`}
                                                </span>
                                            </td>
                                            {/*<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">*/}
                                            {/*    <button*/}
                                            {/*        onClick={() =>*/}
                                            {/*            onViewProperty(property)*/}
                                            {/*        }*/}
                                            {/*        className="text-indigo-600 hover:text-indigo-900"*/}
                                            {/*    >*/}
                                            {/*        View*/}
                                            {/*        <span className="sr-only">*/}
                                            {/*            {property.address}*/}
                                            {/*        </span>*/}
                                            {/*    </button>*/}
                                            {/*</td>*/}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
