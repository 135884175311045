import React from 'react';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

interface Props {
    images?: {
        source: string;
        current: boolean;
        size?: string;
        name?: string;
    }[];
}

export function ImageGallery({
    images = [
        {
            name: 'IMG_4985.HEIC',
            size: '3.9 MB',
            source: 'https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80',
            current: true,
        },
    ],
}: Props) {
    return (
        <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
            {images.map((file) => (
                <li key={file.name} className="relative">
                    <div
                        className={classNames(
                            file.current
                                ? 'ring-2 ring-offset-2 ring-indigo-500'
                                : 'focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500',
                            'group rounded-lg bg-gray-100 overflow-hidden'
                        )}
                    >
                        <img
                            src={file.source}
                            alt={file.name}
                            className={classNames(
                                file.current ? '' : 'group-hover:opacity-75',
                                'object-cover pointer-events-none',
                                'w-30',
                                'h-40',
                                'object-cover'
                            )}
                        />
                        <button
                            type="button"
                            className="absolute inset-0 focus:outline-none"
                        >
                            <span className="sr-only">
                                View details for {file.name}
                            </span>
                        </button>
                    </div>
                    <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
                        {file.name}
                    </p>
                    <p className="block text-sm font-medium text-gray-500 pointer-events-none">
                        {file.size}
                    </p>
                </li>
            ))}
        </ul>
    );
}
