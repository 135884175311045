import React from 'react';
import { useField, useForm } from '@shopify/react-form';
import {
    useAddCommentMutation,
    useGetCommentsQuery,
} from '../../generated/graphql';
import { usePropertyManagerData } from '../../foundation';
import { UserNameBadge } from '../UserNameBadge';

interface Props {
    propertyId: string;
    landlordId: string;
}
export function Comments({ propertyId, landlordId }: Props) {
    const propertyManagerData = usePropertyManagerData();

    const { data } = useGetCommentsQuery({
        variables: {
            propertyId,
            landlordId,
        },
    });

    const [addComment] = useAddCommentMutation();

    const {
        fields: { comment },
        submit,
    } = useForm({
        fields: {
            comment: useField(''),
        },
        onSubmit: async (fieldValues) => {
            await addComment({
                variables: {
                    comment: {
                        comment: fieldValues.comment,
                        userName: `${propertyManagerData.userInfo?.firstName} ${propertyManagerData.userInfo?.lastName}`,
                        dateTime: new Date().toUTCString(),
                    },
                    landlordId,
                    propertyId,
                },
                refetchQueries: ['GetComments'],
                awaitRefetchQueries: true,
            });
            comment.reset();
            return { status: 'success' };
        },
    });
    return (
        <section aria-labelledby="comments-title">
            <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                <div className="divide-y divide-gray-200">
                    <div className="px-4 py-5 sm:px-6">
                        <h2
                            id="comments-title"
                            className="text-lg font-medium text-gray-900"
                        >
                            Comments
                        </h2>
                    </div>
                    <div className="px-4 py-6 sm:px-6">
                        {data?.GetComments && data.GetComments.length > 0 ? (
                            <ul className="space-y-8">
                                {data?.GetComments?.map((comment) => (
                                    <li key={comment.id}>
                                        <div className="flex space-x-3">
                                            <div className="flex-shrink-0">
                                                <UserNameBadge
                                                    firstName={comment.userName
                                                        .split(' ')[0]
                                                        .charAt(0)}
                                                    lastName={comment.userName
                                                        .split(' ')[1]
                                                        .charAt(0)}
                                                />
                                            </div>
                                            <div>
                                                <div className="text-sm">
                                                    <h3 className="font-medium text-gray-900">
                                                        {comment.userName}
                                                    </h3>
                                                </div>
                                                <div className="mt-1 text-sm text-gray-700">
                                                    <p>{comment.comment}</p>
                                                </div>
                                                <div className="mt-2 text-sm space-x-2">
                                                    <span className="text-gray-500 font-medium">
                                                        {comment.dateTime}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="text-center">
                                <h3 className="mt-2 text-sm font-medium text-gray-900">
                                    No comments
                                </h3>
                                <p className="mt-1 text-sm text-gray-500">
                                    Get started by adding a new comment
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="bg-gray-50 px-4 py-6 sm:px-6">
                    <div className="flex space-x-3">
                        <div className="min-w-0 flex-1">
                            <form onSubmit={submit}>
                                <div>
                                    <label
                                        htmlFor="comment"
                                        className="sr-only"
                                    >
                                        Comment
                                    </label>
                                    <textarea
                                        id="comment"
                                        name="comment"
                                        rows={3}
                                        className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                                        placeholder="Add a comment"
                                        value={comment.value}
                                        onChange={(e) =>
                                            comment.onChange(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="mt-3">
                                    <button
                                        type="submit"
                                        onClick={submit}
                                        disabled={!comment.dirty}
                                        className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-75"
                                    >
                                        Comment
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
