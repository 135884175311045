import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';

interface Props {
    message: string;
    detail?: string;
    dismiss(): void;
}

export function SuccessBanner({ message, detail, dismiss }: Props) {
    return (
        <div
            className="rounded-md bg-green-50 p-4"
            style={{ marginBottom: '25px' }}
        >
            <div className="flex">
                <div className="flex-shrink-0">
                    <CheckCircleIcon
                        className="h-5 w-5 text-green-400"
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-green-800">
                        {message}
                    </h3>
                    {detail ? (
                        <div className="mt-2 text-sm text-green-700">
                            <p>{detail}</p>
                        </div>
                    ) : null}
                    <div className="mt-4">
                        <div className="-mx-2 -my-1.5 flex">
                            <button
                                onClick={dismiss}
                                type="button"
                                className="ml-3 bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                            >
                                Dismiss
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
