import React, { createContext, useContext } from 'react';
import { useDataProvider, UserInfo } from '../../@utilities/useDataProvider';

interface DataProviderReturn {
    userInfo: UserInfo | null;
    setUserInfo: (value: UserInfo | null) => void;
}
const DataContext = createContext<DataProviderReturn>({} as DataProviderReturn);
export const DataProvider = ({ children }: any) => {
    const data = useDataProvider();
    return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};

export const usePropertyManagerData = () => {
    if (DataContext === null) {
        throw new Error('You must wrap your app in a data provider');
    }

    return useContext(DataContext);
};
