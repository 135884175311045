import React, { useState } from 'react';
import { Field } from '@shopify/react-form';
import { PencilIcon, CheckIcon, XIcon } from '@heroicons/react/solid';
import {
    ImageGallery,
    LoadingSpinner,
    SuccessBanner,
    TextField,
} from '../../../../../../components';
import { Switch } from '@headlessui/react';
import { usePropertyManagerData } from '../../../../../../foundation';
import {
    Unit,
    useAddPropertyImageMutation,
} from '../../../../../../generated/graphql';
import { useFileUploader } from '../../../../../../foundation/FileUploader/FileUploader';
import { calculateAvergeRentPriceForUnits } from '../../../../../../foundation/utilities';
interface Props {
    name: Field<string>;
    address: Field<string>;
    type: Field<string>;
    renting: Field<boolean>;
    price: Field<number>;
    about: Field<string>;
    id: any;
    onSave: () => void;
    submitting: boolean;
    editMode: boolean;
    setEditMode: (value: boolean) => void;
    pictures: string[] | null | undefined;
    units: Unit[];
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

export function PropertyDetailCard({
    name,
    address,
    type,
    renting,
    price,
    about,
    id,
    onSave,
    submitting,
    editMode,
    setEditMode,
    pictures,
    units,
}: Props) {
    const propertyTypes = ['House', 'Apartment', 'Condo', 'Studio'];
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const propertyManagerData = usePropertyManagerData();
    const [uploadStatus, setUploadStatus] = useState({
        uploading: false,
        uploadFinished: false,
    });
    const [addPropertyImage] = useAddPropertyImageMutation();

    const fileUploader = useFileUploader();

    const handleTypeChange = (value: string) => {
        type.onChange(value);
    };

    const handleFileSubmit = () => {
        setUploadStatus({ uploading: true, uploadFinished: false });
        async function uploadFiles() {
            if (selectedFile) {
                const downloadPath = await fileUploader.uploadFile({
                    file: selectedFile,
                    pathToUpload: `landlords/${propertyManagerData.userInfo?.id}/properties/${id}/${selectedFile?.name}`,
                });

                await addPropertyImage({
                    variables: {
                        propertyImageInput: {
                            filePath: downloadPath,
                            landlordId: propertyManagerData.userInfo?.id!,
                            propertyId: id,
                        },
                    },
                    refetchQueries: ['GetPropertyQuery'],
                });

                setUploadStatus({ uploading: false, uploadFinished: true });
                setSelectedFile(null);
            }
        }
        uploadFiles();
    };

    const handleFileUploadChange = (e: any) => {
        const files = e.target.files;
        setSelectedFile(files[0]);
    };

    return (
        <section aria-labelledby="property-information-title">
            <div className="bg-white shadow sm:rounded-lg">
                <div className="flex flex-row justify-between">
                    <div className="px-4 py-5 sm:px-6 order-first">
                        <h2
                            id="property-information-title"
                            className="text-lg leading-6 font-medium text-gray-900"
                        >
                            Property Information
                        </h2>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            Additional property details
                        </p>
                    </div>
                    <div className="px-4 py-5 sm:px-6 order-last space-x-2 space-y-2">
                        <button
                            type="button"
                            className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white ${
                                editMode ? 'bg-red-600' : 'bg-blue-600'
                            } ${
                                editMode
                                    ? 'hover:bg-red-700'
                                    : 'hover:bg-blue-700'
                            } focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                                editMode
                                    ? 'focus:ring-red-500'
                                    : 'focus:ring-red-500'
                            }`}
                            onClick={() => setEditMode(!editMode)}
                            disabled={submitting}
                        >
                            {editMode ? (
                                <XIcon
                                    className="-ml-1 mr-2 h-5 w-5"
                                    aria-hidden="true"
                                />
                            ) : (
                                <PencilIcon
                                    className="-ml-1 mr-2 h-5 w-5"
                                    aria-hidden="true"
                                />
                            )}
                            {editMode ? 'Cancel' : 'Edit'}
                        </button>
                        {editMode ? (
                            <button
                                type="submit"
                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                onClick={onSave}
                                disabled={submitting}
                            >
                                <CheckIcon
                                    className="-ml-1 mr-2 h-5 w-5"
                                    aria-hidden="true"
                                />
                                Save
                            </button>
                        ) : null}
                    </div>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    {editMode ? (
                        <form className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div>
                                <TextField
                                    type="email"
                                    name="email"
                                    id="email"
                                    htmlFor="email"
                                    inputMode="email"
                                    autoComplete="email"
                                    label="Email"
                                    field={name}
                                    labelColor="text-gray-500"
                                />
                            </div>
                            <div>
                                <TextField
                                    type="text"
                                    name="address"
                                    id="address"
                                    htmlFor="address"
                                    inputMode="text"
                                    autoComplete="address"
                                    label="Address"
                                    field={address}
                                    labelColor="text-gray-500"
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="property-type"
                                    className="block text-sm font-medium text-gray-500"
                                >
                                    Type
                                </label>
                                <select
                                    id="property-type"
                                    name="property-type"
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    value={type.value}
                                    onChange={(event) =>
                                        handleTypeChange(event.target.value)
                                    }
                                >
                                    {propertyTypes.map((type) => {
                                        return (
                                            <option key={type}>{type}</option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div>
                                <label
                                    htmlFor="renting"
                                    className="block text-sm font-medium text-gray-500"
                                >
                                    Renting
                                </label>
                                <div className="mt-2">
                                    <Switch
                                        checked={renting.value}
                                        onChange={(change) =>
                                            renting.onChange(change)
                                        }
                                        className={classNames(
                                            renting.value
                                                ? 'bg-indigo-600'
                                                : 'bg-gray-200',
                                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                        )}
                                        disabled={false}
                                    >
                                        <span className="sr-only">Renting</span>
                                        <span
                                            aria-hidden="true"
                                            className={classNames(
                                                renting.value
                                                    ? 'translate-x-5'
                                                    : 'translate-x-0',
                                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                            )}
                                        />
                                    </Switch>
                                </div>
                            </div>
                            <div>
                                <TextField
                                    type="number"
                                    name="price"
                                    id="price"
                                    htmlFor="price"
                                    inputMode="numeric"
                                    autoComplete=""
                                    label="Rent price"
                                    field={price}
                                    labelColor="text-gray-500"
                                    currencyField
                                />
                            </div>
                            <div className="sm:col-span-2">
                                <TextField
                                    type="text"
                                    name="about"
                                    id="about"
                                    htmlFor="about"
                                    inputMode="text"
                                    autoComplete=""
                                    label="Additonal information about this property"
                                    field={about}
                                    labelColor="text-gray-500"
                                    textArea
                                />
                            </div>
                        </form>
                    ) : (
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                    Name
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    {name.value}
                                </dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                    Address
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    {address.value}
                                </dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                    Type
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    {type.value}
                                </dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                    Renting
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    {renting.value ? 'Yes' : 'No'}
                                </dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                    {price.value
                                        ? 'Rent Price'
                                        : 'Average rent price'}
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    {`${
                                        price.value ||
                                        calculateAvergeRentPriceForUnits(units)
                                    } ${
                                        propertyManagerData.userInfo
                                            ?.preferredCurrency
                                    }`}
                                </dd>
                            </div>

                            {about.value ? (
                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">
                                        About this property
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {about.value}
                                    </dd>
                                </div>
                            ) : null}

                            <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">
                                    Property Images
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    <ImageGallery
                                        images={
                                            pictures?.map((picture) => {
                                                return {
                                                    source: picture,
                                                    current: false,
                                                };
                                            }) || []
                                        }
                                    />
                                    {uploadStatus.uploadFinished && (
                                        <SuccessBanner
                                            message="Upload complete"
                                            dismiss={() =>
                                                setUploadStatus({
                                                    uploading: false,
                                                    uploadFinished: false,
                                                })
                                            }
                                        />
                                    )}
                                    <div className="w-auto flex flex-row flex-wrap mt-1">
                                        <div className="mr-2">
                                            <input
                                                className="form-control w-full h-10 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                type="file"
                                                accept="image/*"
                                                id="formFileMultiple"
                                                onChange={
                                                    handleFileUploadChange
                                                }
                                                multiple={false}
                                            />
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                className="inline-flex items-center justify-center w-20 h-10 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
                                                onClick={handleFileSubmit}
                                            >
                                                {uploadStatus.uploading ? (
                                                    <LoadingSpinner
                                                        size="small"
                                                        fullSize={false}
                                                        pathColor="white"
                                                        circleColor="white"
                                                    />
                                                ) : (
                                                    <>Upload</>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </dd>
                            </div>
                        </dl>
                    )}
                </div>
            </div>
        </section>
    );
}
