import React from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { EditUnitsFields, Page } from '../../../../../../../components';
import { useField, useForm } from '@shopify/react-form';
import { useEditUnitMutation } from '../../../../../../../generated/graphql';
import { NewUnit } from '../../../../../../../components/EditUnits/EditUnits';
import { usePropertyManagerData } from '../../../../../../../foundation';

interface Props {
    open: boolean;
    onClose(): void;
    unit: NewUnit;
    propertyId: string;
    unitId: string;
}

export function EditUnitsModal({
    open,
    onClose,
    unit,
    propertyId,
    unitId,
}: Props) {
    const [updateUnit] = useEditUnitMutation();
    const propertyManagerData = usePropertyManagerData();

    const {
        fields: { unitNumber, unitPrice, rooms, baths },
        submit,
    } = useForm({
        fields: {
            unitNumber: useField(unit.unitNumber),
            unitPrice: useField(unit.unitPrice),
            rooms: useField(unit.rooms),
            baths: useField(unit.baths),
        },
        onSubmit: async (fieldValues) => {
            await updateUnit({
                variables: {
                    unitInput: {
                        ...fieldValues,
                        propertyId,
                        id: unitId,
                    },
                    landlordId: propertyManagerData.userInfo?.id!,
                },
            });
            onClose();
            return { status: 'success' };
        },
    });

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={onClose}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                                <Page title="Edit Unit">
                                    <div>
                                        <EditUnitsFields
                                            unitNumber={unitNumber}
                                            unitPrice={unitPrice}
                                            rooms={rooms}
                                            baths={baths}
                                        />
                                    </div>
                                </Page>
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                        onClick={submit}
                                    >
                                        Save
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
