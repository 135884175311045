import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PropertyDetail } from '../../sections/properties/components/PropertyDetail/PropertyDetail';
import { UserRoutes } from '../../sections/users';
import {
    EditorPanel,
    Home,
    Listings,
    Properties,
    Rentals,
    Settings,
} from '../../sections';
import { usePropertyManagerData } from '../DataProvider';
import { RentalDetails } from '../../sections/Rentals/components';
import { NotFound } from '../../components';
import { UserTypes } from '../../sections/auth/Authenticate';
import { ThemeEditorProvider } from '../EditorContext/EditorContext';
import { Contacts } from '../../sections/Contacts/Contacts';

export function MainRoutes() {
    const propertyManagerData = usePropertyManagerData();

    return (
        <main className="flex-1">
            <div className="py-6">
                <Routes>
                    {propertyManagerData?.userInfo?.type ===
                        UserTypes.LANDLORD ||
                    propertyManagerData?.userInfo?.type ===
                        UserTypes.REALTOR ? (
                        <>
                            <Route
                                path={'/properties/:id'}
                                element={
                                    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                                        <PropertyDetail />
                                    </div>
                                }
                            />
                            <Route
                                path={'/editor'}
                                element={
                                    <ThemeEditorProvider>
                                        <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8">
                                            <div className="flex gap-5">
                                                <div className="flex-auto">
                                                    <Listings
                                                        editorMode
                                                        passed_landlord_id={
                                                            propertyManagerData
                                                                ?.userInfo.id
                                                        }
                                                    />
                                                </div>
                                                <div className="flex-none w-80">
                                                    <EditorPanel />
                                                </div>
                                            </div>
                                        </div>
                                    </ThemeEditorProvider>
                                }
                            />
                            <Route
                                path={'/contacts'}
                                element={
                                    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                                        <Contacts />
                                    </div>
                                }
                            />
                            <Route
                                path={'/users/*'}
                                element={
                                    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                                        <UserRoutes />
                                    </div>
                                }
                            />
                            <Route
                                path={'/app'}
                                element={
                                    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                                        <Properties />
                                    </div>
                                }
                            />
                            <Route
                                path={'/properties'}
                                element={
                                    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                                        <Properties />
                                    </div>
                                }
                            />
                            <Route
                                path={'/settings'}
                                element={
                                    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                                        <Settings />
                                    </div>
                                }
                            />
                        </>
                    ) : (
                        <>
                            <Route
                                path={'/rentals/:id'}
                                element={
                                    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                                        <RentalDetails />
                                    </div>
                                }
                            />
                            <Route
                                path={'/rentals'}
                                element={
                                    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                                        <Rentals />
                                    </div>
                                }
                            />
                            <Route
                                path={'/app'}
                                element={
                                    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                                        <Rentals />
                                    </div>
                                }
                            />
                        </>
                    )}
                    <Route
                        path={'/dashboard'}
                        element={
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                                <Home />
                            </div>
                        }
                    />
                    <Route
                        path="*"
                        element={
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                                <NotFound />
                            </div>
                        }
                    />
                </Routes>
            </div>
        </main>
    );
}
