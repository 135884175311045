import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Lawn, PropertyRentalChargeSchedule } from './templates/Lawn/Lawn';
import {
    useGetPropertiesQuery,
    useGetUserQuery,
} from '../../generated/graphql';
import { useEditorContext } from '../../foundation/EditorContext/EditorContext';
import { LoadingSpinner } from '../../components';

interface Props {
    passed_landlord_id?: string;
    editorMode?: boolean;
}

const ZICHI_LOGO =
    'https://firebasestorage.googleapis.com/v0/b/property-manager-a677a.appspot.com/o/ZICHI%20LOGO%20WITH%20TEXT.svg?alt=media&token=4fb2def4-641a-4ec2-b6d4-4d5d74275a85';
export function Listings({ passed_landlord_id, editorMode }: Props) {
    const { landlord_id } = useParams();

    const editorContext = useEditorContext(!Boolean(editorMode));

    const { data: queryResult, loading: loadingProperties } =
        useGetPropertiesQuery({
            fetchPolicy: 'cache-and-network',
            variables: {
                landlordId: landlord_id || passed_landlord_id || '',
            },
            skip: !landlord_id && !passed_landlord_id,
            context: {
                headers: {
                    landlordid: landlord_id || passed_landlord_id,
                    skipAuth: true,
                },
            },
        });

    const landlordData = useGetUserQuery({
        variables: {
            uid: landlord_id! || passed_landlord_id!,
        },
        context: {
            headers: {
                landlordid: landlord_id || passed_landlord_id,
                skipAuth: true,
            },
        },
        skip: !landlord_id && !passed_landlord_id,
    });

    const brandingData = useMemo(() => {
        const branding = landlordData.data?.GetUser?.branding;

        return {
            branding: {
                logo: {
                    value: branding?.logo || '',
                },
                colors: {
                    heading: { value: branding?.colors.heading || '#FFFFFF' },
                    background: {
                        value: branding?.colors.background || '#FFFFFF',
                    },
                    primaryText: {
                        value: branding?.colors.primaryText || '#000000',
                    },
                    secondaryText: {
                        value: branding?.colors.secondaryText || '#808080',
                    },
                    accent: { value: branding?.colors.accent || '#4B0082' },
                    footer: { value: branding?.colors.footer || '#FFFFFF' },
                },
            },
        };
    }, [landlordData]);

    const extractedProperties =
        queryResult?.GetProperties?.properties
            ?.map((property) => {
                return {
                    id: property.id,
                    address: property.address,
                    price: property.price.toString(),
                    chargeSchedule: PropertyRentalChargeSchedule.Monthly,
                    propertyImageUrl:
                        property.pictures.length > 0
                            ? property.pictures[0]
                            : '',
                    propertyImageAlt: property.address,
                    description: property.about || '',
                    renting: property.forRent,
                    units:
                        property.units?.map((unit) => {
                            return {
                                id: unit.id,
                                propertyId: unit.propertyId,
                                unitPrice: unit.unitPrice || '',
                            };
                        }) || [],
                };
            })
            .filter((property) => property.renting) || [];

    let logoUrl = ZICHI_LOGO;

    if (editorMode && editorContext?.branding.logo.value?.file) {
        logoUrl = URL.createObjectURL(editorContext.branding.logo.value.file);
    } else if (editorMode && brandingData.branding.logo.value) {
        logoUrl = brandingData.branding.logo.value;
    } else if (!editorMode && brandingData.branding.logo.value) {
        logoUrl = brandingData.branding.logo.value;
    }

    if (loadingProperties || landlordData.loading) {
        return <LoadingSpinner />;
    }

    return (
        <Lawn
            branding={editorMode ? editorContext : brandingData}
            landlordEmail={landlordData.data?.GetUser?.email}
            preferredCurrency={landlordData.data?.GetUser?.preferredCurrency}
            properties={extractedProperties}
            landlordLogoUrl={logoUrl}
        />
    );
}
