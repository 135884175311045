import React from 'react';
import { Link } from 'react-router-dom';
// import { Pagination } from '../../../../components';
import { EmptyPropertiesIcon } from '../../../../components/EmptyState';
import { calculateAvergeRentPriceForProperty } from '../../../../foundation/utilities';
import { usePropertyManagerData } from '../../../../foundation';

export interface TransformedProperty {
    id: string;
    name: string;
    address: string;
    price: number;
    type: string;
    units: { id: string; unitPrice: number | undefined }[];
}

interface Props {
    properties?: TransformedProperty[];
    removeProperty: (id: string) => void;
}
export function PropertiesTable({ properties, removeProperty }: Props) {
    const propertyManagerData = usePropertyManagerData();

    return (
        <>
            {properties?.length === 0 ? (
                <EmptyPropertiesIcon />
            ) : (
                <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Address
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Rent price
                                            </th>
                                            <th
                                                scope="col"
                                                className="relative px-6 py-3"
                                            >
                                                <span className="sr-only">
                                                    Delete
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {properties?.map(
                                            (property, personIdx) => (
                                                <tr
                                                    key={property.id}
                                                    className={
                                                        personIdx % 2 === 0
                                                            ? 'bg-white'
                                                            : 'bg-gray-50'
                                                    }
                                                >
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                        <Link
                                                            to={`/properties/${property.id}`}
                                                            className="text-indigo-600 hover:text-indigo-900"
                                                        >
                                                            {property.name}
                                                        </Link>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {property.address}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">
                                                            {`${calculateAvergeRentPriceForProperty(
                                                                property
                                                            )} ${
                                                                propertyManagerData
                                                                    .userInfo
                                                                    ?.preferredCurrency
                                                            }`}
                                                        </span>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                        <button
                                                            className="font-medium text-red-600 hover:text-red-500"
                                                            onClick={() => {
                                                                removeProperty(
                                                                    property.id
                                                                );
                                                            }}
                                                        >
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                                {/* <Pagination /> */}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
