import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingSpinner, NotFound, Page } from '../../../../components';
import { usePropertyManagerData } from '../../../../foundation';
import {
    GetRentalDetailQuery,
    useGetRentalDetailQuery,
    useGetUserQuery,
} from '../../../../generated/graphql';
import {
    CondensedPropertyTable,
    PropertyDetailSlideOver,
    RentalInformation,
} from './components';
import { Property } from './components/CondensedPropertyTable/CondensedPropertyTable';

export function RentalDetails() {
    const { id: landlordId } = useParams();
    const propertyManagerData = usePropertyManagerData();
    const [seePropertyDetail, setSeePropertyDetail] = useState<{
        open: boolean;
        property: Property | null;
    }>({
        open: false,
        property: null,
    });

    const { data, loading } = useGetRentalDetailQuery({
        variables: {
            landlordId: landlordId || '',
            tenantEmail: propertyManagerData.userInfo?.email || '',
        },
    });

    const landlordData = useGetUserQuery({
        variables: {
            uid: landlordId || '',
        },
        context: {
            headers: {
                landlordid: landlordId,
                skipAuth: true,
            },
        },
        skip: !landlordId,
    });

    if (loading) {
        return <LoadingSpinner />;
    }

    if (!data) {
        return <NotFound />;
    }

    const rentalDetail = extractRentalDetails(data.GetRentalDetail);

    const title = `${rentalDetail.firstName} ${rentalDetail.lastName}`;

    return (
        <Page title={title}>
            <RentalInformation rentalDetail={rentalDetail} />
            <CondensedPropertyTable
                properties={rentalDetail.properties}
                landlordName={title}
                onViewProperty={() => {}}
                landlordPrefferedCurrency={
                    landlordData.data?.GetUser?.preferredCurrency
                }
            />
            <PropertyDetailSlideOver
                open={seePropertyDetail.open}
                onClose={() =>
                    setSeePropertyDetail({ open: false, property: null })
                }
                propertyData={seePropertyDetail.property}
            />
        </Page>
    );
}

function extractRentalDetails(data: GetRentalDetailQuery['GetRentalDetail']) {
    return {
        firstName: data?.landlordInfo.firstName || '',
        lastName: data?.landlordInfo.lastName || '',
        email: data?.landlordInfo.email || '',
        properties:
            data?.properties.map(({ address, price, about }) => {
                return {
                    address,
                    price,
                    about: about || '',
                };
            }) || [],
    };
}
