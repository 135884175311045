import React from 'react';
import './Home.css';
import { usePropertyManagerData } from '../../foundation';
import { TenantHome } from './TenantHome';
import { LandlordHome } from './LandlordHome';

export function Home() {
    const propertyManagerData = usePropertyManagerData();

    return (
        <div className="App">
            <h2>
                Hi 👋🏽, {propertyManagerData.userInfo?.firstName}{' '}
                {propertyManagerData.userInfo?.lastName}
            </h2>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {propertyManagerData?.userInfo?.type === 'LANDLORD' ? (
                <>
                    <h1>Here's a summary of your properties</h1>
                    <LandlordHome />
                </>
            ) : (
                <>
                    <h1>Here's a summary of your rentals</h1>
                    <TenantHome />
                </>
            )}
        </div>
    );
}
