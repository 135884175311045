import { useField, useForm } from '@shopify/react-form';
import React, { useState, Fragment } from 'react';
import { usePropertyManagerData } from '../../foundation';
import { Dialog, Transition } from '@headlessui/react';
import { TextField } from '../../components';
import { useUpdateAccountInformationMutation } from '../../generated/graphql';
import cc from 'currency-codes';

export function ProfileSettings() {
    const propertyManagerData = usePropertyManagerData();

    const { firstName, lastName, id, preferredCurrency } =
        propertyManagerData.userInfo || {};

    const [profileModalOpen, setProfileModalOpen] = useState(false);

    const [updateAccountInformation] = useUpdateAccountInformationMutation();

    const { fields, submit } = useForm({
        fields: {
            firstName: useField(firstName || ''),
            lastName: useField(lastName || ''),
            preferredCurrency: useField(preferredCurrency || ''),
        },
        onSubmit: async (fieldValues) => {
            const result = await updateAccountInformation({
                variables: {
                    updateAccountInformationInput: {
                        firstName: fieldValues.firstName,
                        lastName: fieldValues.lastName,
                        preferredCurrency: fieldValues.preferredCurrency,
                    },
                },
                refetchQueries: ['getUser'],
            });

            if (!result?.data?.UpdateAccountInformation) {
                return {
                    status: 'fail',
                    errors: [
                        {
                            field: ['firstName', 'lastName'],
                            message: 'Failure to update account information',
                        },
                    ],
                };
            }
            setProfileModalOpen(false);
            return {
                status: 'success',
            };
        },
    });

    function ProfileModal({ open, close }: { open: boolean; close(): void }) {
        return (
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={close}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                    <div>
                                        <div className="mt-2 text-left sm:mt-2">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-lg font-medium leading-6 text-gray-900"
                                            >
                                                Update Profile
                                            </Dialog.Title>
                                        </div>
                                        <div className="mt-5">
                                            <TextField
                                                field={fields.firstName}
                                                htmlFor="first-name"
                                                name="first-name"
                                                id="first-name"
                                                type="text"
                                                inputMode="text"
                                                label="First name"
                                                autoComplete="given-name"
                                                required
                                            />
                                        </div>
                                        <div className="mt-5">
                                            <TextField
                                                field={fields.lastName}
                                                htmlFor="last-name"
                                                name="last-name"
                                                id="last-name"
                                                type="text"
                                                inputMode="text"
                                                label="Last name"
                                                autoComplete="family-name"
                                                required
                                            />
                                        </div>
                                        <div className="mt-5">
                                            <label
                                                htmlFor="property-type"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Preferred currency
                                            </label>
                                            <select
                                                id="property-type"
                                                name="property-type"
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                value={
                                                    fields.preferredCurrency
                                                        .value
                                                }
                                                onChange={(event) =>
                                                    fields.preferredCurrency.onChange(
                                                        event.target.value
                                                    )
                                                }
                                            >
                                                {cc.codes().map((type) => {
                                                    return (
                                                        <option key={type}>
                                                            {type}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="button"
                                            onClick={submit}
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                            onClick={close}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        );
    }

    return (
        <>
            <div className="mt-10 divide-y divide-gray-200">
                <div className="space-y-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Profile
                    </h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                        Some information will be displayed publicly so be
                        careful what you share.
                    </p>
                </div>
                <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                                First name
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <span className="flex-grow">{firstName}</span>
                                <span className="ml-4 flex-shrink-0">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            setProfileModalOpen(true)
                                        }
                                        className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                    >
                                        Update
                                    </button>
                                </span>
                            </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                                Last name
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <span className="flex-grow">{lastName}</span>
                                <span className="ml-4 flex-shrink-0">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            setProfileModalOpen(true)
                                        }
                                        className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                    >
                                        Update
                                    </button>
                                </span>
                            </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                                Preferred currency
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <span className="flex-grow">
                                    {preferredCurrency}
                                </span>
                                <span className="ml-4 flex-shrink-0">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            setProfileModalOpen(true)
                                        }
                                        className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                    >
                                        Update
                                    </button>
                                </span>
                            </dd>
                        </div>
                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                                Your listing page
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <a
                                    href={`${window.location.origin}/listings/${id}`}
                                    className="text-sm font-semibold text-gray-900"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    View listings
                                </a>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
            <ProfileModal
                open={profileModalOpen}
                close={() => setProfileModalOpen(false)}
            />
        </>
    );
}
