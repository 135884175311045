import React, { useState } from 'react';
import {
    useForm,
    useField,
    submitFail,
    submitSuccess,
    FormError,
} from '@shopify/react-form';
import { useAuth } from '../../foundation/AuthProvider/AuthProvider';
import {
    TextField,
    ErrorBanner,
    SuccessBanner,
    LoadingSpinner,
} from '../../components';
import { useNavigate } from 'react-router-dom';
import * as cc from 'currency-codes';

enum AuthType {
    LOGIN = 'LOGIN',
    SIGN_UP = 'SIGN_UP',
    FORGOT_PASSWORD = 'FORGOT_PASSWORD',
}

export enum UserTypes {
    LANDLORD = 'LANDLORD',
    REALTOR = 'REALTOR',
    TENANT = 'TENANT',
}

const usersTypes = [UserTypes.LANDLORD, UserTypes.REALTOR];

export const Authenticate = () => {
    const auth = useAuth();
    const [authType, setAuthType] = useState<AuthType>(AuthType.LOGIN);
    const [resetPasswordSuccessMessage, setResetPasswordSuccessMessage] =
        useState('');
    const [authLoading, setAuthLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const {
        submit,
        submitErrors,
        fields: {
            email,
            password,
            firstName,
            lastName,
            confirmPassword,
            preferredCurrency,
            userType,
        },
    } = useForm({
        fields: {
            email: useField(''),
            password: useField(''),
            firstName: useField(''),
            lastName: useField(''),
            confirmPassword: useField(''),
            preferredCurrency: useField(cc.codes()[0]),
            userType: useField(UserTypes.LANDLORD),
        },
        onSubmit: async (fieldValues) => {
            const failures: FormError[] = [];
            switch (authType) {
                case AuthType.LOGIN:
                    setAuthLoading(true);
                    const loginResult = await auth?.signIn(
                        fieldValues.email,
                        fieldValues.password,
                        setAuthLoading
                    );

                    if (loginResult?.error) {
                        console.log(loginResult);
                        failures.push({
                            field: ['email'],
                            message: loginResult.error,
                        });
                    } else if (loginResult?.user) {
                        navigate('/app');
                    }
                    break;
                case AuthType.SIGN_UP:
                    if (
                        validateSignUp(
                            fieldValues.password,
                            fieldValues.confirmPassword
                        )
                    ) {
                        setAuthLoading(true);
                        const result = await auth?.signUp(
                            fieldValues.email,
                            fieldValues.password,
                            fieldValues.firstName,
                            fieldValues.lastName,
                            fieldValues.preferredCurrency,
                            fieldValues.userType,
                            setAuthLoading
                        );
                        if (result?.error) {
                            failures.push({
                                field: ['email'],
                                message: result.error,
                            });
                        } else if (result?.user) {
                            navigate('/app');
                        }
                    } else {
                        failures.push({
                            field: ['confirmPassword'],
                            message:
                                'Confirm password is not the same as your password',
                        });
                    }
                    break;
                case AuthType.FORGOT_PASSWORD:
                    setAuthLoading(true);
                    const result = await auth?.resetPassword(fieldValues.email);
                    setAuthLoading(false);
                    if (result.status === 'ERROR') {
                        failures.push({
                            field: ['email'],
                            message: `There is no registered account for ${fieldValues.email}`,
                        });
                    } else {
                        setResetPasswordSuccessMessage(
                            `Password Reset email sent succesfully for ${fieldValues.email}`
                        );
                    }
                    break;
            }
            return failures.length === 0
                ? submitSuccess()
                : submitFail(failures);
        },
    });

    if (auth?.getAuthorizationToken()) {
        window.location.href = '/app';
    }

    return (
        <div>
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-12 w-auto"
                        src="https://firebasestorage.googleapis.com/v0/b/property-manager-a677a.appspot.com/o/Zichi%20Logo.svg?alt=media&token=55157a2f-27b6-46a4-ba89-5ea786b9490a"
                        alt="Zichi.app logo"
                    />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        {authType === AuthType.LOGIN
                            ? 'Sign in to your account'
                            : authType === AuthType.FORGOT_PASSWORD
                            ? 'Reset your password'
                            : 'Create an account'}
                    </h2>
                    {authType === AuthType.LOGIN && (
                        <p className="mt-2 text-center text-sm text-gray-600">
                            Or{' '}
                            <button
                                disabled={authLoading}
                                type="button"
                                className="font-medium text-indigo-600 hover:text-indigo-500 disabled:opacity-50"
                                onClick={() => {
                                    setAuthType(AuthType.SIGN_UP);
                                }}
                            >
                                Create an account
                            </button>
                        </p>
                    )}
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        {submitErrors.length > 0 ? (
                            <ErrorBanner
                                errors={submitErrors.map(
                                    (errors) => errors.message
                                )}
                            />
                        ) : null}
                        {resetPasswordSuccessMessage ? (
                            <SuccessBanner
                                message={resetPasswordSuccessMessage}
                                dismiss={() =>
                                    setResetPasswordSuccessMessage('')
                                }
                            />
                        ) : null}
                        <form
                            className="space-y-6"
                            onSubmit={(e) => {
                                e.preventDefault();
                                submit();
                                return false;
                            }}
                        >
                            <TextField
                                field={email}
                                htmlFor="email"
                                inputMode="text"
                                label="Email"
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                            />

                            {authType !== AuthType.FORGOT_PASSWORD && (
                                <TextField
                                    field={password}
                                    htmlFor="password"
                                    inputMode="text"
                                    label="Password"
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete={
                                        authType === AuthType.LOGIN
                                            ? 'current-password'
                                            : 'new-password'
                                    }
                                    required
                                />
                            )}

                            {authType === AuthType.SIGN_UP && (
                                <>
                                    <TextField
                                        field={confirmPassword}
                                        htmlFor="confirm-password"
                                        name="confirm-password"
                                        id="confirm-password"
                                        type="password"
                                        inputMode="text"
                                        label="Confirm password"
                                        autoComplete="new-password"
                                        required
                                    />
                                    <TextField
                                        field={firstName}
                                        htmlFor="first-name"
                                        name="first-name"
                                        id="first-name"
                                        type="text"
                                        inputMode="text"
                                        label="First name"
                                        autoComplete="given-name"
                                        required
                                    />

                                    <TextField
                                        field={lastName}
                                        htmlFor="last-name"
                                        name="last-name"
                                        id="last-name"
                                        type="text"
                                        inputMode="text"
                                        label="Last name"
                                        autoComplete="family-name"
                                        required
                                    />

                                    <div>
                                        <label
                                            htmlFor="property-type"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Preferred currency
                                        </label>
                                        <select
                                            id="property-type"
                                            name="property-type"
                                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                            value={preferredCurrency.value}
                                            onChange={(event) =>
                                                preferredCurrency.onChange(
                                                    event.target.value
                                                )
                                            }
                                        >
                                            {cc.codes().map((type) => {
                                                return (
                                                    <option key={type}>
                                                        {type}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>

                                    <div>
                                        <label
                                            htmlFor="user-type"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Are your a landlord or
                                            realtor/realty?
                                        </label>
                                        <select
                                            id="user-type"
                                            name="user-type"
                                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                            value={userType.value}
                                            onChange={(event) =>
                                                userType.onChange(
                                                    event.target
                                                        .value as UserTypes
                                                )
                                            }
                                        >
                                            {usersTypes.map((type) => {
                                                return (
                                                    <option key={type}>
                                                        {type}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </>
                            )}

                            {authType === AuthType.LOGIN && (
                                <div className="flex items-center justify-between">
                                    <div className="text-sm">
                                        <button
                                            disabled={authLoading}
                                            type="button"
                                            className="font-medium text-indigo-600 hover:text-indigo-500 disabled:opacity-75"
                                            onClick={() =>
                                                setAuthType(
                                                    AuthType.FORGOT_PASSWORD
                                                )
                                            }
                                        >
                                            Forgot your password?
                                        </button>
                                    </div>
                                </div>
                            )}

                            <div>
                                <button
                                    disabled={authLoading}
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-75"
                                >
                                    {authLoading && (
                                        <LoadingSpinner
                                            size="small"
                                            fullSize={false}
                                            pathColor="white"
                                            circleColor="white"
                                        />
                                    )}
                                    {authType === AuthType.LOGIN
                                        ? 'Login'
                                        : authType === AuthType.FORGOT_PASSWORD
                                        ? 'Reset Password'
                                        : 'Sign up'}
                                </button>
                            </div>
                        </form>

                        <div className="mt-6">
                            <div className="relative">
                                <div className="absolute inset-0 flex items-center">
                                    <div className="w-full border-t border-gray-300" />
                                </div>
                                <div className="relative flex justify-center text-sm">
                                    <span className="px-2 bg-white text-gray-500">
                                        <button
                                            disabled={authLoading}
                                            type="button"
                                            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-75"
                                            onClick={() => {
                                                if (
                                                    authType === AuthType.LOGIN
                                                ) {
                                                    setAuthType(
                                                        AuthType.SIGN_UP
                                                    );
                                                } else {
                                                    setAuthType(AuthType.LOGIN);
                                                }
                                            }}
                                        >
                                            {authType === AuthType.LOGIN
                                                ? 'Sign up'
                                                : 'Login'}
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function validateSignUp(password: string, confirmPassword: string) {
    return password === confirmPassword;
}
