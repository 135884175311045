import { GetPropertyQueryQuery } from '../../../../generated/graphql';

export function extractPropertyDetailFields(property?: GetPropertyQueryQuery) {
    const defaultVaules = {
        name: '',
        address: '',
        type: '',
        forRent: false,
        price: 0,
        units: [],
        about: '',
        timeline: [],
    };

    if (!property?.GetProperty) {
        return defaultVaules;
    }

    return { ...property.GetProperty, about: property.GetProperty.about || '' };
}
