import { Popover } from '@headlessui/react';
import React, { useState } from 'react';
import { LawnFooter } from './Footer';
import {
    BrandingData,
    ThemeEditorData,
} from '../../../../foundation/EditorContext/EditorContext';
import { ContactModal } from './ContactModal';

export enum PropertyRentalChargeSchedule {
    Monthly,
    Nightly,
}

interface PropertyInformation {
    id: string;
    address: string;
    price: string;
    chargeSchedule: PropertyRentalChargeSchedule;
    propertyImageUrl: string;
    propertyImageAlt: string;
    description: string;
    renting: boolean;
    units: { id: string; propertyId: string; unitPrice: string }[];
}

interface Props {
    properties: PropertyInformation[];
    landlordLogoUrl: string;
    landlordEmail?: string;
    preferredCurrency?: string | null;
    branding: ThemeEditorData | BrandingData | null;
}

export function Lawn({
    properties,
    landlordLogoUrl,
    landlordEmail,
    preferredCurrency,
    branding,
}: Props) {
    const [contactLanlord, setContactLandlord] = useState(false);
    return (
        <div
            className="h-screen"
            style={{
                display: 'grid',
                gridTemplateRows: '120px 2fr 120px',
                backgroundColor: branding?.branding.colors.background.value,
            }}
        >
            <header className="h-25">
                <Popover
                    style={{
                        backgroundColor:
                            branding?.branding.colors.heading.value,
                    }}
                >
                    <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
                        <div className="flex justify-start lg:w-0 lg:flex-1">
                            <a href="#">
                                <span className="sr-only">Zichi</span>
                                <img
                                    className="h-20 w-auto sm:h-10"
                                    src={landlordLogoUrl}
                                    alt="Zichi.app logo"
                                />
                            </a>
                        </div>
                    </div>
                </Popover>
            </header>
            {properties.length > 0 ? (
                <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-10 lg:max-w-7xl lg:px-8">
                    <h2
                        className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight"
                        style={{
                            color: branding?.branding.colors.primaryText.value,
                        }}
                    >
                        Available properties
                    </h2>

                    <div className="mt-10 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-8 lg:gap-x-8">
                        {properties.map((property) => (
                            <div key={property.id} className="group relative">
                                <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 hover:opacity-75 lg:h-72 xl:h-80">
                                    <img
                                        src={property.propertyImageUrl}
                                        alt={property.propertyImageAlt}
                                        className="h-full w-full object-cover object-center"
                                    />
                                </div>
                                <h3 className="mt-4">
                                    <p
                                        className="text-md font-bold"
                                        style={{
                                            color: branding?.branding.colors
                                                .primaryText.value,
                                        }}
                                    >
                                        {property.address}{' '}
                                    </p>
                                </h3>
                                <p
                                    className="mt-1 text-sm font-medium"
                                    style={{
                                        color: branding?.branding.colors
                                            .secondaryText.value,
                                    }}
                                >
                                    {property.description}
                                </p>
                                <p className="mt-1 text-md">
                                    <span
                                        className="font-bold"
                                        style={{
                                            color: branding?.branding.colors
                                                .primaryText.value,
                                        }}
                                    >
                                        {property.units.length > 0
                                            ? `${calculateAvergeRentPriceForUnits(
                                                  property.units
                                              )} ${preferredCurrency}`
                                            : property.price}
                                    </span>{' '}
                                    <span
                                        className="font-light"
                                        style={{
                                            color: branding?.branding.colors
                                                .secondaryText.value,
                                        }}
                                    >
                                        {getPriceDetails(
                                            property.chargeSchedule
                                        )}
                                    </span>
                                </p>
                                <button
                                    // href={`mailto:${landlordEmail}`}
                                    className="text-sm font-semibold hover:text-indigo-500"
                                    style={{
                                        color: branding?.branding.colors.accent
                                            .value,
                                    }}
                                    onClick={() => setContactLandlord(true)}
                                >
                                    Contact
                                    <span aria-hidden="true">&rarr;</span>
                                </button>
                                <ContactModal
                                    open={contactLanlord}
                                    onClose={() => setContactLandlord(false)}
                                    propertyId={property.id}
                                    branding={branding}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div>
                    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
                        <div className="text-center">
                            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                                No properties available
                            </h1>
                            <p className="mt-6 text-base leading-7 text-gray-600">
                                This landlord has no available properties right
                                now
                            </p>
                            <div className="mt-10 flex items-center justify-center gap-x-6">
                                <a
                                    href={`mailto:${landlordEmail}`}
                                    className="text-sm font-semibold text-indigo-600 hover:text-indigo-500"
                                >
                                    Email landlord
                                    <span aria-hidden="true">&rarr;</span>
                                </a>
                            </div>
                        </div>
                    </main>
                </div>
            )}
            <LawnFooter branding={branding} />
        </div>
    );
}

export function getPriceDetails(schedule: PropertyRentalChargeSchedule) {
    switch (schedule) {
        case PropertyRentalChargeSchedule.Monthly:
            return 'monthly';
        case PropertyRentalChargeSchedule.Nightly:
            return '/ night';
    }
}

export function calculateAvergeRentPriceForUnits(
    units: { id: string; propertyId: string; unitPrice: string }[]
) {
    let averageUnitPrice = 0;

    units.forEach((unit) => {
        averageUnitPrice = unit.unitPrice
            ? averageUnitPrice + parseInt(unit.unitPrice)
            : averageUnitPrice;
    });

    return averageUnitPrice;
}
