import { useForm } from '@shopify/react-form';
import React, { useState } from 'react';
import { LoadingSpinner } from '../../components';
import {
    useGetStripeAccountInfoQuery,
    useRegisterLandlordForPaymentsMutation,
} from '../../generated/graphql';

export function PaymentSettings() {
    const [loadingRegistration, setLoadingRegistration] = useState(false);
    const [registerLandlordForPayments] =
        useRegisterLandlordForPaymentsMutation();

    const { data, loading } = useGetStripeAccountInfoQuery();

    const { GetStripeAccountInfo } = data || {};
    const { submit } = useForm({
        fields: {},
        onSubmit: async () => {
            setLoadingRegistration(true);
            const { data } = await registerLandlordForPayments();
            setLoadingRegistration(false);

            if (data?.RegisterLandlordForPayments.accountLink) {
                window.location.href =
                    data.RegisterLandlordForPayments.accountLink;
            }
            return { status: 'success' };
        },
    });

    const AccountConnection = () => {
        return (
            <>
                {GetStripeAccountInfo?.chargesEnabled ? (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-300 text-green-800">
                        Connected
                    </span>
                ) : (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-300 text-red-800">
                        Not connected
                    </span>
                )}
            </>
        );
    };
    return (
        <div className="mt-10 divide-y divide-gray-200">
            <div className="space-y-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Payments Setup
                </h3>
                <p className="max-w-2xl text-sm text-gray-500">
                    Connect a stripe account to collect rental payments (Coming
                    soon).
                </p>
            </div>
            <div className="mt-6">
                <dl className="divide-y divide-gray-200">
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                        <dt className="text-sm font-medium text-gray-500">
                            Account
                        </dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span className="flex-grow">
                                {loading ? (
                                    <LoadingSpinner
                                        size="small"
                                        fullSize={false}
                                        pathColor="blue"
                                        circleColor="blue"
                                    />
                                ) : (
                                    <AccountConnection />
                                )}
                            </span>
                            {GetStripeAccountInfo?.chargesEnabled ||
                            loading ? null : (
                                <span className="ml-4 flex-shrink-0">
                                    <button
                                        type="button"
                                        onClick={submit}
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-75"
                                    >
                                        {loadingRegistration && (
                                            <LoadingSpinner
                                                size="small"
                                                fullSize={false}
                                                pathColor="white"
                                                circleColor="white"
                                            />
                                        )}
                                        Connect
                                    </button>
                                </span>
                            )}
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    );
}
