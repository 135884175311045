import React, { useEffect, useState } from 'react';
import { useAuth } from '../foundation';
import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache,
    from,
    HttpLink,
} from '@apollo/client';
import { isDevelopmentEnvironment } from '../environment';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as ls from 'local-storage';
import { LoadingSpinner } from '../components';

interface Props {
    children: React.ReactNode;
}

export function GraphQL({ children }: Props) {
    const [authToken, setAuthToken] = useState('');
    const [loading, setLoading] = useState(true);
    const authState = useAuth();

    const devServer = 'http://localhost:4200/';
    const backendServer = 'https://api.zichi.app';

    useEffect(() => {
        async function getAuthToken() {
            const token = authState?.getAuthorizationToken();
            const refreshToken = ls.get<string>('refresh_token');

            if (token) {
                setAuthToken(token);
                setLoading(false);
            } else if (refreshToken) {
                const token = await authState?.fetchAuthorizationToken();

                if (token) {
                    setAuthToken(token);
                    setLoading(false);
                } else {
                    throw new Error('Error authenticating user');
                }
            } else {
                setLoading(false);
            }
        }

        getAuthToken();
    });

    const authenticationLink = setContext(async (_, { headers }) => {
        return {
            headers: {
                ...headers,
                token: authToken,
                uid: ls.get<string>('uid'),
            },
        };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
            );
        if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: from([
            authenticationLink,
            new HttpLink({
                uri: `${isDevelopmentEnvironment ? devServer : backendServer}`,
            }),
            errorLink,
        ]),
    });

    if (loading) {
        return <LoadingSpinner />;
    }

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
