import React, { Fragment, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
    LibraryIcon,
    MenuAlt2Icon,
    UsersIcon,
    XIcon,
    SparklesIcon,
    MailIcon,
} from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import * as ls from 'local-storage';
import { MainRoutes } from '../MainRoutes';
import { usePropertyManagerData } from '../DataProvider';
import { UserNameBadge } from '../../components/UserNameBadge';
import { UserTypes } from '../../sections/auth/Authenticate';

export function Navigation() {
    const [currentMenuItemSelected, setCurrentMenuItem] = useState<string>(
        ls.get('selected-menu-item') || '/'
    );

    const propertyManagerData = usePropertyManagerData();

    const [sidebarOpen, setSidebarOpen] = useState(false);

    // const baseNavigation = [
    //     // {
    //     //     name: 'Dashboard',
    //     //     to: '/dashboard',
    //     //     icon: HomeIcon,
    //     //     current: currentMenuItemSelected === '/dashboard',
    //     // },
    // ];

    const landlordNavigationItems = [
        // ...baseNavigation,
        {
            name: 'Properties',
            to: '/properties',
            icon: LibraryIcon,
            current: currentMenuItemSelected === '/properties',
        },
        {
            name: 'Applicants',
            to: '/contacts',
            icon: MailIcon,
            current: currentMenuItemSelected === '/contacts',
        },
        {
            name: 'Tenants',
            to: '/users/tenants',
            icon: UsersIcon,
            current: currentMenuItemSelected === '/users/tenants',
        },
        {
            name: 'Theme editor',
            to: '/editor',
            icon: SparklesIcon,
            current: currentMenuItemSelected === '/editor',
        },
    ];

    const tenantNavigationItems = [
        // ...baseNavigation,
        {
            name: 'Rentals',
            to: '/rentals',
            icon: LibraryIcon,
            current: currentMenuItemSelected === '/rentals',
        },
    ];

    const navigation =
        propertyManagerData.userInfo?.type === UserTypes.LANDLORD ||
        propertyManagerData.userInfo?.type === UserTypes.REALTOR
            ? landlordNavigationItems
            : tenantNavigationItems;
    const userNavigation = [{ name: 'Settings', to: '/settings' }];

    const selectMenuItem = (path: string) => {
        ls.set('selected-menu-item', path);
        setCurrentMenuItem(path);
    };

    function classNames(...classes: any[]) {
        return classes.filter(Boolean).join(' ');
    }
    return (
        <div>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 flex z-40 md:hidden"
                    onClose={setSidebarOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">
                                            Close sidebar
                                        </span>
                                        <XIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-shrink-0 flex items-center px-4">
                                <img
                                    className="h-8 w-auto"
                                    src="https://firebasestorage.googleapis.com/v0/b/property-manager-a677a.appspot.com/o/ZICHI%20LOGO%20WITH%20TEXT.svg?alt=media&token=4fb2def4-641a-4ec2-b6d4-4d5d74275a85"
                                    alt="Zichi.app logo"
                                />
                            </div>
                            <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                <nav className="px-2 space-y-1">
                                    {navigation.map((item) => (
                                        <Link
                                            key={item.name}
                                            to={item.to}
                                            className={classNames(
                                                item.current
                                                    ? 'bg-gray-100 text-gray-900'
                                                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                            )}
                                            onClick={() =>
                                                selectMenuItem(item.to)
                                            }
                                        >
                                            <item.icon
                                                className={classNames(
                                                    item.current
                                                        ? 'text-gray-500'
                                                        : 'text-gray-400 group-hover:text-gray-500',
                                                    'mr-4 flex-shrink-0 h-6 w-6'
                                                )}
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </Link>
                                    ))}
                                </nav>
                            </div>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex-1 flex flex-col min-h-0 bg-white border-r border-gray-200">
                    <div className="flex items-center h-16 flex-shrink-0 px-4 bg-white">
                        <img
                            className="h-8 w-auto"
                            src="https://firebasestorage.googleapis.com/v0/b/property-manager-a677a.appspot.com/o/ZICHI%20LOGO%20WITH%20TEXT.svg?alt=media&token=4fb2def4-641a-4ec2-b6d4-4d5d74275a85"
                            alt="Zichi.app logo"
                        />
                    </div>
                    <div className="flex-1 flex flex-col overflow-y-auto">
                        <nav className="flex-1 px-2 py-4 space-y-1">
                            {navigation.map((item) => (
                                <Link
                                    to={item.to}
                                    key={item.name}
                                    className={classNames(
                                        item.current
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                    )}
                                    onClick={() => selectMenuItem(item.to)}
                                >
                                    <item.icon
                                        className={classNames(
                                            item.current
                                                ? 'text-gray-500'
                                                : 'text-gray-400 group-hover:text-gray-500',
                                            'mr-3 flex-shrink-0 h-6 w-6'
                                        )}
                                        aria-hidden="true"
                                    />
                                    {item.name}
                                </Link>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
            <div className="md:pl-64 flex flex-col">
                <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
                    <button
                        type="button"
                        className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <div className="flex-1 px-4 flex justify-between">
                        <div className="flex-1 flex">
                            <form
                                className="w-full flex md:ml-0"
                                action="#"
                                method="GET"
                            >
                                <label
                                    htmlFor="search-field"
                                    className="sr-only"
                                >
                                    Search
                                </label>
                                {/* <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                                        <SearchIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <input
                                        id="search-field"
                                        className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                                        placeholder="Search"
                                        type="search"
                                        name="search"
                                    />
                                </div> */}
                            </form>
                        </div>
                        <div className="ml-4 flex items-center md:ml-6">
                            <Menu as="div" className="ml-3 relative">
                                <div>
                                    <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <span className="sr-only">
                                            Open user menu
                                        </span>
                                        <UserNameBadge
                                            firstName={propertyManagerData.userInfo?.firstName?.charAt(
                                                0
                                            )}
                                            lastName={propertyManagerData.userInfo?.lastName?.charAt(
                                                0
                                            )}
                                        />
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {userNavigation.map((item) => (
                                            <Menu.Item key={item.name}>
                                                {({ active }) => (
                                                    <Link
                                                        to={item.to}
                                                        className={classNames(
                                                            active
                                                                ? 'bg-gray-100'
                                                                : '',
                                                            'block px-4 py-2 text-sm text-gray-700'
                                                        )}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                        ))}
                                        <Menu.Item key="logout">
                                            <a
                                                onClick={() => {
                                                    ls.clear();
                                                    window.location.reload();
                                                }}
                                                className={classNames(
                                                    'block px-4 py-2 text-sm text-gray-700'
                                                )}
                                            >
                                                Logout
                                            </a>
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>

                <MainRoutes />
            </div>
        </div>
    );
}
