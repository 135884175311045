import React, { Fragment, useEffect } from 'react';
import { useField, useForm } from '@shopify/react-form';
import { XIcon } from '@heroicons/react/outline';
import { Dialog, Transition } from '@headlessui/react';
import {
    useAddTenantMutation,
    useGetUnitsForPropertyQuery,
} from '../../../../../../generated/graphql';
import { useAuth, usePropertyManagerData } from '../../../../../../foundation';
import { TextField } from '../../../../../../components';
import { TenantsTransformedProperty } from '../../Tenants';

interface Props {
    properties?: TenantsTransformedProperty[];
    onClose(): void;
    open: boolean;
}

export function TenantNew({ properties, onClose, open }: Props) {
    const [addTenant] = useAddTenantMutation();
    const propertyManagerData = usePropertyManagerData();
    const auth = useAuth();

    const {
        fields: { firstName, lastName, email, propertyId, unitId },
        submit,
    } = useForm({
        fields: {
            firstName: useField(''),
            lastName: useField(''),
            email: useField(''),
            propertyId: useField(''),
            unitId: useField(''),
        },

        onSubmit: async (fieldValues) => {
            const values = {
                ...fieldValues,
                landlordId: propertyManagerData.userInfo?.id,
            };

            const result = await addTenant({
                variables: {
                    occupantInput: values,
                },
            });

            if (result.data) {
                await auth?.sendTenantCreateAccountEmail(
                    fieldValues.email,
                    propertyManagerData.userInfo!.id!,
                    fieldValues.firstName,
                    fieldValues.lastName
                );
                //TO-DO Make sure there is no error. If there is an error, we should retry sending this email.
            }

            onClose();
            return { status: 'success' };
        },
    });

    const { data: propertyUnits, refetch } = useGetUnitsForPropertyQuery({
        variables: {
            landlordId: propertyManagerData.userInfo?.id!,
            propertyId: propertyId.value,
        },
        skip: propertyId.value === '',
    });

    const handleChange = (value: string) => {
        propertyId.onChange(value);
    };

    const unitsForProperty = propertyUnits?.GetUnitsForProperty || [];

    useEffect(() => {
        refetch({
            landlordId: propertyManagerData.userInfo?.id!,
            propertyId: propertyId.value,
        });
    }, [propertyId, propertyManagerData.userInfo?.id, refetch]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 overflow-hidden"
                onClose={onClose}
                style={{ zIndex: 9999 }}
            >
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay className="absolute inset-0" />
                    <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="w-screen max-w-md">
                                <form className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                    <div className="flex-1 h-0 overflow-y-auto">
                                        <div className="py-6 px-4 bg-indigo-700 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title className="text-lg font-medium text-white">
                                                    Create a new tenant
                                                </Dialog.Title>
                                                <div className="ml-3 h-7 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                        onClick={() =>
                                                            onClose()
                                                        }
                                                    >
                                                        <span className="sr-only">
                                                            Close panel
                                                        </span>
                                                        <XIcon
                                                            className="h-6 w-6"
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mt-1">
                                                <p className="text-sm text-indigo-300">
                                                    Fill in the information
                                                    below to create a new
                                                    tenant.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex-1 flex flex-col justify-between">
                                            <div className="px-4 divide-y divide-gray-200 sm:px-6">
                                                <div className="space-y-6 pt-6 pb-5">
                                                    <div>
                                                        <TextField
                                                            field={firstName}
                                                            htmlFor="first-name"
                                                            inputMode="text"
                                                            label="First name"
                                                            id="first-name"
                                                            name="first-name"
                                                            type="text"
                                                            autoComplete="text"
                                                            required
                                                        />
                                                    </div>
                                                    <div>
                                                        <TextField
                                                            field={lastName}
                                                            htmlFor="last-name"
                                                            inputMode="text"
                                                            label="Last name"
                                                            id="last-name"
                                                            name="last-name"
                                                            type="text"
                                                            autoComplete="text"
                                                            required
                                                        />
                                                    </div>
                                                    <div>
                                                        <TextField
                                                            field={email}
                                                            htmlFor="email"
                                                            inputMode="text"
                                                            label="E-mail"
                                                            id="email"
                                                            name="email"
                                                            type="text"
                                                            autoComplete="text"
                                                            required
                                                        />
                                                    </div>
                                                    <div>
                                                        <label
                                                            htmlFor="property"
                                                            className="block text-sm font-medium text-gray-700"
                                                        >
                                                            Property
                                                        </label>
                                                        <select
                                                            id="property"
                                                            name="property"
                                                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                            value={
                                                                propertyId.value
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                handleChange(
                                                                    event.target
                                                                        .value
                                                                );
                                                            }}
                                                            required
                                                        >
                                                            <option
                                                                disabled
                                                                value=""
                                                            >
                                                                Select a
                                                                property
                                                            </option>
                                                            {properties?.map(
                                                                (property) => {
                                                                    return (
                                                                        <option
                                                                            key={
                                                                                property.id
                                                                            }
                                                                            value={
                                                                                property.id
                                                                            }
                                                                        >
                                                                            {
                                                                                property.name
                                                                            }
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div>
                                                        {unitsForProperty.length >
                                                            0 && (
                                                            <>
                                                                <label
                                                                    htmlFor="unit"
                                                                    className="block text-sm font-medium text-gray-700"
                                                                >
                                                                    Unit
                                                                </label>
                                                                <select
                                                                    id="unit"
                                                                    name="unit"
                                                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                                    value={
                                                                        unitId.value
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        unitId.onChange(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                    required
                                                                >
                                                                    <option
                                                                        disabled
                                                                        value=""
                                                                    >
                                                                        Select a
                                                                        unit
                                                                    </option>
                                                                    {unitsForProperty.map(
                                                                        (
                                                                            unit
                                                                        ) => {
                                                                            return (
                                                                                <option
                                                                                    key={
                                                                                        unit.id
                                                                                    }
                                                                                    value={
                                                                                        unit.id ||
                                                                                        ''
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        unit.unitNumber
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </select>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                                        <button
                                            type="button"
                                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={() => onClose()}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={submit}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
