import React from 'react';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { InboxIcon, MenuIcon } from '@heroicons/react/outline';
import landingPhoto from './assets/landing-prop-photo.jpg';
import zichiExample from './assets/zichi-example.png';

export function Landing() {
    return (
        <div className="bg-white">
            <header>
                <Popover className="relative bg-white">
                    <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
                        <div className="flex justify-start lg:w-0 lg:flex-1">
                            <a href="#">
                                <span className="sr-only">Zichi</span>
                                <img
                                    className="h-20 w-auto sm:h-10"
                                    src="https://firebasestorage.googleapis.com/v0/b/property-manager-a677a.appspot.com/o/ZICHI%20LOGO%20WITH%20TEXT.svg?alt=media&token=4fb2def4-641a-4ec2-b6d4-4d5d74275a85"
                                    alt="Zichi.app logo"
                                />
                            </a>
                        </div>
                        <div className="-mr-2 -my-2 md:hidden">
                            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span className="sr-only">Open menu</span>
                                <MenuIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </Popover.Button>
                        </div>
                        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                            <Link to={`/auth/`}>
                                <a className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                                    Sign in
                                </a>
                            </Link>
                            <Link to={`/auth/`}>
                                <a className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-indigo-600 to-blue-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-indigo-700 hover:to-blue-700">
                                    Sign up
                                </a>
                            </Link>
                        </div>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="duration-200 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                        >
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                                <div className="py-6 px-5">
                                    <div className="mt-6">
                                        <Link to={`/auth/`}>
                                            <a
                                                href="#"
                                                className="w-full flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-purple-700 hover:to-indigo-700"
                                            >
                                                Sign up
                                            </a>
                                        </Link>

                                        <p className="mt-6 text-center text-base font-medium text-gray-500">
                                            Existing customer?
                                            <Link to={`/auth/`}>
                                                <a
                                                    href="#"
                                                    className="text-gray-900"
                                                >
                                                    Sign in
                                                </a>
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </Popover>
            </header>

            <main>
                {/* Hero section */}
                <div className="relative">
                    <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                        <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                            <div className="absolute inset-0">
                                <img
                                    className="h-full w-full object-cover"
                                    src={landingPhoto}
                                    alt="People working on laptops"
                                />
                                <div className="absolute inset-0 bg-gradient-to-r from-purple-800 to-indigo-700 mix-blend-multiply" />
                            </div>
                            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                                    <span className="block text-white">
                                        A platform for
                                    </span>
                                    <span className="block text-indigo-200">
                                        Property Management
                                    </span>
                                </h1>
                                <p className="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl">
                                    Zichi is one platform for Organizing,
                                    Managing and making the most out of your
                                    Property Management service.
                                </p>
                                <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                                    <div className="space-y-4 sm:space-y-0 sm:mx-auto">
                                        <Link to={`/auth`}>
                                            <a className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8">
                                                Get started
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo Cloud */}
                <div className="bg-gray-100"></div>

                {/* Alternating Feature Sections */}
                <div className="relative pt-16 pb-32 overflow-hidden">
                    <div
                        aria-hidden="true"
                        className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
                    />
                    <div className="relative">
                        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                                <div>
                                    <div>
                                        <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-indigo-600 to-blue-600">
                                            <InboxIcon
                                                className="h-6 w-6 text-white"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    </div>
                                    <div className="mt-6">
                                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                            Simple Tenant and Property
                                            management
                                        </h2>
                                        <p className="mt-4 text-lg text-gray-500">
                                            Unlock Effortless Property
                                            Management Today! Say goodbye to
                                            paperwork and hello to simplicity
                                            with Zichi. Adding tenants and
                                            accepting rental applications has
                                            never been this easy:
                                        </p>
                                        <p className="mt-4 text-lg text-gray-500">
                                            🏠 Seamlessly add tenants with just
                                            a few clicks
                                        </p>
                                        <p className="mt-4 text-lg text-gray-500">
                                            📄 Streamline the rental application
                                            process
                                        </p>
                                        <p className="mt-4 text-lg text-gray-500">
                                            📱 Manage your properties on-the-go
                                        </p>
                                        <p className="mt-4 text-lg text-gray-500">
                                            🚀 Boost your efficiency and stay
                                            organized Experience the future of
                                            property management. Join us now and
                                            start managing with ease!"
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-12 sm:mt-16 lg:mt-0">
                                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                                    <img
                                        className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                        src={zichiExample}
                                        alt="Zichi user interface"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* CTA Section */}
                <div className="bg-white">
                    <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
                        <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            <span className="block">Ready to get started?</span>
                            <span className="block bg-gradient-to-r from-indigo-600 to-blue-600 bg-clip-text text-transparent">
                                Create an account.
                            </span>
                        </h2>
                        <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
                            <Link to={`/auth/`}>
                                <a className="flex items-center justify-center bg-gradient-to-r from-indigo-600 to-blue-600 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-indigo-700 hover:to-blue-700">
                                    Get started
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </main>

            <footer className="bg-gray-50" aria-labelledby="footer-heading">
                <h2 id="footer-heading" className="sr-only">
                    Footer
                </h2>
                <div className="max-w-7xl mx-auto pt-2 pb-8 px-4 sm:px-6 lg:pt-2 lg:px-8">
                    <div className="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16">
                        <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
                            &copy; {new Date().getFullYear()} Zichi Inc.
                        </p>
                    </div>
                </div>
            </footer>
        </div>
    );
}
