import React from 'react';
import { Link } from 'react-router-dom';
// import { Pagination } from '../../../../../../components';
import { EmptyTenantIcon } from '../../../../../../components/EmptyState';
import { Occupant } from '../../../../../../generated/graphql';

interface Props {
    occupants?: Array<Occupant> | null;
    removeTenant: (id: string) => Promise<void>;
}
export function TenantsTable({ occupants, removeTenant }: Props) {
    return (
        <>
            {occupants?.length === 0 ? (
                <EmptyTenantIcon />
            ) : (
                <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Property ID
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Email
                                            </th>
                                            <th
                                                scope="col"
                                                className="relative px-6 py-3"
                                            >
                                                <span className="sr-only">
                                                    Delete
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {occupants?.map((tenant, personIdx) => (
                                            <tr
                                                key={tenant.id}
                                                className={
                                                    personIdx % 2 === 0
                                                        ? 'bg-white'
                                                        : 'bg-gray-50'
                                                }
                                            >
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    <Link
                                                        to={`/users/tenants/${tenant.id}`}
                                                        className="text-indigo-600 hover:text-indigo-900"
                                                    >
                                                        {`${tenant.firstName} ${tenant.lastName}`}
                                                    </Link>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <Link
                                                        to={`/properties/${tenant.propertyId}`}
                                                        className="text-indigo-600 hover:text-indigo-900"
                                                    >
                                                        {tenant.propertyId}
                                                    </Link>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {tenant.email}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                    <a
                                                        onClick={() =>
                                                            removeTenant(
                                                                tenant.id
                                                            )
                                                        }
                                                        className="text-red-600 hover:text-red-900"
                                                    >
                                                        Delete
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {/* <Pagination /> */}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
