import React from 'react';
import { useI18n } from '@shopify/react-i18n';

type Type =
    | 'text'
    | 'email'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'url'
    | 'date'
    | 'datetime-local'
    | 'month'
    | 'time'
    | 'week'
    | 'currency';

type InputMode =
    | 'none'
    | 'text'
    | 'decimal'
    | 'numeric'
    | 'tel'
    | 'search'
    | 'email'
    | 'url';

interface Props {
    value: string;
    onChange(value: string): void;
    htmlFor: string;
    name: string;
    id: string;
    type: Type;
    inputMode: InputMode;
    autoComplete?: string;
    label: string;
    required?: boolean;
    labelColor?: string;
    currencyField?: boolean;
    textArea?: boolean;
    disabled?: boolean;
}

export function TextFieldValue({
    value,
    onChange,
    htmlFor,
    name,
    id,
    type,
    inputMode,
    autoComplete,
    label,
    required = false,
    labelColor,
    currencyField,
    textArea,
    disabled,
}: Props) {
    const [i18n] = useI18n();
    const textColorForLabel = labelColor || 'text-gray-700';
    return (
        <div>
            <label
                htmlFor={htmlFor}
                className={`block text-sm font-medium ${textColorForLabel}`}
            >
                {label}
            </label>
            <div className="mt-1 relative">
                {currencyField ? (
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">
                            {i18n.defaultCurrency}
                        </span>
                    </div>
                ) : null}
                {textArea ? (
                    <textarea
                        id={id}
                        name={name}
                        rows={3}
                        autoComplete={autoComplete}
                        required={required}
                        className={`appearance-none block w-full px-3 py-2 ${
                            currencyField ? 'pl-7 pr-15' : ''
                        } border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        value={value}
                        onChange={(event) => onChange(event.target.value)}
                        inputMode={inputMode}
                    />
                ) : (
                    <input
                        id={id}
                        name={name}
                        type={type}
                        autoComplete={autoComplete}
                        required={required}
                        className={`appearance-none block w-full px-3 py-2 ${
                            currencyField ? 'pl-7 pr-15' : ''
                        } border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        value={value}
                        onChange={(event) => onChange(event.target.value)}
                        inputMode={inputMode}
                        disabled={disabled}
                    />
                )}
            </div>
        </div>
    );
}
