import { useGetLandlordSubscriptionQuery } from '../../generated/graphql';

export function useGetLandlordSubscription() {
    const { data, loading } = useGetLandlordSubscriptionQuery();

    return {
        planName: data?.GetLandlordSubscription.planName,
        subscriptionActive: data?.GetLandlordSubscription.active,
        portalUrl: data?.GetLandlordSubscription.portalSessionUrl,
        loading,
    };
}
