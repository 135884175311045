import { Unit } from '../generated/graphql';
import { TransformedProperty } from '../sections/properties/components/PropertiesTable/PropertiesTable';

export function calculateAvergeRentPriceForProperty(
    property?: TransformedProperty
) {
    if (!property) {
        return 0;
    }

    if (property.type !== 'Apartment') {
        return property.price;
    }

    let averageUnitPrice = 0;

    property.units.forEach((unit) => {
        averageUnitPrice = unit.unitPrice
            ? averageUnitPrice + unit.unitPrice
            : averageUnitPrice;
    });

    return averageUnitPrice;
}

export function calculateAvergeRentPriceForUnits(units: Unit[]) {
    let averageUnitPrice = 0;

    units.forEach((unit) => {
        averageUnitPrice = unit.unitPrice
            ? averageUnitPrice + parseInt(unit.unitPrice)
            : averageUnitPrice;
    });

    return averageUnitPrice;
}
