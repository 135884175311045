import { useState } from 'react';
import { LandlordBranding } from '../generated/graphql';

export interface UserInfo {
    email: string;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    type: string;
    landlords: string[];
    onboarding: boolean;
    preferredCurrency?: string | null;
    branding?: LandlordBranding | null;
}

export function useDataProvider() {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

    return {
        userInfo,
        setUserInfo,
    };
}
