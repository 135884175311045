import React from 'react';
import { I18nContext, I18nManager } from '@shopify/react-i18n';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider, BaseApp, DataProvider } from './foundation';
import { GraphQL } from './GraphQL/GraphQL';
import { Landing } from './sections/brand/landing/Landing';
import {
    Authenticate,
    LandlordOnboarding,
    LandlordPaymentsSetup,
    Listings,
    TenantOnboarding,
} from './sections';

const locale = 'en';
const i18nManager = new I18nManager({
    locale,
    country: 'CA',
    currency: '$',
    onError() {},
});

function App() {
    return (
        <AuthProvider>
            <GraphQL>
                <DataProvider>
                    <I18nContext.Provider value={i18nManager}>
                        <Router>
                            <Routes>
                                <Route path={'/'} element={<Landing />} />
                                <Route
                                    path={'/listings/:landlord_id'}
                                    element={<Listings />}
                                />
                                <Route
                                    path={'/auth'}
                                    element={
                                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                                            <Authenticate />
                                        </div>
                                    }
                                />
                                <Route
                                    path={'/tenant_onboarding'}
                                    element={<TenantOnboarding />}
                                />
                                <Route
                                    path={'/landlord_onboarding'}
                                    element={<LandlordOnboarding />}
                                />
                                <Route
                                    path={'/landlord_onboarding/payment_setup'}
                                    element={<LandlordPaymentsSetup />}
                                />
                                <Route
                                    path={'/landlord_onboarding/complete'}
                                    element={<LandlordOnboarding />}
                                />
                                <Route path={'/*'} element={<BaseApp />} />
                            </Routes>
                        </Router>
                    </I18nContext.Provider>
                </DataProvider>
            </GraphQL>
        </AuthProvider>
    );
}

export default App;
